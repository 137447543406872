@use "global" as *;

/* application */
.application {
  background: #fff;
  border: rem(1) solid #ccc;
  padding: rem(65) rem(120) rem(65);
  @include mq(sm) {
    padding: rem(40) rem(20);
  }
}

.application__text {
  font-size: rem(15);
  letter-spacing: 0.08em;
  margin-top: rem(20);
}

.application__form {
  margin-top: rem(30);
  .form__item-input {
    width: 70%;
    @include mq(sm) {
      width: 100%;
    }
  }
}