@use "global" as *;

/* strict */
.strict {
  width: rem(960);
  max-width: 100%;
  margin: rem(120) auto 0;
  @include mq(sm) {
    margin-top: rem(80);
  }
}

.strict__items {
  margin-top: rem(80);
  @include mq(sm) {
    margin-top: rem(40);
  }
}

.strict__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(sm) {
    display: block;
  }
  &:first-child {
    flex-direction: row-reverse;
    .strict__item-body {
      width: 37.5%;
      @include mq(sm) {
        width: 100%;
      }
    }
    .strict__item-img {
      width: 56%;
      @include mq(sm) {
        width: 100%;
      }
    }
  }
  &:nth-child(2) {
    width: rem(920);
    max-width: 100%;
    margin-top: rem(100);
    @include mq(sm) {
      margin-top: rem(60);
    }
    .strict__item-body {
      width: 63%;
      @include mq(sm) {
        width: 100%;
      }
    }
    .strict__item-img {
      width: 28.5%;
      @include mq(sm) {
        width: 100%;
      }
    }
  }
}

.strict__item-body {

}

.strict__item-title {
  font-size: rem(20);
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(16);
  }
}

.strict__item-text {
  font-size: rem(15);
  line-height: 2.3;
  letter-spacing: 0.08em;
  margin-top: rem(20);
  @include mq(sm) {
    font-size: rem(13);
    line-height: 1.7;
    margin-top: rem(15);
  }
}

.strict__item-img {
  @include mq(sm) {
    max-width: rem(400);
    margin: rem(20) auto 0;
  }
}