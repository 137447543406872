@use "global" as *;

/* hamburger */
.hamburger {
  display : block;
  position: absolute;
  z-index : 30;
  right : rem(19);
  top   : rem(19);
  width : rem(20);
  height: rem(20);
  cursor: pointer;
}

.hamburger__border {
  display: block;
  position: absolute;
  width: rem(1);
  background: #000;
  transition: 0.3s ease-in-out;
  top: 0;
  &:nth-child(1) {
    height: rem(14);
    left: rem(6);
  }
  &:nth-child(2) {
    height: rem(20);
    left: rem(12);
  }
  &:nth-child(3) {
    height: rem(20);
    left: rem(18);
  }
}

/* ナビ開いてる時のボタン */
.hamburger--active {
  .hamburger__border {
    left: rem(12);
    &:nth-child(1) {
      top: 0;
      height: rem(20);
      transform: rotate(-45deg);
    }

    &:nth-child(2), &:nth-child(3) {
      transform: rotate(45deg);
    }
  }
}