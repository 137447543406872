@use "global" as *;

.l-inner {
  padding-right: $padding-pc;
  padding-left: $padding-pc;
  margin-right: rem(45);
  margin-left: rem(240);
  max-width: rem(1500);
  @include mq(xl) {
    margin: 0 auto;
  }
  @include mq(lg) {
    margin-right: rem(20);
  }
  @include mq("sm") {
    margin: 0;
    max-width: unset;
    padding-right: $padding-sp;
    padding-left: $padding-sp;
  }
}

.l-inner--wide {
  @include mq(sm) {
    padding: 0 rem(15);
  }
}