@use "global" as *;

/* latest */
.latest {

}

.latest__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(-5);
  @include mq(sm) {
    display: block;
    margin-top: rem(40);
  }
}

.latest__item {
  border: rem(1) solid #ccc;
  width: calc(50% - #{rem(22)} * 1 / 2);
  margin-top: rem(50);
  @include mq(sm) {
    width: 100%;
    & + & {
      margin-top: rem(40);
    }
  }
  &:not(:nth-child(2n + 1)) {
    margin-left: rem(22);
    @include mq(sm) {
      margin-left: auto;
    }
  }
}

.latest__item-inner {
  display: flex;
  flex-direction: row-reverse;
  @include mq(sm) {
    display: block;
  }
  &:hover {
    opacity: 1;
    img {
      transform: scale(1.2);
    }
  }
}

.latest__item-body {
  width: 64%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: rem(35) rem(22);
  @include mq(sm) {
    width: 100%;
    padding: rem(20) rem(20);
  }
}

.latest__item-title {
  color: $main-color;
  font-weight: 700;
  letter-spacing: 0.08em;
  line-height: 1.37;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.latest__item-info {
  margin-top: rem(17);
  .info__item {
    &:not(:first-child) {
      margin-top: rem(10);
    }
  }
  .info__item-title {
    padding: rem(3) rem(12) rem(4);
  }
}

.latest__item-text {
  font-size: rem(13);
  letter-spacing: 0.08em;
  line-height: 1.77;
  margin-top: rem(12);
}

.latest__item-img-wrapper {
  width: 36%;
  @include mq(sm) {
    width: 100%;
    height: rem(200);
  }
}

.latest__item-img {
  width: 100%;
  height: 100%;
  padding-top: calc(184 / 252 * 100%);
  overflow: hidden;
  position: relative;
  @include mq(sm) {
    padding: unset;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .4s;
  }
}