@use "global"as *;

/* recruit */
.recruit {
  
}

.recruit__text {
  font-size: rem(15);
  letter-spacing: 0.04em;
  line-height: 1.8;
  width: rem(920);
  max-width: 100%;
  margin: 0 auto;
}