@use "global" as *;

/* appointment */
.appointment {
  background: #fff;
  border: rem(1) solid #ccc;
  padding: rem(65) rem(122) rem(70) rem(122);
  @include mq(sm) {
    padding: rem(40) rem(20);
  }
}

.appointment__header {
  text-align: center;
  border-bottom: rem(1) solid #ccc;
  padding-bottom: rem(45);
  margin-bottom: rem(45);
}

.appointment__text {
  text-align: center;
  font-size: rem(15);
  line-height: 1.73;
  letter-spacing: 0.08em;
  margin-top: rem(25);
  @include mq(sm) {
    text-align: left;
    margin-top: rem(20);
  }
}

.appointment__tel {
  display: inline-block;
  font-size: rem(38);
  letter-spacing: 0.08em;
  color: $main-color;
  margin-top: rem(12);
  @include mq(sm) {
    font-size: rem(28);
    margin-top: rem(20);
  }
}

.appointment__flex {
  display: flex;
  justify-content: center;
  margin-top: rem(5);
  @include mq(sm) {
    display: block;
    margin-top: rem(15);
  }
}

.appointment__flex-text {
  font-size: rem(12);
  letter-spacing: 0.08em;
  &:last-child {
    margin-left: rem(12);
    @include mq(sm) {
      margin-left: 0;
      margin-top: rem(5);
    }
  }
}

.appointment__form {
  margin-top: rem(45);
  @include mq(sm) {
    margin-top: rem(30);
  }
  .form__item--chance {
    .form__check {
      > span {
        &:nth-child(3n + 1) {
          width: 38%;
          @include mq(sm) {
            width: 100%;
          }
        }
        &:nth-child(3n + 2) {
          width: 30%;
          margin-left: 3%;
          @include mq(sm) {
            width: 100%;
            margin-left: 0;
          }
        }
        &:nth-child(3n + 3) {
          width: 21%;
          margin-left: auto;
          @include mq(sm) {
            width: 100%;
            margin-left: 0;
          }
        }
      }
    }
    textarea {
      width: 62%;
      @include mq(sm) {
        width: 100%;
      }
    }
  }
}