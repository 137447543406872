@use "global" as *;

/* obsession */
.obsession {
  .concept-nav {
    margin: rem(22) 0;
    @include mq(sm) {
      margin: rem(15) 0;
    }
  }
  .section-title {
    @include mq(sm) {
      font-size: rem(18);
    }
  }
}

.obsession__text {
  text-align: center;
  letter-spacing: 0.04em;
  line-height: 2;
  margin-top: rem(117);
  @include mq(sm) {
    text-align: left;
    font-size: rem(13);
    line-height: 1.7;
    margin-top: rem(80);
  }
}

.obsession__footer {
  background: #F5F5F5;
  padding: rem(40) 0;
  margin-top: rem(120);
  @include mq(sm) {
    margin-top: rem(80);
  }
}

.obsession__banner {
  border-top: rem(1) solid #E3E6E6;
  margin-top: rem(40);
  padding-top: rem(40);
}