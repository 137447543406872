@use "global" as *;

/* breadcrumb */
.breadcrumb {
  background: #F5F5F2;
}

.breadcrumb__items {
  display: flex;
  flex-wrap: wrap;
}

.breadcrumb__item {
  font-size: rem(13);
  line-height: 1.4;
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(12);
  }
  & + & {
    margin-left: rem(40);
    position: relative;
    &::before {
      position: absolute;
      content: ">";
      left: rem(-26);
      top: rem(4);
    }
  }
  a, span {
    display: inline-block;
    padding: rem(4) 0;
  }
}