/* リキッドレイアウト対応 */
@use "global" as *;

html {
  //~375px
  @media (max-width: 375px) {
    font-size: vw(375, 16);
  }

  //375px~767px
  font-size: 16px;

  //768px~inner
  @media screen and (min-width: 768px) {
    font-size: vw(strip-unit(1200px), 16);
  }

  //inner~max-screen
  @media (min-width: 1200px) {
    font-size: 16px;
  }
}

/* pcの電話番号発信対応 */
a[href^="tel:"] {
  pointer-events: none;
  @include mq("md") {
    pointer-events: unset;
  }
}

/* ホバー */
a {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.7;
  }
  @include mq("sm") {
    &:hover {
      opacity: 1;
    }
  }
}

button {
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.7;
  }
  @include mq("sm") {
    &:hover {
      opacity: 1;
    }
  }
}

* {
  ::-webkit-scrollbar {
    display:none;
  }
}