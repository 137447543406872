@use "global" as *;

/* info */
.info {
  
}

.info__item {
  display: flex;
  align-items: flex-start;
  & + & {
    margin-top: rem(20);
    @include mq(sm) {
      margin-top: rem(15);
    }
  }
}

.info__item-title {
  font-size: rem(12);
  letter-spacing: 0.12em;
  background: #F5F5F2;
  padding: rem(3) rem(18) rem(4);
  white-space: nowrap;
}

.info__item-text {
  font-size: rem(15);
  letter-spacing: 0.08em;
  margin-left: rem(7);
}
