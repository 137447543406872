@use "global"as *;

/* pagination */
.pagination {
  .wp-pagenavi {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    a,
    span {
      border: none;
      width: rem(35);
      height: rem(35);
      margin: rem(5) rem(12) 0;
    }

    .page,
    .current {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: rem(7);
      letter-spacing: 0.08em;
    }

    .page {}

    .current {
      background: #F5F5F2;
    }

    .previouspostslink,
    .nextpostslink {
      position: relative;

      &::after {
        position: absolute;
        content: "";
        transform: rotate(45deg);
        width: rem(12);
        height: rem(12);
        top: rem(12);
      }
    }

    .previouspostslink {
      &::after {
        border-bottom: rem(3) solid $main-color;
        border-left: rem(3) solid $main-color;
        left: rem(10);
      }
    }

    .nextpostslink {
      &::after {
        border-top: rem(3) solid $main-color;
        border-right: rem(3) solid $main-color;
        left: rem(6);
      }
    }
  }
}