@use "global" as *;

/* feature */
.feature {
  margin-top: rem(95);
}

.feature__text {
  text-align: center;
  font-size: rem(20);
  letter-spacing: 0.08em;
  margin-top: rem(30);
  @include mq(sm) {
    font-size: rem(16);
    margin-top: rem(20);
  }
}

.feature__items {
  margin-top: rem(45);
  @include mq(sm) {
    margin-top: rem(40);
  }
}

.feature__item {
  padding: rem(35) rem(45) rem(40);
  border: rem(1) solid #707070;
  @include mq(sm) {
    padding: rem(25) rem(20);
  }
  & + & {
    margin-top: rem(30);
  }
  &:last-child {
    padding: rem(15) 0 rem(20) rem(30);
    @include mq(sm) {
      padding: rem(25) rem(20);
    }
  }
}

.feature__item-title {
  font-size: rem(20);
  font-weight: 700;
  letter-spacing: 0.08em;
  color: $main-color;
  @include mq(sm) {
    font-size: rem(18);
  }
}

.feature__item-text {
  font-size: rem(15);
  line-height: 1.8;
  letter-spacing: 0.04em;
  margin-top: rem(20);
  @include mq(sm) {
    font-size: rem(14);
    margin-top: rem(15);
  }
}

.feature__item-flex {
  display: flex;
  justify-content: space-between;
  margin-top: rem(20);
  @include mq(sm) {
    display: block;
  }
}

.feature__item-body {
  width: 62%;
  @include mq(sm) {
    width: 100%;
  }
  .feature__item-text {
    margin-top: 0;
  }
}

.feature__item-imgs {
  width: 30%;
  display: flex;
  justify-content: space-between;
  @include mq(sm) {
    width: 100%;
    margin-top: rem(20);
  }
}

.feature__item-img-wrapper {
  width: 44%;
  @include mq(sm) {
    width: 46%;
  }
}

.feature__item-img {

}

.feature__item-zoom {
  font-size: rem(11);
  letter-spacing: 0.08em;
  padding-left: rem(19);
  background: url(../img/search-icon.png) no-repeat center left / rem(13);
  margin-top: rem(5);
}

.feature__item-heading {
  text-align: center;
  font-size: rem(26);
  font-weight: 700;
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(18);
  }
}

.feature__item-logo {
  width: 40%;
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: 0 auto;
  }
}

.feature__item-subtext {
  width: 59%;
  margin-top: rem(5);
  @include mq(sm) {
    width: 100%;
    font-size: rem(15);
    margin-top: rem(15);
  }
  a {
    text-decoration: underline;
  }
}