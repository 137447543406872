@use "global" as *;

/* akiya-other */
.akiya-other {
  margin-top: rem(70);
  padding-bottom: rem(60);
}

.akiya-other__title {
  text-align: center;
  font-size: rem(30);
  letter-spacing: 0.08em;
  line-height: 1.7;
  font-weight: 700;
  @include mq(sm) {
    font-size: rem(20);
  }
}

.akiya-other__subtitle {
  text-align: center;
  letter-spacing: 0.08em;
  line-height: 1.7;
  font-size: rem(24);
  margin-top: rem(65);
  @include mq(sm) {
    text-align: left;
    font-size: rem(18);
    margin-top: rem(40);
  }
}

.akiya-other__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(35);
  @include mq(sm) {
    display: block;
  }
}

.akiya-other__item {
  width: calc(33.33333333% - #{rem(65)} * 2 / 3);
  margin-top: rem(42);
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: 0 auto;
    & + & {
      margin-top: rem(30);
    }
  }
  &:not(:nth-child(3n + 1)) {
    margin-left: rem(65);
    @include mq(sm) {
      margin-left: auto;
    }
  }
}

.akiya-other__item-img {

}

.akiya-other__item-text {
  letter-spacing: 0.08em;
  line-height: 1.68;
  margin-top: rem(2);
  @include mq(sm) {
    font-size: rem(15);
  }
}

.akiya-other__text {
  text-align: center;
  font-size: rem(24);
  letter-spacing: 0.08em;
  line-height: 1.7;
  margin-top: rem(50);
  @include mq(sm) {
    font-size: rem(18);
    text-align: left;
    margin-top: rem(40);
  }
}

.akiya-other__box {
  display: flex;
  align-items: center;
  border: rem(1) solid #707070;
  border-radius: rem(12);
  width: rem(606);
  max-width: 100%;
  margin: rem(32) auto 0;
  padding: rem(22) rem(10) rem(21) rem(40);
  @include mq(sm) {
    display: block;
    padding: rem(25) rem(20);
  }
}

.akiya-other__box-icon {
  width: rem(111);
  @include mq(sm) {
    margin: 0 auto;
  }
}

.akiya-other__box-body {
  margin-left: rem(13);
  @include mq(sm) {
    margin-left: 0;
    margin-top: rem(20);
  }
}

.akiya-other__box-text {
  font-size: rem(18);
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(15);
  }
}

.akiya-other__box-tel {
  display: inline-block;
  font-size: rem(41);
  letter-spacing: 0.08em;
  margin-top: rem(5);
  margin-left: rem(5);
  @include mq(sm) {
    font-size: rem(24);
    margin-top: rem(10);
  }
  span {
    padding-left: rem(81);
    background: url(../img/tel-icon.png) no-repeat center left / rem(72);
    @include mq(sm) {
      padding-left: rem(50);
      background-size: rem(40);
    }
  }
}