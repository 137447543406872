@use "global" as *;

/* drawer */
.drawer {
  position: fixed;
  z-index: 20;
  top: rem(60);
  left: 0;
  color: #000;
  background: #fff;
  transform: translateY(-200%);
  transition: all 0.6s;
  width: 100%;
  height: 100%;
  padding-top: rem(30);
  overflow-y: scroll;
	padding-bottom: rem(100);
}

.drawer__items {
  text-align: center;
}

.drawer__item {
  width: 100%;
  font-size: rem(15);
  & + & {
    margin-top: rem(10);
  }
  a {
    padding: rem(10) 0;
    display: block;
  }
}

.drawer__menu {
  margin-top: rem(30);
}

.drawer__menu-item {
  text-align: center;
  width: rem(200);
  max-width: 100%;
  background: #5A3234;
  margin: 0 auto;
  & + & {
    margin-top: rem(10);
  }
  a {
    display: block;
    width: 100%;
    font-size: rem(14);
    font-weight: 500;
    letter-spacing: 0.04em;
    color: #fff;
    text-align: center;
    padding: rem(11) rem(10);
    position: relative;
    background: url(../img/arrow-icon01.png) no-repeat center right #{rem(15)} / rem(7);
    &::after {
      position: absolute;
      content: "";
    }
  }
}

.drawer__icon {
  display: block;
  width: rem(45);
  margin: rem(30) auto 0;
}

/* このクラスを、jQueryで付与・削除する */
.drawer--active {
  transform: translateY(0%);
}

.drawer--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}