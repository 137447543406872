@use "global"as *;

/* fv */
.fv {
  text-align: center;
  padding: rem(55) 0;
}

.fv--staff {
  padding: rem(32) 0;
  .fv__entitle {
    color: #9FA0A0;
  }
}

.fv--reform {
  padding-bottom: rem(30);
}

.fv--privacy, .fv--exhibition, .fv--catalog, .fv--sdgs {
  .fv__entitle {
    letter-spacing: 0.12em;
    color: #9FA0A0;
  }
  .fv__title {
    margin-top: rem(15);
  }
  .fv__text {
    font-size: rem(16);
    margin-top: rem(40);
  }
}

.fv__inner {
  @include mq(sm) {
    padding: 0 rem(15);
  }
}

.fv__entitle {
  color: $main-color;
  letter-spacing: 0.08em;
}

.fv__title {
  font-size: rem(24);
  letter-spacing: 0.08em;
  margin-top: rem(5);
}

.fv__text {
  font-size: rem(18);
  letter-spacing: 0.08em;
  margin-top: rem(30);
  @include mq(sm) {
    font-size: rem(16);
  }
}

.fv__subtext {
  font-size: rem(15);
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: rem(35);
}