@use "global" as *;

/* about */
.about {
  
}

.about__img {

}

.about__greeting {
  margin-top: rem(90);
  @include mq(sm) {
    margin-top: rem(60);
  }
}

.about__box-wrapper {
  margin-top: rem(95);
  @include mq(sm) {
    margin-top: rem(60);
  }
}

.about__box {
  background: #fff;
  padding: rem(40) rem(80) rem(70);
  @include mq(sm) {
    padding: rem(25) rem(20) rem(30);
  }
}

.about__list {
  border-top: none;
}

.about__links {
  display: flex;
  justify-content: center;
  margin-top: rem(60);
  @include mq(sm) {
    display: block;
    margin-top: rem(40);
  }
  .link-b {
    @include mq(sm) {
      margin: 0 auto;
    }
    &:last-child {
      margin-left: rem(25);
      @include mq(sm) {
        margin: rem(20) auto 0;
      }
    }
  }
}

.about__access {
  margin-top: rem(95);
  @include mq(sm) {
    margin-top: rem(60);
  }
}