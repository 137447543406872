@use "global" as *;

/* performance */
.performance {
  width: rem(920);
  max-width: 100%;
  margin: rem(84) auto 0;
  @include mq(sm) {
    margin-top: rem(80);
  }
}

.performance__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(-15);
  @include mq(sm) {
    display: block;
    margin-top: rem(30);
  }
}

.performance__item {
  width: calc(50% - #{rem(60)} * 1 / 2);
  margin-top: rem(95);
  @include mq(sm) {
    width: 100%;
    margin: 0 auto;
    & + & {
      margin-top: rem(40);
    }
  }
  &:not(:nth-child(2n + 1)) {
    margin-left: rem(60);
    @include mq(sm) {
      margin-left: auto;
    }
  }
}

.performance__item-img {
  @include mq(sm) {
    max-width: rem(400);
    margin: 0 auto;
  }
}

.performance__item-point {
  letter-spacing: 0.08em;
  margin-top: rem(20);
  @include mq(sm) {
    font-size: rem(15);
    margin-top: rem(15);
  }
}

.performance__item-title {
  font-size: rem(20);
  letter-spacing: 0.08em;
  margin-top: rem(5);
  @include mq(sm) {
    font-size: rem(16);
  }
}

.performance__item-text {
  font-size: rem(15);
  letter-spacing: 0.08em;
  line-height: 2;
  margin-top: rem(15);
  @include mq(sm) {
    font-size: rem(13);
    line-height: 1.7;
    margin-top: rem(10);
  }
  &:nth-of-type(2) {
    margin-top: rem(25);
    @include mq(sm) {
      margin-top: rem(15);
    }
  }
}

.performance__item-subtext {
  font-size: rem(13);
  line-height: 2.3;
  letter-spacing: 0.08em;
  margin-top: rem(12);
}