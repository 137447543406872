@use "global" as *;

/* slider */
.slider {
  width: 100%;
  position: relative;
  margin-top: rem(30);
  @include mq(sm) {
    margin-top: rem(25);
  }
  .swiper-button-next, .swiper-button-prev {
    width: rem(40);
    height: rem(40);
    position: absolute;
    top: 57%;
    transform: translateY(-50%);
    z-index: 10;
    @include mq(sm) {
      width: rem(20);
      height: rem(20);
      top: 70%;
    }
    &::before {
      position: absolute;
      content: "";
      width: rem(32);
      height: rem(32);
      @include mq(lg) {
        width: rem(28);
        height: rem(28);
      }
      @include mq(sm) {
        width: rem(16);
        height: rem(16);
      }
    }
    &::after {
      display: none;
    }
  }
  .swiper-button-prev {
    left: rem(-70);
    @include mq(lg) {
      left: rem(-50);
    }
    @include mq(sm) {
      left: rem(-25);
    }
    &::before {
      background: url(../img/arrow-icon03.png) no-repeat center / contain;
    }
  }
  .swiper-button-next {
    right: rem(-70);
    @include mq(lg) {
      right: rem(-50);
    }
    @include mq(sm) {
      right: rem(-25);
    }
    &::before {
      background: url(../img/arrow-icon04.png) no-repeat center / contain;
    }
  }
}

.slider__item {
  width: 100%;
  padding-top: calc(320 / 880 * 100%);
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  a {
    display: block;
    &:hover {
      opacity: 1;
    }
  }
}
