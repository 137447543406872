@use "global" as *;

/* news-single */
.news-single {
  
}

.news-single__date {
  font-size: rem(15);
  letter-spacing: 0.12em;
}

.news-single__title {
  font-size: rem(24);
  font-weight: 700;
  color: $main-color;
  letter-spacing: 0.12em;
  line-height: 1.2;
  margin-top: rem(18);
  @include mq(sm) {
    font-size: rem(22);
  }
}

.news-single__thumbnail {
  margin-top: rem(25);
  @include mq(sm) {
    max-width: rem(500);
    margin: rem(30) auto 0;
  }
}

.news-single__post {
  margin-top: rem(50);
  @include mq(sm) {
    margin-top: rem(40);
  }
}

.news-single__links {
  margin-top: rem(60);
}

.news-single {
  .section-entitle {
    color: $main-color;
  }
  .news__item-inner {
    padding: rem(24) rem(25) rem(24) 0;
    @include mq(sm) {
      padding: rem(19) rem(20) rem(19) 0;
    }
    &::after {
      background-image: url(../img/arrow-icon06.png);
    }
  }
  .news__item-category {
    span {
      background: #F5F5F2;
      color: $main-color;
      font-weight: 700;
    }
  }
  .news__link {
    color: $main-color;
    background-image: url(../img/arrow-icon06.png);
  }
}