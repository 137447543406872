@use "global" as *;

/* customer */
.customer {
  margin-top: rem(120);
  @include mq(sm) {
    margin-top: rem(80);
  }
}

.customer__items {
  margin-top: rem(70);
  @include mq(sm) {
    margin-top: rem(40);
  }
}

.customer__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(sm) {
    display: block;
  }
  &:first-child {
    flex-direction: row-reverse;
    margin-left: rem(100);
    @include mq(sm) {
      margin-left: 0;
    }
    .customer__item-img {
      width: 38%;
      @include mq(sm) {
        width: 100%;
      }
    }
  }
  &:nth-child(2) {
    margin-right: rem(110);
    margin-top: rem(90);
    @include mq(sm) {
      margin-right: 0;
      margin-top: rem(60);
    }
    .customer__item-img {
      width: 29%;
      @include mq(sm) {
        width: 100%;
      }
    }
  }
}

.customer__item-body {
  width: 53%;
  @include mq(sm) {
    width: 100%;
  }
}

.customer__item-text {
  font-size: rem(15);
  letter-spacing: 0.08em;
  line-height: 2.3;
  @include mq(sm) {
    font-size: rem(13);
    line-height: 1.7;
  }
  & + & {
    border-top: rem(1) solid #E3E6E6;
    padding-top: rem(34);
    margin-top: rem(34);
    @include mq(sm) {
      padding-top: rem(20);
      margin-top: rem(20);
    }
  }
}

.customer__item-img {
  @include mq(sm) {
    max-width: rem(400);
    margin: rem(20) auto 0;
  }
}

.customer__link {
  margin: rem(95) auto 0;
  @include mq(sm) {
    margin-top: rem(60);
  }
}