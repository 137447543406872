@use "global" as *;

/* card */
.card {

}

.card__inner {
  display: block;
  &:hover {
    opacity: 1;
    img {
      transform: scale(1.2);
    }
  }
}

.card__img {
  width: 100%;
  padding-top: calc(200 / 320 * 100%);
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .4s;
  }
}

.card__title {
  letter-spacing: 0.08em;
  margin-top: rem(16);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.card__subtitle {
  font-family: $second-font-family;
  font-weight: 300;
  letter-spacing: 0.06em;
  margin-top: rem(16);
  @include mq(sm) {
    font-size: rem(13);
  }
}

.card__heading {
  font-size: rem(18);
  letter-spacing: 0.08em;
  margin-top: rem(12);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  @include mq(sm) {
    font-size: rem(16);
    margin-top: rem(8);
  }
}

.card__category {
  margin-top: rem(10);
}