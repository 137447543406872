@use "global" as *;

/* concept */
.concept {
  
}

.concept__video {
  margin-top: rem(13);
  video {
    width: 100%;
    height: rem(700);
    object-fit: cover;
    @include mq(sm) {
      height: auto;
    }
  }
}

.concept__header {
  display: flex;
  justify-content: space-between;
  width: rem(940);
  max-width: 100%;
  margin: 0 auto;
  padding: rem(95) 0;
  @include mq(sm) {
    display: block;
    padding: rem(60) 0;
  }
}

.concept__header-body {
  width: 55%;
  @include mq(sm) {
    width: 100%;
  }
}

.concept__title {
  display: flex;
  align-items: center;
  font-size: rem(40);
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(24);
  }
  span {
    display: inline-block;
    font-size: rem(24);
    margin-left: rem(26);
    position: relative;
    @include mq(sm) {
      font-size: rem(18);
      margin-top: rem(6);
    }
    &::before {
      position: absolute;
      content: "";
      height: rem(1);
      width: rem(12);
      background: #000;
      left: rem(-17);
      top: rem(18);
      @include mq(sm) {
        top: rem(15);
      }
    }
  }
}

.concept__text {
  font-size: rem(15);
  letter-spacing: 0.08em;
  line-height: 2.4;
  @include mq(sm) {
    font-size: rem(13);
    line-height: 1.7;
  }
}

.concept__header-text {
  margin-top: rem(16);
}

.concept__header-img {
  width: 38.3%;
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: rem(20) auto 0;
  }
}

.concept__middle {
  padding: rem(95) 0 rem(95);
  background: url(../img/concept-bg.png) no-repeat center / cover;
  @include mq(sm) {
    padding: rem(60) 0;
  }
}

.concept__body {
  width: rem(720);
  max-width: 100%;
  margin: 0 auto;
}

.concept__body-text {
  margin-top: rem(35);
  @include mq(sm) {
    margin-top: rem(20);
  }
}

.concept__units {
  margin-top: rem(100);
  @include mq(sm) {
    margin-top: rem(60);
  }
}

.concept__unit {
  max-width: 100%;
  margin: 0 auto;
  & + & {
    margin-top: rem(115);
    @include mq(sm) {
      margin-top: rem(60);
    }
  }
}

.concept__unit-img {
  width: rem(540);
  max-width: 100%;
  margin: 0 auto;
  @include mq(sm) {
    width: rem(400);
  }
}

.concept__unit-text {
  display: flex;
  justify-content: center;
  margin-top: rem(30);
  @include mq(sm) {
    margin-top: rem(20);
  }
}

.concept__flex {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: rem(957);
  max-width: 100%;
  margin: rem(115) auto 0;
  @include mq(sm) {
    display: block;
    margin-top: rem(60);
  }
}

.concept__flex-text {
  width: 49.8%;
  @include mq(sm) {
    width: 100%;
  }
}

.concept__flex-img {
  width: 42.5%;
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: rem(20) auto 0;
  }
}

.concept__img {
  margin-top: rem(100);
  @include mq(sm) {
    margin-top: rem(60);
  }
  img {
    max-height: rem(1000);
    object-fit: cover;
  }
}

.concept__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem(120) 0;
  @include mq(sm) {
    display: block;
    padding: rem(60) 0;
  }
}

.concept__footer-title {
  font-size: rem(24);
  line-height: 1.46;
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(18);
  }
}

.concept__footer-img {
  width: rem(240);
  max-width: 100%;
  margin-left: rem(60);
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: rem(20) auto 0;
  }
}

.concept__footer-text {
  margin-left: rem(60);
  @include mq(sm) {
    margin-left: 0;
    margin-top: rem(20);
  }
}

.concept__items {

}

.concept__item {
  padding: rem(40) 0 rem(60);
  &:nth-child(odd) {
    background: #ECE9E8;
  }
  &:nth-child(even) {
    background: #E2E6E7;
  }
}

.concept__item-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @include mq(sm) {
    display: block;
  }
}

.concept__item-body {
  width: 50%;
  padding-left: rem(40);
  @include mq(sm) {
    width: 100%;
    padding-left: 0;
  }
}

.concept__item-number {
  font-size: rem(70);
  letter-spacing: 0.04em;
  color: #fff;
  @include mq(sm) {
    font-size: rem(32);
  }
}

.concept__item-title {
  font-size: rem(24);
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(16);
  }
}

.concept__item-text {
  width: 92%;
  margin-top: rem(50);
  @include mq(sm) {
    width: 100%;
    margin-top: rem(20);
  }
}

.concept__item-link {
  margin-top: rem(55);
  @include mq(sm) {
    font-size: rem(14);
    margin-top: rem(40);
  }
}

.concept__item-img {
  width: 48%;
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: rem(20) auto 0;
  }
}