@use "global" as *;

/* section-entitle */
.section-entitle {
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.12em;
  color: #9FA0A0;
}

section {
  .section-entitle {
    margin-bottom: rem(7);
  }
}