@use "global" as *;

/* consultation */
.consultation {
  background: #fff;
  border: rem(1) solid #ccc;
  padding: rem(65) rem(120) rem(65);
  @include mq(sm) {
    padding: rem(40) rem(20);
  }
}

.consultation__form {
  margin-top: rem(30);
}