@use "global" as *;

/* greeting */
.greeting {
  
}

.greeting__items {
  margin-top: rem(32);
  @include mq(sm) {
    margin-top: rem(25);
  }
}

.greeting__item {
  & + & {
    margin-top: rem(69);
    @include mq(sm) {
      margin-top: rem(50);
    }
  }
}

.greeting__item-title {
  text-align: center;
  font-size: rem(18);
  font-weight: 700;
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(16);
  }
}

.greeting__item-text {
  font-size: rem(15);
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: rem(20);
  @include mq(sm) {
    font-size: rem(14);
  }
}

.greeting__name {
  text-align: right;
  letter-spacing: 0.08em;
  margin-top: rem(70);
  @include mq(sm) {
    font-size: rem(15);
    margin-top: rem(50);
  }
}