@use "global"as *;

/* categories */
.categories {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  li {
    color: $main-color;
    font-size: rem(13);
    letter-spacing: 0.12em;
    background: #F5F5F2;
    margin-right: rem(25);
    margin-bottom: rem(15);
    @include mq(sm) {
      font-size: rem(12);
      margin-right: rem(10);
      margin-bottom: rem(10);
    }

    &.current-cat {
      color: #fff;
      background: $main-color;
    }
  }

  a {
    display: inline-block;
    padding: rem(2) rem(10) rem(3);
  }
}