@use "global" as *;

/* btn */
.btn {
  text-align: center;
  display: block;
  width: rem(300);
  max-width: 100%;
  font-size: rem(15);
  font-weight: 500;
  border: rem(1) solid #3F4040;
  padding: rem(14) rem(10);
  background: url(../img/arrow-icon02.png) no-repeat center right #{rem(22)} / rem(8);
  @include mq(sm) {
    width: rem(235);
    padding: rem(12) rem(10);
  }
}