@use "global" as *;

/* member */
.member {

}

.member__items {
  width: rem(880);
  max-width: 100%;
  margin: rem(35) auto 0;
}

.member__item {
  display: flex;
  justify-content: space-between;
  @include mq(sm) {
    display: block;
  }
  & + & {
    margin-top: rem(50);
  }
}

.member__item-img {
  width: 50%;
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: 0 auto;
  }
}

.member__item-body {
  width: 45%;
  margin-top: rem(-3);
  @include mq(sm) {
    width: 100%;
    margin-top: rem(15);
  }
}

.member__item-position {
  font-size: rem(14);
  letter-spacing: 0.08em;
  color: $main-color;
}

.member__item-names {
  display: flex;
  align-items: center;
  margin-top: rem(10);
}

.member__item-name {
  font-size: rem(20);
  letter-spacing: 0.04em;
  @include mq(sm) {
    font-size: rem(18);
  }
}

.member__item-enname {
  font-size: rem(13);
  letter-spacing: 0.04em;
  margin-left: rem(20);
}

.member__item-text {
  font-size: rem(15);
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: rem(12);
  @include mq(sm) {
    font-size: rem(14);
  }
}