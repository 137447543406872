@use "global"as *;

/* requirements */
.requirements {

}

.requirements__list {
  margin-top: rem(35);
  .list__item {
    &:last-child {
      border-bottom: none;
    }
  }
}