@use "global" as *;

/* voice-archive */
.voice-archive {
  
}

.voice-archive__cards {
  
}

.voice-archive__card {
  & + & {
    margin-top: rem(50);
    @include mq(sm) {
      margin-top: rem(40);
    }
  }
}