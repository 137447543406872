@use "global" as *;

/* durability */
.durability {
  width: rem(942);
  max-width: 100%;
  margin: rem(115) auto 0;
  @include mq(sm) {
    margin-top: rem(80);
  }
}

.durability__text {
  text-align: center;
  font-size: rem(15);
  letter-spacing: 0.08em;
  line-height: 2;
  margin-top: rem(35);
  @include mq(sm) {
    font-size: rem(13);
    line-height: 1.7;
    margin-top: rem(25);
  }
}

.durability__items {
  display: flex;
  margin-top: rem(35);
  @include mq(sm) {
    display: block;
  }
}

.durability__item {
  width: calc(50% - #{rem(44)} * 1 / 2);
  @include mq(sm) {
    width: 100%;
    & + & {
      margin-top: rem(30);
    }
  }
  &:not(:nth-child(2n + 1)) {
    margin-left: rem(44);
    @include mq(sm) {
      margin-left: auto;
    }
  }
}

.durability__item-img {
  @include mq(sm) {
    max-width: rem(400);
    margin: 0 auto;
  }
}

.durability__item-text {
  text-align: center;
  font-size: rem(15);
  letter-spacing: 0.08em;
  margin-top: rem(20);
  @include mq(sm) {
    font-size: rem(13);
    margin-top: rem(15);
  }
}