@use "global" as *;

/* privacy */
.privacy {

}

.privacy__content {
  padding: rem(25) 0 rem(100);
}

.privacy__items {

}

.privacy__item {
  & + & {
    margin-top: rem(78);
    @include mq(sm) {
      margin-top: rem(60);
    }
  }
}

.privacy__item-title {
  text-align: center;
  font-size: rem(20);
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(18);
  }
}

.privacy__item-text {
  font-size: rem(15);
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: rem(25);
}

.privacy__link {
  margin: rem(85) auto 0;
  @include mq(sm) {
    margin-top: rem(60);
  }
}