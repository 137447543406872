@use "global" as *;

/* case-single */
.case-single {
  
}

.case-single__title {
  text-align: center;
  font-size: rem(20);
  letter-spacing: 0.08em;
}

.case-single__category {
  margin-top: rem(45);
  @include mq(sm) {
    margin-top: rem(40);
  }
  span {
    text-align: center;
    min-width: rem(98);
    padding: rem(4) rem(10);
    @include mq(sm) {
      min-width: unset;
    }
  }
}

.case-single__thumbnail {
  margin-top: rem(10);
  @include mq(sm) {
    max-width: rem(400);
    margin: rem(10) auto 0;
  }
}

.case-single__post {
  padding: rem(50) 0;
  margin-top: rem(100);
  @include mq(sm) {
    margin-top: rem(60);
    padding: rem(40) 0;
  }
  p {
    font-size: rem(14);
    letter-spacing: 0.08em;
    line-height: 3.6;
    margin-bottom: 0;
    @include mq(sm) {
      line-height: 2;
    }
    &:not(:last-child) {
      margin-bottom: rem(40);
    }
  }
}

.case-single__modal {
  margin-top: rem(100);
  @include mq(sm) {
    margin-top: rem(60);
  }
}

.case-single__modal-imgs {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(-20);
  @include mq(sm) {
    margin-top: rem(-10);
  }
}

.case-single__modal-img-wrapper {
  width: calc(33.33333333% - #{rem(20)} * 2 / 3);
  margin-top: rem(20);
  @include mq(sm) {
    width: calc(50% - #{rem(10)} * 1 / 2);
    margin-top: rem(10);
  }
  &:not(:nth-child(3n + 1)) {
    margin-left: rem(20);
    @include mq(sm) {
      margin-left: 0;
    }
  }
  &:not(:nth-child(2n + 1)) {
    @include mq(sm) {
      margin-left: rem(10);
    }
  }
}

.case-single__modal-img {
  width: 100%;
  padding-top: calc(334 / 334 * 100%);
  position: relative;
  overflow: hidden;
  &:hover {
    opacity: 1;
    img {
      transform: scale(1.2);
    }
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .4s;
  }
}

.case-single__links {
  margin-top: rem(65);
}