@use "global" as *;

/* staff */
.staff {
  
}

.staff__items {
  width: rem(880);
  max-width: 100%;
  margin: rem(50) auto 0;
  @include mq(sm) {
    margin-top: rem(30);
  }
}

.staff__item {
  min-height: rem(300);
  padding: rem(30);
  position: relative;
  @include mq(sm) {
    padding: rem(15);
  }
  & + & {
    margin-top: rem(60);
    @include mq(sm) {
      margin-top: rem(40);
    }
  }
  &:nth-child(even) {
    .staff__item-body {
      margin-left: auto;
    }
  }
}

.staff__item-img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  img {
    height: 100%;
    object-fit: cover;
  }
}

.staff__item-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: inherit;
  min-height: rem(250);
  width: 50%;
  position: relative;
  @include mq(sm) {
    width: 52%;
  }
}

.staff__item-header {
  display: flex;
  @include mq(sm) {
    display: block;
  }
}

.staff__item-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(33.33333333% - #{rem(15)} * 2 / 3);
  text-align: center;
  letter-spacing: 0.08em;
  font-size: rem(15);
  font-weight: 700;
  color: #fff;
  background: #3F4040;
  padding: rem(8);
  @include mq(sm) {
    font-size: rem(14);
    width: auto;
    padding: rem(5);
    & + & {
      margin-top: rem(5);
    }
  }
  &:not(:nth-child(3n + 1)) {
    margin-left: rem(15);
    @include mq(sm) {
      margin-left: 0;
    }
  }
}

.staff__item-text {
  font-size: rem(15);
  letter-spacing: 0.08em;
  margin-top: rem(20);
  @include mq(sm) {
    font-size: rem(14);
    margin-top: rem(10);
  }
}

.staff__units {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(60);
}

.staff__unit {
  width: 25%;
  position: relative;
  cursor: pointer;
  @include mq(sm) {
    width: 50%;
  }
  &:hover {
    .staff__unit-body {
      opacity: 1;
    }
  }
}

.staff__unit-img {
  width: 100%;
  padding-top: calc(199 / 264 * 100%);
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: rem(1) solid #CECECE;
  }
}

.staff__unit-body {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000, .5);
  display: flex;
  align-items: center;
  transition: opacity .3s;
  padding: rem(15);
  @include mq(sm) {
    padding: rem(10);
  }
}

.staff__unit-texts {
}

.staff__unit-text {
  font-size: rem(17);
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(14);
  }
  & + & {
    margin-top: rem(6);
  }
  &:last-child {
    font-size: rem(15);
    @include mq(sm) {
      font-size: rem(13);
    }
  }
}

.staff__flex {
  display: flex;
  justify-content: space-between;
  width: rem(880);
  max-width: 100%;
  margin: rem(76) auto 0;
  @include mq(sm) {
    display: block;
    margin-top: rem(60);
  }
}

.staff__flex-item {
  font-size: rem(15);
  line-height: 1.8;
  letter-spacing: 0.04em;
  &:last-child {
    margin-left: rem(20);
    @include mq(sm) {
      margin-left: 0;
      margin-top: rem(30);
    }
  }
}

.staff__flex-title {
  font-weight: 700;
}

.staff__list {

}

.staff__list-item {

}

.staff__banner {
  background: #F5F5F5;
  padding: rem(100) 0;
  margin-top: rem(95);
  @include mq(sm) {
    padding: rem(80) 0;
    margin-top: rem(80);
  }
}