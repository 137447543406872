@use "global" as *;

/* voice-card */
.voice-card {
  border: rem(1) solid #ccc;
}

.voice-card__inner {
  display: flex;
  flex-direction: row-reverse;
  @include mq(sm) {
    display: block;
  }
  &:hover {
    opacity: 1;
    img {
      transform: scale(1.2);
    }
  }
}

.voice-card__img-wrapper {
  width: 35%;
  @include mq(sm) {
    width: 100%;
    height: rem(150);
  }
}

.voice-card__img {
  width: 100%;
  height: 100%;
  padding-top: calc(156 / 307 * 100%);
  overflow: hidden;
  position: relative;
  @include mq(sm) {
    padding-top: unset;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .4s;
  }
}

.voice-card__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 65%;
  padding: rem(20) rem(105) rem(20) rem(70);
  @include mq(sm) {
    width: 100%;
    padding: rem(20) rem(20);
  }
}

.voice-card__text {
  font-size: rem(14);
  line-height: 1.7;
  letter-spacing: 0.08em;
}

.voice-card__subtext {
  font-size: rem(14);
  line-height: 1.7;
  letter-spacing: 0.08em;
  color: $main-color;
  font-weight: 700;
}