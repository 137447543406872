@use "global" as *;

/* case */
.case {

}

.case__cards {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(-30);
  @include mq(sm) {
    display: block;
    margin-top: rem(40);
  }
}

.case__card {
  width: calc(33.33333333% - #{rem(40)} * 2 / 3);
  margin-top: rem(65);
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: 0 auto;
    & + & {
      margin-top: rem(40);
    }
  }
  &:not(:nth-child(3n + 1)) {
    margin-left: rem(40);
    @include mq(sm) {
      margin-left: auto;
    }
  }
  .card__text {
    font-size: rem(15);
    line-height: 1.73;
    margin-top: rem(10);
  }
}



.case__link {
  margin: rem(60) auto 0;
  @include mq(sm) {
    margin-top: rem(40);
  }
}