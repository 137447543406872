@use "global" as *;

/* excluded */
.excluded {
  border: rem(1) solid #707070;
  width: rem(974);
  max-width: 100%;
  margin: rem(65) auto 0;
  padding: rem(20) rem(45) rem(50) rem(50);
  @include mq(sm) {
    padding: rem(20) rem(15);
  }
}

.excluded__flex {
  display: flex;
  justify-content: space-between;
  margin-top: rem(25);
  @include mq(sm) {
    display: block;
  }
}

.excluded__img {
  width: 33%;
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: 0 auto;
  }
}

.excluded__body {
  width: 65%;
  margin-top: rem(-5);
  @include mq(sm) {
    width: 100%;
    font-size: rem(15);
    margin-top: rem(20);
  }
}

.excluded__text {
  line-height: 1.69;
}

.excluded__subtext {
  font-size: rem(14);
  line-height: 1;
  margin-top: rem(20);
  @include mq(sm) {
    line-height: 1.5;
  }
}

.excluded__link {
  display: inline-block;
  font-size: rem(14);
  text-decoration: underline;
  word-break: break-all;
  @include mq(sm) {
  }
}