@use "global" as *;

/* top-concept */
.top-concept {
  padding: rem(55) 0 rem(60);
  @include mq(sm) {
    padding: rem(48) 0 rem(50);
  }
}

.top-concept__text {
  text-align: center;
  font-size: rem(15);
  letter-spacing: 0.04em;
  margin-top: rem(31);
  @include mq(sm) {
    line-height: 1.73;
  }
}

.top-concept__items {
  display: flex;
  margin-top: rem(40);
  @include mq(sm) {
    display: block;
    margin-top: rem(35);
  }
}

.top-concept__item {
  width: calc(33.33333333% - #{rem(10)} * 2 / 3);
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: 0 auto;
    & + & {
      margin-top: rem(50);
    }
  }
  &:not(:nth-child(3n + 1)) {
    margin-left: rem(10);
    @include mq(sm) {
      margin-left: auto;
    }
  }
}

.top-concept__item-img {
  width: 100%;
  padding-top: calc(220 / 340 * 100%);
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .4s;
  }
}

.top-concept__item-title {
  text-align: center;
  font-size: rem(18);
  letter-spacing: 0.08em;
  margin-top: rem(25);
  @include mq(sm) {
    margin-top: rem(22);
  }
}

.top-concept__link {
  margin: rem(62) auto 0;
  @include mq(sm) {
    width: rem(300);
    padding: rem(14) rem(10);
    margin-top: rem(48);
  }
}