@use "global"as *;

/* news */
.news {
  
}

.news__inner {
  display: flex;
  justify-content: space-between;
  @include mq(sm) {
    display: block;
  }
}

.news__titles {

}

.news__content {
  width: 84.5%;
  @include mq(sm) {
    width: 100%;
    margin-top: rem(30);
  }
}

.news__body {}

.news__link-wrapper {
  text-align: right;
  margin-right: rem(20);
  @include mq(sm) {
    margin-right: 0;
  }
}

.news__link {
  display: inline-block;
  font-size: rem(15);
  font-weight: 500;
  border-bottom: rem(1) solid $main-color;
  background: url(../img/arrow-icon02.png) no-repeat center right / rem(9);
  padding: rem(10) rem(50) rem(10) rem(5);
  margin-top: rem(20);
}

.news__items {
  border-top: rem(1) dashed #ccc;
}

.news__item {
  border-bottom: rem(1) dashed #ccc;
}

.news__item-inner {
  display: flex;
  align-items: center;
  padding: rem(20) rem(25) rem(20) 0;
  transition: background .3s;
  position: relative;
  @include mq(sm) {
    display: block;
    padding: rem(19) rem(20) rem(19) 0;
    background-position: center right;
  }
  &::after {
    position: absolute;
    content: "";
    width: rem(10);
    height: rem(10);
    top: 50%;
    transform: translateY(-50%);
    right: rem(20);
    background: url(../img/arrow-icon02.png) no-repeat center / contain;
  }
  &:hover {
    opacity: 1;
    background: #F7F7F7;
  }
}

.news__item-header {
  display: flex;
  align-items: center;
}

.news__item-date {
  font-family: $second-font-family;
  letter-spacing: 0.06em;
  white-space: nowrap;
  @include mq(sm) {
    font-size: rem(14);
  }
}

.news__item-category {
  margin-left: rem(23);
  text-align: center;
  @include mq(sm) {
    margin-left: rem(15);
  }
  span {
    display: inline-block;
    min-width: rem(120);
    font-size: rem(14);
    padding: rem(5) rem(5);
    color: #7E8080;
    background: #F5F5F5;
    @include mq(sm) {
      min-width: rem(80);
      font-size: rem(12);
      padding: rem(2) rem(5);
    }
  }
}

.news__item-title {
  letter-spacing: 0.04em;
  margin-left: rem(30);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @include mq(sm) {
    font-size: rem(15);
    margin-top: rem(10);
    margin-left: 0;
    text-overflow: unset;
    white-space: unset;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}