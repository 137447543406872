@use "global" as *;

/* design */
.design {
  width: rem(922);
  max-width: 100%;
  margin: rem(115) auto 0;
  @include mq(sm) {
    margin-top: rem(80);
  }
}

.design__title {
  white-space: unset;
}

.design__items {
  margin-top: rem(100);
  @include mq(sm) {
    margin-top: rem(40);
  }
}

.design__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(sm) {
    display: block;
  }
  & + & {
    margin-top: rem(100);
    @include mq(sm) {
      margin-top: rem(80);
    }
  }
}

.design__item--reverse {
  flex-direction: row-reverse;
}

.design__item--a {
  .design__item-img {
    width: 39%;
    @include mq(sm) {
      width: 100%;
    }
  }
  .design__item-body {
    width: 55%;
    @include mq(sm) {
      width: 100%;
    }
  }
}

.design__item--b {
  .design__item-img {
    width: 28%;
    @include mq(sm) {
      width: 100%;
    }
  }
  .design__item-body {
    width: 64%;
    @include mq(sm) {
      width: 100%;
    }
  }
}

.design__item-texts {
  & + & {
    margin-top: rem(65);
    @include mq(sm) {
      margin-top: rem(30);
    }
  }
}

.design__item-title {
  font-size: rem(20);
  letter-spacing: 0.08em;
  line-height: 1.7;
  @include mq(sm) {
    font-size: rem(16);
  }
}

.design__item-text {
  font-size: rem(15);
  letter-spacing: 0.08em;
  line-height: 2.3;
  margin-top: rem(25);
  @include mq(sm) {
    font-size: rem(13);
    line-height: 1.7;
    margin-top: rem(15);
  }
}

.design__item-img {
  @include mq(sm) {
    max-width: rem(400);
    margin: rem(20) auto 0;
  }
}