@use "global" as *;

/* case-archive */
.case-archive {
  
}

.case-archive__youtube-wrapper {
  width: rem(650);
  max-width: 100%;
  margin: 0 auto;
  @include mq(sm) {
    width: rem(500);
  }
}

.case-archive__youtube {
  width: 100%;
  padding-top: 367 / 652 * 100%;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.case-archive__category {
  margin-top: rem(100);
  @include mq(sm) {
    margin-top: rem(60);
  }
  &.categories {
    justify-content: flex-start;
  }
}

.case-archive__items {
  display: flex;
  flex-wrap: wrap;
  @include mq(sm) {
    display: block;
    margin-top: rem(40);
  }
}

.case-archive__item {
  width: calc(33.33333333% - #{rem(40)} * 2 / 3);
  margin-top: rem(70);
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: 0 auto;
    & + & {
      margin-top: rem(40);
    }
  }
  &:not(:nth-child(3n + 1)) {
    margin-left: rem(40);
    @include mq(sm) {
      margin-left: auto;
    }
  }
  .card__title {
    margin-top: rem(12);
  }
}
