@charset "UTF-8";
html {
  font-family: "Zen Old Mincho", serif;
  color: #000;
  line-height: 1.5;
}

@media screen and (max-width: 767px) {
  .u-pc {
    display: none;
  }
}

.u-sp {
  display: none;
}
@media screen and (max-width: 767px) {
  .u-sp {
    display: block;
  }
}

/* リキッドレイアウト対応 */
html {
  font-size: 16px;
}
@media (max-width: 375px) {
  html {
    font-size: 4.2666666667vw;
  }
}
@media screen and (min-width: 768px) {
  html {
    font-size: 1.3333333333vw;
  }
}
@media (min-width: 1200px) {
  html {
    font-size: 16px;
  }
}

/* pcの電話番号発信対応 */
a[href^="tel:"] {
  pointer-events: none;
}
@media screen and (max-width: 1023px) {
  a[href^="tel:"] {
    pointer-events: unset;
  }
}

/* ホバー */
a {
  text-decoration: none;
  color: inherit;
  transition: opacity 0.3s;
}
a:hover {
  opacity: 0.7;
}
@media screen and (max-width: 767px) {
  a:hover {
    opacity: 1;
  }
}

button {
  transition: opacity 0.3s;
}
button:hover {
  opacity: 0.7;
}
@media screen and (max-width: 767px) {
  button:hover {
    opacity: 1;
  }
}

* ::-webkit-scrollbar {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default type */
ul,
ol {
  list-style: none;
}

li, dd {
  list-style-type: none;
}

/* Remove default margin padding */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
figure,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
}

/* Make images easier to work with */
img {
  display: block;
  width: 100%;
  height: auto;
}

picture {
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
/* フォームリセット */
input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type=checkbox],
input[type=radio] {
  display: none;
}

input[type=submit],
input[type=button],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

button {
  padding: 0;
}

/* l-banner */
.l-banner {
  margin: 6.25rem 0;
}
@media screen and (max-width: 767px) {
  .l-banner {
    margin: 5rem 0;
  }
}

/* l-body */
.l-body {
  width: 55rem;
  max-width: 100%;
  margin: 0 auto;
}

.l-inner {
  padding-right: 20px;
  padding-left: 20px;
  margin-right: 2.8125rem;
  margin-left: 15rem;
  max-width: 93.75rem;
}
@media screen and (min-width: 2000px) {
  .l-inner {
    margin: 0 auto;
  }
}
@media screen and (max-width: 1350px) {
  .l-inner {
    margin-right: 1.25rem;
  }
}
@media screen and (max-width: 767px) {
  .l-inner {
    margin: 0;
    max-width: unset;
    padding-right: 30px;
    padding-left: 30px;
  }
}

@media screen and (max-width: 767px) {
  .l-inner--wide {
    padding: 0 0.9375rem;
  }
}

/* l-pagination */
.l-pagination {
  margin-top: 5.9375rem;
}
@media screen and (max-width: 767px) {
  .l-pagination {
    margin-top: 5rem;
  }
}

/* l-section */
.l-section {
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .l-section {
    margin-top: 5rem;
  }
}

/* banner */
.banner {
  display: flex;
  flex-direction: row-reverse;
  border: 0.0625rem solid #ccc;
}
@media screen and (max-width: 767px) {
  .banner {
    display: block;
  }
}

.banner__img {
  width: 44%;
}
@media screen and (max-width: 1350px) {
  .banner__img {
    width: 40%;
  }
}
@media screen and (max-width: 767px) {
  .banner__img {
    width: 100%;
  }
}
.banner__img img {
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 767px) {
  .banner__img img {
    height: 12.5rem;
  }
}

.banner__body {
  width: 56%;
}
@media screen and (max-width: 1350px) {
  .banner__body {
    width: 60%;
  }
}
@media screen and (max-width: 767px) {
  .banner__body {
    width: 100%;
  }
}

.banner__title {
  height: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  letter-spacing: 0.04em;
  line-height: 1.96;
  background: #F5F5F2;
  padding: 1.25rem;
}
@media screen and (max-width: 1350px) {
  .banner__title {
    height: 50%;
    font-size: 1.375rem;
    line-height: 1.7;
  }
}
@media screen and (max-width: 767px) {
  .banner__title {
    display: block;
    height: auto;
    font-size: 1.125rem;
    padding: 0.9375rem;
  }
}

.banner__footer {
  height: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 1.25rem;
}
@media screen and (max-width: 1350px) {
  .banner__footer {
    height: 50%;
  }
}
@media screen and (max-width: 767px) {
  .banner__footer {
    height: auto;
    display: block;
    padding: 1.25rem 0.9375rem;
  }
}

.banner__text {
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
}

.banner__number {
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 2.0625rem;
  letter-spacing: 0.03em;
  font-weight: 300;
  line-height: 1.4;
  margin-top: 0.1875rem;
}
@media screen and (max-width: 767px) {
  .banner__number {
    font-size: 1.75rem;
  }
}

.banner__subtext {
  font-size: 0.875rem;
}

.banner__btn {
  text-align: center;
  display: block;
  width: 12.5rem;
  max-width: 100%;
  padding: 0.6875rem 0;
  font-size: 0.9375rem;
  color: #fff;
  background: #445F39;
  margin-left: 2.8125rem;
  position: relative;
}
@media screen and (max-width: 1350px) {
  .banner__btn {
    margin-left: 1.875rem;
  }
}
@media screen and (max-width: 767px) {
  .banner__btn {
    margin-left: 0;
    margin-top: 0.9375rem;
  }
}
.banner__btn::after {
  position: absolute;
  content: "";
  width: 0.4375rem;
  height: 0.4375rem;
  top: 50%;
  transform: translateY(-50%);
  right: 0.9375rem;
  background: url(../img/arrow-icon01.png) no-repeat center/contain;
}

/* bg */
.bg {
  background: #F5F5F2;
  padding: 4.375rem 0;
}
@media screen and (max-width: 767px) {
  .bg {
    padding: 3.125rem 0;
  }
}

/* breadcrumb */
.breadcrumb {
  background: #F5F5F2;
}

.breadcrumb__items {
  display: flex;
  flex-wrap: wrap;
}

.breadcrumb__item {
  font-size: 0.8125rem;
  line-height: 1.4;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .breadcrumb__item {
    font-size: 0.75rem;
  }
}
.breadcrumb__item + .breadcrumb__item {
  margin-left: 2.5rem;
  position: relative;
}
.breadcrumb__item + .breadcrumb__item::before {
  position: absolute;
  content: ">";
  left: -1.625rem;
  top: 0.25rem;
}
.breadcrumb__item a, .breadcrumb__item span {
  display: inline-block;
  padding: 0.25rem 0;
}

/* btn */
.btn {
  text-align: center;
  display: block;
  width: 18.75rem;
  max-width: 100%;
  font-size: 0.9375rem;
  font-weight: 500;
  border: 0.0625rem solid #3F4040;
  padding: 0.875rem 0.625rem;
  background: url(../img/arrow-icon02.png) no-repeat center right 1.375rem/0.5rem;
}
@media screen and (max-width: 767px) {
  .btn {
    width: 14.6875rem;
    padding: 0.75rem 0.625rem;
  }
}

/* calendar */
.calendar {
  position: relative;
}
.calendar table {
  width: 100%;
  text-align: center;
}
.calendar table tr:first-child th:nth-of-type(1) {
  color: #DB5050;
}
.calendar table tr:first-child th:nth-of-type(7) {
  color: #496BF8;
}
.calendar table th {
  font-weight: 400;
}
@media screen and (max-width: 767px) {
  .calendar table th {
    font-size: 0.625rem;
  }
}
.calendar table td {
  cursor: pointer;
  font-size: 1.25rem;
  padding: 0.4375rem 0;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .calendar table td {
    font-size: 0.9375rem;
    padding: 0.375rem 0;
  }
}
.calendar table td::after {
  position: absolute;
  content: "";
  width: 1.9375rem;
  height: 1.8125rem;
  border-radius: 0.3125rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .calendar table td::after {
    width: 1.5rem;
    height: 1.375rem;
  }
}
.calendar table td.yesterday:after {
  background: #A6DD95;
}
.calendar table td.today {
  color: #fff;
}
.calendar table td.today::after {
  background: #445F39;
}

.calendar__arrow {
  position: absolute;
  top: 53%;
  transform: translateY(-50%);
  width: 0.875rem;
}
@media screen and (max-width: 767px) {
  .calendar__arrow {
    width: 0.625rem;
  }
}

.calendar__prev {
  left: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .calendar__prev {
    left: -1.25rem;
  }
}

.calendar__next {
  right: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .calendar__next {
    right: -1.25rem;
  }
}

.calendar__header {
  display: flex;
  align-items: center;
  margin-left: 0.625rem;
}

.calendar__number {
  font-size: 4.375rem;
  line-height: 1;
  margin-bottom: 0.625rem;
}
@media screen and (max-width: 767px) {
  .calendar__number {
    font-size: 3.3125rem;
  }
}

.calendar__years {
  padding-left: 1.25rem;
  margin-left: 1.25rem;
  border-left: 0.0625rem solid #000;
  padding-bottom: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .calendar__years {
    padding-left: 0.9375rem;
    margin-left: 0.9375rem;
  }
}

.calendar__year {
  display: block;
  font-size: 1.25rem;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .calendar__year {
    font-size: 0.9375rem;
  }
}

.calendar__month {
  display: block;
  font-size: 1.875rem;
  line-height: 1;
}
@media screen and (max-width: 767px) {
  .calendar__month {
    font-size: 1.375rem;
  }
}

/* card */
.card__inner {
  display: block;
}
.card__inner:hover {
  opacity: 1;
}
.card__inner:hover img {
  transform: scale(1.2);
}

.card__img {
  width: 100%;
  padding-top: 62.5%;
  position: relative;
  overflow: hidden;
}
.card__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s;
}

.card__title {
  letter-spacing: 0.08em;
  margin-top: 1rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.card__subtitle {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  letter-spacing: 0.06em;
  margin-top: 1rem;
}
@media screen and (max-width: 767px) {
  .card__subtitle {
    font-size: 0.8125rem;
  }
}

.card__heading {
  font-size: 1.125rem;
  letter-spacing: 0.08em;
  margin-top: 0.75rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .card__heading {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
}

.card__category {
  margin-top: 0.625rem;
}

/* cards */
.cards {
  display: flex;
}
@media screen and (max-width: 767px) {
  .cards {
    overflow-x: auto;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
    flex-wrap: nowrap;
    margin-right: calc(50% - 50vw);
  }
}

.cards__item {
  width: calc(33.33333333% - 2.5rem * 2 / 3);
}
@media screen and (max-width: 767px) {
  .cards__item {
    width: 11.875rem;
    min-width: 11.875rem;
    width: 54%;
    min-width: 54%;
    position: relative;
  }
  .cards__item:last-child::after {
    position: absolute;
    content: "";
    top: 0;
    left: 100%;
    height: 0.0625rem;
    width: 1.25rem;
    background-color: transparent;
  }
}
.cards__item:not(:nth-child(3n+1)) {
  margin-left: 2.5rem;
}
@media screen and (max-width: 767px) {
  .cards__item:not(:nth-child(3n+1)) {
    margin-left: 1.25rem;
  }
}

/* categories */
.categories {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.categories li {
  color: #445F39;
  font-size: 0.8125rem;
  letter-spacing: 0.12em;
  background: #F5F5F2;
  margin-right: 1.5625rem;
  margin-bottom: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .categories li {
    font-size: 0.75rem;
    margin-right: 0.625rem;
    margin-bottom: 0.625rem;
  }
}
.categories li.current-cat {
  color: #fff;
  background: #445F39;
}
.categories a {
  display: inline-block;
  padding: 0.125rem 0.625rem 0.1875rem;
}

/* category */
.category span {
  display: inline-block;
  font-size: 0.875rem;
  letter-spacing: 0.12em;
  color: #445F39;
  background: #F5F5F2;
  padding: 0.3125rem 0.9375rem;
  margin-right: 0.8125rem;
  margin-bottom: 0.625rem;
}
@media screen and (max-width: 767px) {
  .category span {
    font-size: 0.8125rem;
    padding: 0.25rem 0.625rem;
    margin-right: 0.625rem;
  }
}

/* drawer */
.drawer {
  position: fixed;
  z-index: 20;
  top: 3.75rem;
  left: 0;
  color: #000;
  background: #fff;
  transform: translateY(-200%);
  transition: all 0.6s;
  width: 100%;
  height: 100%;
  padding-top: 1.875rem;
  overflow-y: scroll;
  padding-bottom: 6.25rem;
}

.drawer__items {
  text-align: center;
}

.drawer__item {
  width: 100%;
  font-size: 0.9375rem;
}
.drawer__item + .drawer__item {
  margin-top: 0.625rem;
}
.drawer__item a {
  padding: 0.625rem 0;
  display: block;
}

.drawer__menu {
  margin-top: 1.875rem;
}

.drawer__menu-item {
  text-align: center;
  width: 12.5rem;
  max-width: 100%;
  background: #5A3234;
  margin: 0 auto;
}
.drawer__menu-item + .drawer__menu-item {
  margin-top: 0.625rem;
}
.drawer__menu-item a {
  display: block;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  color: #fff;
  text-align: center;
  padding: 0.6875rem 0.625rem;
  position: relative;
  background: url(../img/arrow-icon01.png) no-repeat center right 0.9375rem/0.4375rem;
}
.drawer__menu-item a::after {
  position: absolute;
  content: "";
}

.drawer__icon {
  display: block;
  width: 2.8125rem;
  margin: 1.875rem auto 0;
}

/* このクラスを、jQueryで付与・削除する */
.drawer--active {
  transform: translateY(0%);
}

.drawer--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

/* fade */
.js-fade {
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: opacity 0.8s, transform 0.8s;
}
.js-fade.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px);
}

/* fnav */
.fnav {
  display: flex;
}
@media screen and (max-width: 767px) {
  .fnav {
    text-align: left;
    display: block;
    border-top: 0.0625rem solid #999;
  }
}

@media screen and (max-width: 767px) {
  .fnav__items {
    border-bottom: 0.0625rem solid #999;
    padding: 0.75rem 0;
  }
}
.fnav__items + .fnav__items {
  margin-left: 3.875rem;
}
@media screen and (max-width: 1350px) {
  .fnav__items + .fnav__items {
    margin-left: 2.5rem;
  }
}
@media screen and (max-width: 767px) {
  .fnav__items + .fnav__items {
    margin-left: 0;
  }
}

.fnav__item {
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.08em;
  position: relative;
}
.fnav__item::before, .fnav__item::after {
  position: absolute;
}
@media screen and (max-width: 767px) {
  .fnav__item::before, .fnav__item::after {
    width: 0.9375rem;
    height: 0.0625rem;
    background: #999;
    right: 0.625rem;
  }
}
.fnav__item::before {
  content: "-";
  left: -1em;
  top: 0.2em;
}
@media screen and (max-width: 767px) {
  .fnav__item::before {
    content: "";
    left: unset;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media screen and (max-width: 767px) {
  .fnav__item::after {
    content: "";
    top: 48%;
    transform: rotate(90deg);
    transition: 0.2s;
  }
}
@media screen and (max-width: 767px) {
  .fnav__item.opened::after {
    transform: rotate(0deg);
  }
}
.fnav__item a, .fnav__item span {
  display: inline-block;
  padding: 0.3125rem 0;
}

@media screen and (max-width: 767px) {
  .fnav__subitem-wrapper {
    display: none;
  }
}

.fnav__subitems {
  margin-top: 0.75rem;
}
@media screen and (max-width: 767px) {
  .fnav__subitems {
    margin-top: 0.3125rem;
  }
}

.fnav__subitem {
  font-size: 0.875rem;
  letter-spacing: 0.08em;
  color: #666;
}
.fnav__subitem a {
  display: block;
  padding: 0.3125rem 0;
}

/* footer */
.footer {
  padding: 1.75rem 0 2.375rem;
}
@media screen and (max-width: 767px) {
  .footer {
    text-align: center;
    padding: 2.5rem 0 2.8125rem;
  }
}

.footer__inner {
  border-left: 0.0625rem solid #D9D9D9;
  padding-left: 3.1875rem;
  padding-right: 5rem;
}
@media screen and (max-width: 1350px) {
  .footer__inner {
    padding: 0 1.25rem;
    border-left: none;
  }
}

.footer__flex {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .footer__flex {
    display: block;
  }
}

.footer__body {
  padding-top: 0.75rem;
}
@media screen and (max-width: 767px) {
  .footer__body {
    padding-top: 0;
    margin-top: 2.5rem;
  }
}

.footer__logo {
  width: 10.0625rem;
  max-width: 100%;
}
@media screen and (max-width: 767px) {
  .footer__logo {
    margin: 0 auto;
  }
}

.footer__text {
  font-size: 0.875rem;
  color: #666;
  line-height: 1.78;
  letter-spacing: 0.04em;
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .footer__text {
    font-size: 0.8125rem;
    line-height: 1.92;
  }
}

.footer__copyright {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 0.875rem;
  letter-spacing: 0.08em;
  margin-top: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .footer__copyright {
    font-size: 0.75rem;
    margin-top: 1.5625rem;
  }
}

/* form */
.form .mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 0;
}

.form__item {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .form__item {
    display: block;
  }
}
.form__item + .form__item {
  margin-top: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .form__item + .form__item {
    margin-top: 1.5625rem;
  }
}

.form__item--block {
  display: block;
}
.form__item--block.form__item .form__item-label {
  width: 100%;
}
.form__item--block.form__item .form__item-input {
  width: 100%;
  margin-top: 0.9375rem;
}

.form__item-label {
  width: 40%;
}
@media screen and (max-width: 767px) {
  .form__item-label {
    width: 100%;
  }
}
.form__item-label label {
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  font-weight: 700;
}
.form__item-label label span {
  display: block;
  font-size: 0.625rem;
  font-weight: 500;
  letter-spacing: 0.12em;
}
.form__item-label > span {
  display: inline-block;
  font-size: 0.75rem;
  letter-spacing: 0.12em;
  color: #fff;
  background: #445F39;
  padding: 0.125rem 0.625rem;
  margin-left: 0.375rem;
}

.form__item-label-inner > span {
  display: inline-block;
  font-size: 0.75rem;
  letter-spacing: 0.12em;
  color: #fff;
  background: #445F39;
  padding: 0.125rem 0.625rem;
  margin-left: 0.375rem;
}

.form__item-label-text {
  font-size: 0.75rem;
  letter-spacing: 0.08em;
  margin-top: 0.3125rem;
}

.form__item-input {
  width: 58.5%;
}
@media screen and (max-width: 767px) {
  .form__item-input {
    width: 100%;
    margin-top: 0.625rem;
  }
}

.form__item-input--short input {
  width: 6.25rem;
}

input, textarea {
  border: 0.0625rem solid #ccc;
  width: 100%;
  padding: 0 0.625rem;
}

input {
  height: 1.5625rem;
}
@media screen and (max-width: 767px) {
  input {
    height: 2.5rem;
  }
}

textarea {
  height: 3.6875rem;
  resize: none;
}
@media screen and (max-width: 767px) {
  textarea {
    height: 5rem;
  }
}

select {
  border: 0.0625rem solid #ccc;
  width: 6.25rem;
  height: 1.5625rem;
  padding: 0 0.9375rem 0 0.75rem;
  color: #000;
}
@media screen and (max-width: 767px) {
  select {
    height: 2.5rem;
  }
}

.form__select-wrapper {
  position: relative;
}
.form__select-wrapper::after {
  position: absolute;
  content: "";
  left: 5.3125rem;
  top: 0.625rem;
  border-right: 0.25rem solid transparent;
  border-top: 0.375rem solid #ccc;
  border-left: 0.25rem solid transparent;
}
@media screen and (max-width: 767px) {
  .form__select-wrapper::after {
    top: 1.125rem;
  }
}

.form__radio {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -0.3125rem;
}
.form__radio > span {
  margin-right: 4.0625rem;
  margin-bottom: 0.3125rem;
}
.form__radio input[type=radio] + span {
  cursor: pointer;
  font-size: 0.875rem;
  letter-spacing: 0.08em;
  display: inline-block;
  padding-left: 1.625rem;
  position: relative;
}
.form__radio input[type=radio] + span::before, .form__radio input[type=radio] + span::after {
  position: absolute;
  content: "";
}
.form__radio input[type=radio] + span::before {
  display: block;
  background: #fff;
  border-radius: 50%;
  border: 0.0625rem solid #ccc;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 1rem;
  height: 1rem;
}
.form__radio input[type=radio] + span::after {
  height: 0.625rem;
  width: 0.625rem;
  background: #445F39;
  border-radius: 50%;
  opacity: 0;
  transform: rotate(-45deg);
  transition: all 0.3s ease 0s;
  left: 0.1875rem;
  top: 0.375rem;
}
.form__radio input[type=radio]:checked + span::after {
  opacity: 1;
}

.form__check {
  margin-bottom: -0.625rem;
  display: flex;
  flex-wrap: wrap;
}
.form__check > span {
  margin-right: 3.75rem;
  margin-bottom: 0.625rem;
}
.form__check input[type=checkbox] + span {
  cursor: pointer;
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  display: inline-block;
  padding-left: 1.625rem;
  position: relative;
}
.form__check input[type=checkbox] + span::before, .form__check input[type=checkbox] + span::after {
  position: absolute;
  content: "";
}
.form__check input[type=checkbox] + span::before {
  display: block;
  background: #fff;
  border: 0.0625rem solid #ccc;
  top: 0.3125rem;
  left: 0;
  width: 0.875rem;
  height: 0.875rem;
}
.form__check input[type=checkbox] + span::after {
  top: 0.4375rem;
  left: 0.1875rem;
  width: 0.5625rem;
  height: 0.4375rem;
  border-left: 0.125rem solid #445F39;
  border-bottom: 0.125rem solid #445F39;
  transform: rotate(-45deg);
  opacity: 0;
}
.form__check input[type=checkbox]:checked + span::after {
  opacity: 1;
}

.form__item--day .form__selects {
  display: flex;
  align-items: center;
}
.form__item--day .form__select-wrapper {
  margin-right: 0.4375rem;
}
.form__item--day .form__select-wrapper:last-of-type {
  margin-left: 1.875rem;
}
.form__item--day .form__select-wrapper::after {
  left: 3rem;
}
.form__item--day select {
  width: 4rem;
}

.form__item--date.form__item .form__item-input {
  width: 73%;
}
@media screen and (max-width: 767px) {
  .form__item--date.form__item .form__item-input {
    width: 100%;
  }
}

.form__item--method .form__check {
  flex-wrap: nowrap;
}
@media screen and (max-width: 767px) {
  .form__item--method .form__check {
    flex-wrap: wrap;
  }
}
.form__item--method .form__check > span {
  margin-right: 1.25rem;
}

.form__item--time .form__check > span {
  margin-right: 0;
  min-width: 10.9375rem;
}

.form__item--chance .form__check > span {
  width: 35%;
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  .form__item--chance .form__check > span {
    width: 100%;
  }
}
.form__item--chance .form__check > span:nth-child(3n+1) {
  width: 30%;
}
@media screen and (max-width: 767px) {
  .form__item--chance .form__check > span:nth-child(3n+1) {
    width: 100%;
  }
}
.form__item--chance .form__check > span:last-of-type {
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .form__item--chance .form__check > span:last-of-type {
    margin-top: 0;
  }
}
.form__item--chance .form__check input[type=checkbox] + span {
  padding-left: 1.25rem;
}
.form__item--chance .form__item-input textarea {
  margin-left: auto;
  width: 66%;
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .form__item--chance .form__item-input textarea {
    width: 100%;
    margin-top: 0;
  }
}

.form__item--request.form__item {
  margin-top: 2.5rem;
}
.form__item--request textarea {
  height: 4.75rem;
}

.form__item--birth .form__item-input {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  letter-spacing: 0.08em;
}
.form__item--birth .form__item-input input:first-of-type {
  width: 4rem;
  margin: 0 0.5rem;
}
.form__item--birth .form__item-input input:nth-of-type(2), .form__item--birth .form__item-input input:last-of-type {
  width: 2.125rem;
  margin: 0 0.5rem 0 1.5625rem;
}
@media screen and (max-width: 767px) {
  .form__item--birth .form__item-input input:nth-of-type(2), .form__item--birth .form__item-input input:last-of-type {
    margin: 0 0.5rem 0 0.625rem;
  }
}

.form__item--post input {
  width: 10.375rem;
  max-width: 100%;
}

.form__item--hope.form__item .form__item-label {
  width: 20%;
}
@media screen and (max-width: 767px) {
  .form__item--hope.form__item .form__item-label {
    width: 100%;
  }
}
.form__item--hope.form__item .form__item-input {
  width: 80%;
}
@media screen and (max-width: 767px) {
  .form__item--hope.form__item .form__item-input {
    width: 100%;
  }
}
.form__item--hope .form__item-input-inner {
  display: flex;
}
.form__item--hope .form__item-input li {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 767px) {
  .form__item--hope .form__item-input li {
    display: block;
  }
}
.form__item--hope .form__item-input li:not(:first-child) {
  margin-top: 1.25rem;
}
.form__item--hope .form__item-input span {
  display: inline-block;
  font-size: 0.875rem;
  letter-spacing: 0.08em;
}
.form__item--hope .form__item-input .form__selects {
  display: flex;
  margin-left: 0.5rem;
}
@media screen and (max-width: 767px) {
  .form__item--hope .form__item-input .form__selects {
    align-items: center;
    margin-left: 0;
    margin-top: 0.625rem;
  }
}
.form__item--hope .form__item-input .form__select-wrapper {
  margin-right: 0.5rem;
}
.form__item--hope .form__item-input .form__select-wrapper:last-of-type {
  margin-left: 1.25rem;
}
@media screen and (max-width: 767px) {
  .form__item--hope .form__item-input .form__select-wrapper:last-of-type {
    margin-left: 0.8125rem;
  }
}
.form__item--hope .form__item-input .form__select-wrapper::after {
  left: 3rem;
}
.form__item--hope .form__item-input select {
  width: 4rem;
}
@media screen and (max-width: 767px) {
  .form__item--hope .form__item-input .form__radio {
    display: block;
    margin-top: 0.625rem;
  }
}
.form__item--hope .form__item-input .form__radio > span {
  margin-left: 1.5rem;
  margin-right: 0;
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .form__item--hope .form__item-input .form__radio > span {
    display: block;
    margin-left: 0.8125rem;
  }
}
.form__item--hope .form__item-input .form__radio > span:not(:first-child) {
  margin-left: 1.5rem;
}
@media screen and (max-width: 767px) {
  .form__item--hope .form__item-input .form__radio > span:not(:first-child) {
    margin-left: 0.8125rem;
  }
}
.form__item--hope .form__item-input .form__radio input[type=radio] + span {
  padding-left: 1.375rem;
}
.form__item--hope .form__item-input .form__radio input[type=radio] + span::before {
  border-radius: unset;
  width: 0.875rem;
  height: 0.875rem;
}
.form__item--hope .form__item-input .form__radio input[type=radio] + span::after {
  top: 45%;
  left: 0.3125rem;
  width: 0.5625rem;
  height: 0.4375rem;
  border-left: 0.125rem solid #445F39;
  border-bottom: 0.125rem solid #445F39;
  transform: rotate(-45deg) translateY(-50%);
  background: none;
  border-radius: unset;
}

.form__item--pr textarea {
  height: 7.5625rem;
}

.form__item--resume .form__item-input input[type=file] {
  display: none;
}
.form__item--resume .form__item-input label {
  display: inline-block;
  text-align: center;
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  background: #F5F5F2;
  border: 0.0625rem solid #ccc;
  padding: 0.625rem 3.125rem;
  transition: opacity 0.3s;
}
.form__item--resume .form__item-input label:hover {
  opacity: 0.7;
}
.form__item--resume .form__item-input > span {
  display: inline-block;
  font-size: 0.875rem;
  letter-spacing: 0.08em;
  margin-left: 1.25rem;
}
@media screen and (max-width: 767px) {
  .form__item--resume .form__item-input > span {
    margin-left: 0;
    margin-top: 0.625rem;
  }
}
.form__item--resume .form__item-input .mwform-file-delete {
  display: none;
}

.form__privacy {
  text-align: center;
  margin-top: 1.875rem;
}
.form__privacy span {
  display: inline-block;
  font-size: 0.8125rem;
  letter-spacing: 0.08em;
}
.form__privacy label {
  display: block;
}
.form__privacy a {
  text-decoration: underline;
}

.form__privacy-box + span {
  cursor: pointer;
  display: inline-block;
  padding-left: 2.0625rem;
  position: relative;
}
.form__privacy-box + span::before, .form__privacy-box + span::after {
  position: absolute;
  content: "";
  display: block;
  top: 50%;
}
.form__privacy-box + span::before {
  border: 0.0625rem solid #ccc;
  width: 0.875rem;
  height: 0.875rem;
  left: 1.125rem;
  top: -0.75rem;
}
.form__privacy-box + span::after {
  border-bottom: 0.125rem solid #445F39;
  border-left: 0.125rem solid #445F39;
  height: 0.375rem;
  width: 0.625rem;
  opacity: 0;
  transform: rotate(-45deg);
  transition: all 0.3s ease 0s;
  left: 1.25rem;
  top: -0.5625rem;
}

.form__privacy-box:checked + span::after {
  opacity: 1;
}

.mw_wp_form input[name="__children[privacypolicy][]"] + .error {
  display: inline;
  padding-right: 0.5em;
}

.form__btn {
  margin: 1.25rem auto 0;
}
@media screen and (max-width: 767px) {
  .form__btn {
    margin-top: 2.5rem;
  }
}

/* fv */
.fv {
  text-align: center;
  padding: 3.4375rem 0;
}

.fv--staff {
  padding: 2rem 0;
}
.fv--staff .fv__entitle {
  color: #9FA0A0;
}

.fv--reform {
  padding-bottom: 1.875rem;
}

.fv--privacy .fv__entitle, .fv--exhibition .fv__entitle, .fv--catalog .fv__entitle, .fv--sdgs .fv__entitle {
  letter-spacing: 0.12em;
  color: #9FA0A0;
}
.fv--privacy .fv__title, .fv--exhibition .fv__title, .fv--catalog .fv__title, .fv--sdgs .fv__title {
  margin-top: 0.9375rem;
}
.fv--privacy .fv__text, .fv--exhibition .fv__text, .fv--catalog .fv__text, .fv--sdgs .fv__text {
  font-size: 1rem;
  margin-top: 2.5rem;
}

@media screen and (max-width: 767px) {
  .fv__inner {
    padding: 0 0.9375rem;
  }
}

.fv__entitle {
  color: #445F39;
  letter-spacing: 0.08em;
}

.fv__title {
  font-size: 1.5rem;
  letter-spacing: 0.08em;
  margin-top: 0.3125rem;
}

.fv__text {
  font-size: 1.125rem;
  letter-spacing: 0.08em;
  margin-top: 1.875rem;
}
@media screen and (max-width: 767px) {
  .fv__text {
    font-size: 1rem;
  }
}

.fv__subtext {
  font-size: 0.9375rem;
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: 2.1875rem;
}

/* gnav */
.gnav__items {
  display: flex;
}

.gnav__item {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.2em;
  writing-mode: vertical-rl;
}
.gnav__item a {
  display: inline-block;
  padding: 0 0.3125rem;
  position: relative;
}
.gnav__item a::after {
  position: absolute;
  right: 0;
  content: "";
  width: 0.0625rem;
  height: 100%;
  background: #000000;
  top: 0;
  transform: scale(1, 0);
  transform-origin: left top;
  transition: all 300ms cubic-bezier(1, 0, 0, 1);
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
}
.gnav__item a:hover {
  opacity: 1;
}
.gnav__item a:hover::after {
  transform: scale(1, 1);
}

/* hamburger */
.hamburger {
  display: block;
  position: absolute;
  z-index: 30;
  right: 1.1875rem;
  top: 1.1875rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.hamburger__border {
  display: block;
  position: absolute;
  width: 0.0625rem;
  background: #000;
  transition: 0.3s ease-in-out;
  top: 0;
}
.hamburger__border:nth-child(1) {
  height: 0.875rem;
  left: 0.375rem;
}
.hamburger__border:nth-child(2) {
  height: 1.25rem;
  left: 0.75rem;
}
.hamburger__border:nth-child(3) {
  height: 1.25rem;
  left: 1.125rem;
}

/* ナビ開いてる時のボタン */
.hamburger--active .hamburger__border {
  left: 0.75rem;
}
.hamburger--active .hamburger__border:nth-child(1) {
  top: 0;
  height: 1.25rem;
  transform: rotate(-45deg);
}
.hamburger--active .hamburger__border:nth-child(2), .hamburger--active .hamburger__border:nth-child(3) {
  transform: rotate(45deg);
}

/* header */
.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 15rem;
  max-width: 100%;
  height: 100%;
  padding: 2.3125rem 1.0625rem;
  z-index: 100;
}
@media screen and (max-width: 767px) {
  .header {
    background: #fff;
    width: 100%;
    height: 3.75rem;
    padding: 0;
  }
}

.header__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: inherit;
}
@media screen and (max-width: 767px) {
  .header__inner {
    flex-direction: row;
    justify-content: center;
  }
}

.header__logo {
  width: 10.0625rem;
  max-width: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .header__logo {
    width: 6.6875rem;
  }
}
.header__logo a {
  display: block;
}

.header__bottom {
  width: calc(100% - 0.625rem);
}

.header__nav {
  margin-top: 1.5625rem;
}

.header__menu-item {
  text-align: center;
}
.header__menu-item + .header__menu-item {
  margin-top: 0.625rem;
}
.header__menu-item a {
  display: inline-block;
  background: #5A3234;
  width: 100%;
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  color: #fff;
  border: 0.0625rem solid transparent;
  text-align: center;
  padding: 0.6875rem 0.625rem;
  position: relative;
  transition: background 0.3s, color 0.3s, border 0.3s;
}
.header__menu-item a::after {
  position: absolute;
  content: "";
  width: 0.375rem;
  height: 0.375rem;
  top: 50%;
  transform: translateY(-50%);
  right: 0.9375rem;
  background: url(../img/arrow-icon01.png) no-repeat center/contain;
  transition: background 0.3s;
}
.header__menu-item a:hover {
  opacity: 1;
  border-color: #5A3234;
  color: #5A3234;
  background: #fff;
}
.header__menu-item a:hover::after {
  background: url(../img/arrow-icon08.png) no-repeat center/contain;
}

.header__icon {
  display: block;
  width: 2rem;
  margin: 1.25rem auto 0;
}

/* info */
.info__item {
  display: flex;
  align-items: flex-start;
}
.info__item + .info__item {
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .info__item + .info__item {
    margin-top: 0.9375rem;
  }
}

.info__item-title {
  font-size: 0.75rem;
  letter-spacing: 0.12em;
  background: #F5F5F2;
  padding: 0.1875rem 1.125rem 0.25rem;
  white-space: nowrap;
}

.info__item-text {
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  margin-left: 0.4375rem;
}

/* link-a */
.link-a {
  display: block;
  text-align: center;
  width: 18.75rem;
  max-width: 100%;
  padding: 0.8125rem 0;
  position: relative;
  border: 0.0625rem solid #000;
  color: #000;
  transition: color 0.3s, background 0.3s;
}
@media screen and (max-width: 767px) {
  .link-a {
    margin: 0 auto;
  }
}
.link-a::after {
  position: absolute;
  content: "";
  width: 0.5625rem;
  height: 0.5625rem;
  right: 1.4375rem;
  top: 50%;
  transform: translateY(-50%);
  background: url(../img/arrow-icon02.png) no-repeat center/contain;
  transition: background 0.3s;
}
.link-a:hover {
  background: #000;
  opacity: 1;
  color: #fff;
}
.link-a:hover::after {
  background-image: url(../img/arrow-icon01.png);
}

/* link-b */
.link-b {
  display: block;
  text-align: center;
  width: 18.75rem;
  max-width: 100%;
  padding: 0.8125rem 0;
  position: relative;
  border: 0.0625rem solid #445F39;
  color: #445F39;
  transition: color 0.3s, background 0.3s;
}
.link-b::after {
  position: absolute;
  content: "";
  width: 0.5625rem;
  height: 0.5625rem;
  right: 1.4375rem;
  top: 50%;
  transform: translateY(-50%);
  background: url(../img/arrow-icon06.png) no-repeat center/contain;
  transition: background 0.3s;
}
.link-b:hover {
  opacity: 1;
  background: #445F39;
  color: #fff;
}
.link-b:hover::after {
  background-image: url(../img/arrow-icon01.png);
}

/* links */
.links {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .links {
    display: block;
  }
}
.links a {
  display: block;
  text-align: center;
  width: 18.75rem;
  max-width: 100%;
  border: 0.0625rem solid #445F39;
  color: #445F39;
  padding: 0.8125rem 0;
  position: relative;
  transition: color 0.3s, background 0.3s;
}
@media screen and (max-width: 767px) {
  .links a {
    margin: 0 auto;
  }
}
.links a::after {
  position: absolute;
  content: "";
  width: 0.5625rem;
  height: 0.5625rem;
  right: 1.4375rem;
  top: 50%;
  transform: translateY(-50%);
  background: url(../img/arrow-icon06.png) no-repeat center/contain;
  transition: background 0.3s;
}
.links a:hover {
  opacity: 1;
  background: #445F39;
  color: #fff;
}
.links a:hover::after {
  background-image: url(../img/arrow-icon01.png);
}

.links__prev, .links__next {
  margin: 0 0.75rem;
}
@media screen and (max-width: 767px) {
  .links__prev, .links__next {
    margin: 0.75rem 0;
  }
}
.links__prev a:hover, .links__next a:hover {
  opacity: 1;
  background: #445F39;
  color: #fff;
}

.links__prev a::after {
  left: 1.4375rem;
  background: url(../img/arrow-icon05.png) no-repeat center/contain;
}
.links__prev a:hover::after {
  background-image: url(../img/arrow-icon07.png);
}

.links__next a::after {
  right: 1.4375rem;
  background: url(../img/arrow-icon06.png) no-repeat center/contain;
}
.links__next a:hover::after {
  background-image: url(../img/arrow-icon01.png);
}

/* list */
.list {
  border-top: 0.0625rem solid #ccc;
}

.list__item {
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
  border-bottom: 0.0625rem solid #ccc;
  padding: 1.4375rem 0;
}
@media screen and (max-width: 767px) {
  .list__item {
    display: block;
    padding: 0.9375rem 0;
  }
}

.list__item-title {
  width: 37%;
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  .list__item-title {
    width: 100%;
  }
}

.list__item-text {
  width: 63%;
}
@media screen and (max-width: 767px) {
  .list__item-text {
    width: 100%;
    font-size: 0.875rem;
    margin-top: 0.1875rem;
  }
}

/* main */
.main {
  flex: 1;
}

/* menu */
.menu__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: -0.625rem;
}

.menu__item {
  text-align: center;
  font-size: 0.9375rem;
  width: calc(33.33333333% - 0.625rem * 2 / 3);
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .menu__item {
    width: calc(50% - 0.625rem * 1 / 2);
  }
}
.menu__item:not(:nth-child(3n+1)) {
  margin-left: 0.625rem;
}
@media screen and (max-width: 767px) {
  .menu__item:not(:nth-child(3n+1)) {
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .menu__item:not(:nth-child(2n+1)) {
    margin-left: 0.625rem;
  }
}
.menu__item span, .menu__item a {
  display: block;
  border: 0.0625rem solid #3F4040;
  padding: 0.875rem 0.3125rem;
}
@media screen and (max-width: 767px) {
  .menu__item span, .menu__item a {
    font-size: 0.875rem;
  }
}
.menu__item.current {
  background: #fff;
}

/* modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  background: rgba(0, 0, 0, 0.78);
  z-index: 200;
  transition: opacity 0.6s;
  overflow-y: auto;
  padding-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .modal {
    overflow-y: unset;
    padding-top: 0;
  }
}
.modal.active {
  opacity: 1;
  pointer-events: auto;
}
.modal .swiper-button-prev, .modal .swiper-button-next {
  position: absolute;
  top: 47%;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .modal .swiper-button-prev, .modal .swiper-button-next {
    top: 55%;
  }
}
.modal .swiper-button-prev::after, .modal .swiper-button-next::after {
  position: absolute;
  content: "";
  top: 0;
}
@media screen and (max-width: 767px) {
  .modal .swiper-button-prev::after, .modal .swiper-button-next::after {
    width: 1.25rem;
    height: 1.25rem;
  }
}
.modal .swiper-button-prev {
  left: -4.375rem;
}
@media screen and (max-width: 767px) {
  .modal .swiper-button-prev {
    left: -1.875rem;
  }
}
.modal .swiper-button-prev::after {
  border-top: 2.5625rem solid transparent;
  border-right: 2.25rem solid #fff;
  border-bottom: 2.5625rem solid transparent;
}
@media screen and (max-width: 767px) {
  .modal .swiper-button-prev::after {
    border-top: 1.25rem solid transparent;
    border-right: 1.25rem solid #fff;
    border-bottom: 1.25rem solid transparent;
  }
}
.modal .swiper-button-next {
  right: -4.375rem;
}
@media screen and (max-width: 767px) {
  .modal .swiper-button-next {
    right: -1.875rem;
  }
}
.modal .swiper-button-next::after {
  border-top: 2.5625rem solid transparent;
  border-left: 2.25rem solid #fff;
  border-bottom: 2.5625rem solid transparent;
}
@media screen and (max-width: 767px) {
  .modal .swiper-button-next::after {
    border-top: 1.25rem solid transparent;
    border-left: 1.25rem solid #fff;
    border-bottom: 1.25rem solid transparent;
  }
}

.modal__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.modal__content {
  position: relative;
  max-width: 80%;
}
@media screen and (max-width: 767px) {
  .modal__content {
    max-width: 85%;
  }
}

.modal__inner {
  padding: 9.375rem 0 6.25rem;
}

.modal__close {
  display: inline-block;
  position: absolute;
  right: 2.1875rem;
  top: 8.4375rem;
  z-index: 10;
}
@media screen and (max-width: 767px) {
  .modal__close {
    top: 7.5rem;
    right: 0.625rem;
  }
}
.modal__close::before, .modal__close::after {
  position: absolute;
  content: "";
  height: 0.9375rem;
  width: 4.375rem;
  background: #fff;
  left: 0;
  top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .modal__close::before, .modal__close::after {
    width: 0.5rem;
    height: 2.5rem;
  }
}
.modal__close::before {
  transform: rotate(45deg);
}
.modal__close::after {
  transform: rotate(-45deg);
}
.modal__close:hover {
  opacity: 1;
}

.modal__slider-wrapper {
  overflow: hidden;
}

.modal__slider-item {
  padding-bottom: 1.25rem;
}

.modal__slider-item-img {
  width: 100%;
  padding-top: 56.1571125265%;
  position: relative;
}
.modal__slider-item-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal__slider-item-text {
  position: relative;
  bottom: -0.625rem;
  text-align: center;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .modal__slider-item-text {
    font-size: 0.875rem;
  }
}

/* news */
.news__inner {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .news__inner {
    display: block;
  }
}

.news__content {
  width: 84.5%;
}
@media screen and (max-width: 767px) {
  .news__content {
    width: 100%;
    margin-top: 1.875rem;
  }
}

.news__link-wrapper {
  text-align: right;
  margin-right: 1.25rem;
}
@media screen and (max-width: 767px) {
  .news__link-wrapper {
    margin-right: 0;
  }
}

.news__link {
  display: inline-block;
  font-size: 0.9375rem;
  font-weight: 500;
  border-bottom: 0.0625rem solid #445F39;
  background: url(../img/arrow-icon02.png) no-repeat center right/0.5625rem;
  padding: 0.625rem 3.125rem 0.625rem 0.3125rem;
  margin-top: 1.25rem;
}

.news__items {
  border-top: 0.0625rem dashed #ccc;
}

.news__item {
  border-bottom: 0.0625rem dashed #ccc;
}

.news__item-inner {
  display: flex;
  align-items: center;
  padding: 1.25rem 1.5625rem 1.25rem 0;
  transition: background 0.3s;
  position: relative;
}
@media screen and (max-width: 767px) {
  .news__item-inner {
    display: block;
    padding: 1.1875rem 1.25rem 1.1875rem 0;
    background-position: center right;
  }
}
.news__item-inner::after {
  position: absolute;
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
  right: 1.25rem;
  background: url(../img/arrow-icon02.png) no-repeat center/contain;
}
.news__item-inner:hover {
  opacity: 1;
  background: #F7F7F7;
}

.news__item-header {
  display: flex;
  align-items: center;
}

.news__item-date {
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.06em;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .news__item-date {
    font-size: 0.875rem;
  }
}

.news__item-category {
  margin-left: 1.4375rem;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .news__item-category {
    margin-left: 0.9375rem;
  }
}
.news__item-category span {
  display: inline-block;
  min-width: 7.5rem;
  font-size: 0.875rem;
  padding: 0.3125rem 0.3125rem;
  color: #7E8080;
  background: #F5F5F5;
}
@media screen and (max-width: 767px) {
  .news__item-category span {
    min-width: 5rem;
    font-size: 0.75rem;
    padding: 0.125rem 0.3125rem;
  }
}

.news__item-title {
  letter-spacing: 0.04em;
  margin-left: 1.875rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .news__item-title {
    font-size: 0.9375rem;
    margin-top: 0.625rem;
    margin-left: 0;
    text-overflow: unset;
    white-space: unset;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

/* page-top */
.page-top {
  padding: 5.625rem 0;
}
@media screen and (max-width: 767px) {
  .page-top {
    padding: 0.9375rem 0 1.5625rem;
    margin-top: 3.75rem;
  }
}

.page-top--top .page-top__img {
  width: 43.75rem;
}
@media screen and (max-width: 767px) {
  .page-top--top .page-top__img {
    width: 20.625rem;
  }
}

.page-top--concept .page-top__img {
  width: 31.25rem;
}
@media screen and (max-width: 767px) {
  .page-top--concept .page-top__img {
    width: 20.625rem;
  }
}

@media screen and (max-width: 767px) {
  .page-top__inner {
    padding: 0 0.9375rem;
  }
}

.page-top__img {
  width: 56.25rem;
  max-width: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .page-top__img {
    width: 20.625rem;
  }
}

/* pagetop */
.pagetop {
  display: none;
  position: fixed;
  width: 4rem;
  right: 1.25rem;
  bottom: 1.4375rem;
  z-index: 50;
}
@media screen and (max-width: 767px) {
  .pagetop {
    width: 2rem;
    right: 0.625rem;
    bottom: 2.0625rem;
  }
}

/* pagination */
.pagination .wp-pagenavi {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.pagination .wp-pagenavi a,
.pagination .wp-pagenavi span {
  border: none;
  width: 2.1875rem;
  height: 2.1875rem;
  margin: 0.3125rem 0.75rem 0;
}
.pagination .wp-pagenavi .page,
.pagination .wp-pagenavi .current {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.4375rem;
  letter-spacing: 0.08em;
}
.pagination .wp-pagenavi .current {
  background: #F5F5F2;
}
.pagination .wp-pagenavi .previouspostslink,
.pagination .wp-pagenavi .nextpostslink {
  position: relative;
}
.pagination .wp-pagenavi .previouspostslink::after,
.pagination .wp-pagenavi .nextpostslink::after {
  position: absolute;
  content: "";
  transform: rotate(45deg);
  width: 0.75rem;
  height: 0.75rem;
  top: 0.75rem;
}
.pagination .wp-pagenavi .previouspostslink::after {
  border-bottom: 0.1875rem solid #445F39;
  border-left: 0.1875rem solid #445F39;
  left: 0.625rem;
}
.pagination .wp-pagenavi .nextpostslink::after {
  border-top: 0.1875rem solid #445F39;
  border-right: 0.1875rem solid #445F39;
  left: 0.375rem;
}

/* popup */
.popup {
  display: none;
  z-index: 100;
}
.popup.is-open {
  display: block;
}

.popup__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  z-index: 100;
  overflow: auto;
}

.popup__container {
  width: 46rem;
  max-width: 90%;
  padding: 6.25rem 0;
  overflow: auto;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
@media screen and (max-width: 767px) {
  .popup__container {
    padding: 3.75rem 0;
  }
}

.popup__content {
  background: #fff;
  padding: 2.5rem 2.5rem;
}
@media screen and (max-width: 767px) {
  .popup__content {
    padding: 1.875rem 1.25rem;
  }
}

.popup__close {
  position: absolute;
  right: 1.25rem;
  top: 3.75rem;
  width: 1.875rem;
  height: 1.875rem;
}
@media screen and (max-width: 767px) {
  .popup__close {
    top: 1.25rem;
  }
}
.popup__close::before, .popup__close::after {
  position: absolute;
  content: "";
  background: #fff;
  width: 1.75rem;
  height: 0.3125rem;
}
.popup__close::before {
  transform: rotate(45deg);
}
.popup__close::after {
  transform: rotate(-45deg);
}

.popup__header {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .popup__header {
    display: block;
  }
}

.popup__img {
  width: 38.5%;
}
@media screen and (max-width: 767px) {
  .popup__img {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }
}

.popup__body {
  width: 57%;
  margin-top: -0.1875rem;
}
@media screen and (max-width: 767px) {
  .popup__body {
    width: 100%;
    margin-top: 0.9375rem;
  }
}

.popup__position {
  font-size: 0.8125rem;
  letter-spacing: 0.08em;
}

.popup__name {
  font-size: 1.25rem;
  letter-spacing: 0.08em;
  margin-top: 0.1875rem;
}

.popup__subtitle {
  font-size: 0.875rem;
  font-weight: 700;
  color: #445F39;
  margin-top: 0.625rem;
}

.popup__subtext {
  font-size: 0.8125rem;
  line-height: 1.92;
  letter-spacing: 0.04em;
  margin-top: 0.375rem;
}

.popup__text {
  font-size: 0.8125rem;
  letter-spacing: 0.04em;
  line-height: 1.9;
  margin-top: 1.25rem;
}
.popup__text span {
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  color: #445F39;
  margin-bottom: -0.625rem;
}

/* post-content */
.post-content h2, .post-content h3, .post-content h4, .post-content h5, .post-content h6 {
  font-weight: 700;
  letter-spacing: 0.12em;
  line-height: 1.3;
  margin: 0;
  margin-bottom: 1.5625rem;
}
.post-content h2 {
  font-size: 1.5rem;
}
@media screen and (max-width: 767px) {
  .post-content h2 {
    font-size: 1.375rem;
  }
}
.post-content h3 {
  font-size: 1.25rem;
}
@media screen and (max-width: 767px) {
  .post-content h3 {
    font-size: 1.125rem;
  }
}
.post-content h4, .post-content h5, .post-content h6 {
  font-size: 1.125rem;
}
@media screen and (max-width: 767px) {
  .post-content h4, .post-content h5, .post-content h6 {
    font-size: 1rem;
  }
}
.post-content p {
  font-size: 0.9375rem;
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-bottom: 2.5rem;
}
.post-content figure {
  margin-bottom: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .post-content figure {
    max-width: 25rem;
    margin: 0 auto 2.5rem;
  }
}
.post-content a {
  display: inline-block;
  text-decoration: underline;
}
.post-content ul {
  margin-bottom: 2.5rem;
}
.post-content ul li {
  line-height: 1.8;
  padding-left: 1em;
  position: relative;
}
.post-content ul li::before {
  position: absolute;
  content: "・";
  left: 0;
  top: 0;
}

/* schedule */
.schedule {
  display: flex;
  background: #fff;
}
@media screen and (max-width: 767px) {
  .schedule {
    display: block;
  }
}

.schedule__calendar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  padding: 1.375rem 3.75rem 1.875rem;
}
@media screen and (max-width: 767px) {
  .schedule__calendar {
    padding: 2rem 0.625rem 2.1875rem;
    max-width: 25rem;
    width: 100%;
    margin: 0 auto;
  }
}

.schedule__body {
  overflow-y: auto;
  width: 50%;
  border: 0.0625rem solid #707070;
  max-height: 28.125rem;
  padding: 0.9375rem 1.125rem;
}
@media screen and (max-width: 767px) {
  .schedule__body {
    width: 100%;
    max-height: 18.75rem;
    padding: 0.875rem 0.8125rem 2.5rem;
  }
}

.schedule__title {
  text-align: center;
  font-size: 1.25rem;
  color: #707070;
}
@media screen and (max-width: 767px) {
  .schedule__title {
    font-size: 0.75rem;
  }
}

.schedule__cards {
  margin-top: 1.5rem;
}
@media screen and (max-width: 767px) {
  .schedule__cards {
    max-width: 25rem;
    margin: 1rem auto 0;
  }
}

.schedule__card {
  border: 0.0625rem solid #ccc;
}
.schedule__card + .schedule__card {
  margin-top: 1.25rem;
}

.schedule__card-inner {
  display: flex;
  flex-direction: row-reverse;
}
.schedule__card-inner:hover {
  opacity: 1;
}
.schedule__card-inner:hover img {
  transform: scale(1.2);
}

.schedule__card-body {
  width: 69%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0.625rem 0.625rem;
}
@media screen and (max-width: 767px) {
  .schedule__card-body {
    width: 62%;
    padding: 0.4375rem 0.625rem;
  }
}

.schedule__card-title {
  font-size: 0.75rem;
  font-weight: 700;
  letter-spacing: 0.08em;
  color: #445F39;
}
@media screen and (max-width: 767px) {
  .schedule__card-title {
    font-size: 0.625rem;
  }
}

.schedule__card-text {
  font-size: 0.75rem;
  letter-spacing: 0.08em;
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .schedule__card-text {
    font-size: 0.625rem;
    margin-top: 0;
  }
}

.schedule__card-time {
  font-size: 0.875rem;
  letter-spacing: 0.08em;
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .schedule__card-time {
    font-size: 0.6875rem;
    margin-top: 0.1875rem;
  }
}

.schedule__card-img-wrapper {
  width: 31%;
}
@media screen and (max-width: 767px) {
  .schedule__card-img-wrapper {
    width: 38%;
  }
}

.schedule__card-img {
  width: 100%;
  height: 100%;
  padding-top: 71.5231788079%;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 767px) {
  .schedule__card-img {
    padding-top: unset;
  }
}
.schedule__card-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s;
}

/* section-entitle */
.section-entitle {
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.12em;
  color: #9FA0A0;
}

section .section-entitle {
  margin-bottom: 0.4375rem;
}

/* section-heading */
.section-heading {
  text-align: center;
  color: #445F39;
  font-weight: 700;
  font-size: 1.375rem;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .section-heading {
    font-size: 1.25rem;
  }
}

/* section-subheading */
.section-subheading {
  text-align: center;
  font-size: 1.25rem;
  letter-spacing: 0.08em;
  font-weight: 700;
}

/* section-subtitle */
.section-subtitle {
  text-align: center;
  color: #445F39;
  font-size: 1.25rem;
  letter-spacing: 0.08em;
}

/* section-title */
.section-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.08em;
}

/* sidebar */
.sidebar__block + .sidebar__block {
  margin-top: 4.3125rem;
}

.sidebar__subtitle {
  letter-spacing: 0.08em;
}

.sidebar__items {
  border-top: 0.0625rem solid #707070;
  padding-top: 0.9375rem;
  margin-top: 0.625rem;
}

.sidebar__item + .sidebar__item {
  margin-top: 1.5625rem;
}

.sidebar__item-inner {
  display: block;
}
.sidebar__item-inner:hover {
  opacity: 1;
}
.sidebar__item-inner:hover img {
  transform: scale(1.2);
}

.sidebar__item-img {
  width: 100%;
  padding-top: 47.3451327434%;
  overflow: hidden;
  position: relative;
}
.sidebar__item-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s;
}

.sidebar__item-title {
  font-size: 0.875rem;
  letter-spacing: 0.04em;
  margin-top: 0.625rem;
}

.sidebar__link {
  margin-top: 2.5rem;
}

/* site-wrapper */
.site-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* slider */
.slider {
  width: 100%;
  position: relative;
  margin-top: 1.875rem;
}
@media screen and (max-width: 767px) {
  .slider {
    margin-top: 1.5625rem;
  }
}
.slider .swiper-button-next, .slider .swiper-button-prev {
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 57%;
  transform: translateY(-50%);
  z-index: 10;
}
@media screen and (max-width: 767px) {
  .slider .swiper-button-next, .slider .swiper-button-prev {
    width: 1.25rem;
    height: 1.25rem;
    top: 70%;
  }
}
.slider .swiper-button-next::before, .slider .swiper-button-prev::before {
  position: absolute;
  content: "";
  width: 2rem;
  height: 2rem;
}
@media screen and (max-width: 1350px) {
  .slider .swiper-button-next::before, .slider .swiper-button-prev::before {
    width: 1.75rem;
    height: 1.75rem;
  }
}
@media screen and (max-width: 767px) {
  .slider .swiper-button-next::before, .slider .swiper-button-prev::before {
    width: 1rem;
    height: 1rem;
  }
}
.slider .swiper-button-next::after, .slider .swiper-button-prev::after {
  display: none;
}
.slider .swiper-button-prev {
  left: -4.375rem;
}
@media screen and (max-width: 1350px) {
  .slider .swiper-button-prev {
    left: -3.125rem;
  }
}
@media screen and (max-width: 767px) {
  .slider .swiper-button-prev {
    left: -1.5625rem;
  }
}
.slider .swiper-button-prev::before {
  background: url(../img/arrow-icon03.png) no-repeat center/contain;
}
.slider .swiper-button-next {
  right: -4.375rem;
}
@media screen and (max-width: 1350px) {
  .slider .swiper-button-next {
    right: -3.125rem;
  }
}
@media screen and (max-width: 767px) {
  .slider .swiper-button-next {
    right: -1.5625rem;
  }
}
.slider .swiper-button-next::before {
  background: url(../img/arrow-icon04.png) no-repeat center/contain;
}

.slider__item {
  width: 100%;
  padding-top: 36.3636363636%;
  position: relative;
}
.slider__item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider__item a {
  display: block;
}
.slider__item a:hover {
  opacity: 1;
}

/* voice-card */
.voice-card {
  border: 0.0625rem solid #ccc;
}

.voice-card__inner {
  display: flex;
  flex-direction: row-reverse;
}
@media screen and (max-width: 767px) {
  .voice-card__inner {
    display: block;
  }
}
.voice-card__inner:hover {
  opacity: 1;
}
.voice-card__inner:hover img {
  transform: scale(1.2);
}

.voice-card__img-wrapper {
  width: 35%;
}
@media screen and (max-width: 767px) {
  .voice-card__img-wrapper {
    width: 100%;
    height: 9.375rem;
  }
}

.voice-card__img {
  width: 100%;
  height: 100%;
  padding-top: 50.8143322476%;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 767px) {
  .voice-card__img {
    padding-top: unset;
  }
}
.voice-card__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s;
}

.voice-card__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 65%;
  padding: 1.25rem 6.5625rem 1.25rem 4.375rem;
}
@media screen and (max-width: 767px) {
  .voice-card__body {
    width: 100%;
    padding: 1.25rem 1.25rem;
  }
}

.voice-card__text {
  font-size: 0.875rem;
  line-height: 1.7;
  letter-spacing: 0.08em;
}

.voice-card__subtext {
  font-size: 0.875rem;
  line-height: 1.7;
  letter-spacing: 0.08em;
  color: #445F39;
  font-weight: 700;
}

/* about */
.about__greeting {
  margin-top: 5.625rem;
}
@media screen and (max-width: 767px) {
  .about__greeting {
    margin-top: 3.75rem;
  }
}

.about__box-wrapper {
  margin-top: 5.9375rem;
}
@media screen and (max-width: 767px) {
  .about__box-wrapper {
    margin-top: 3.75rem;
  }
}

.about__box {
  background: #fff;
  padding: 2.5rem 5rem 4.375rem;
}
@media screen and (max-width: 767px) {
  .about__box {
    padding: 1.5625rem 1.25rem 1.875rem;
  }
}

.about__list {
  border-top: none;
}

.about__links {
  display: flex;
  justify-content: center;
  margin-top: 3.75rem;
}
@media screen and (max-width: 767px) {
  .about__links {
    display: block;
    margin-top: 2.5rem;
  }
}
@media screen and (max-width: 767px) {
  .about__links .link-b {
    margin: 0 auto;
  }
}
.about__links .link-b:last-child {
  margin-left: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .about__links .link-b:last-child {
    margin: 1.25rem auto 0;
  }
}

.about__access {
  margin-top: 5.9375rem;
}
@media screen and (max-width: 767px) {
  .about__access {
    margin-top: 3.75rem;
  }
}

/* access */
.access__map-wrapper {
  margin-top: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .access__map-wrapper {
    margin-top: 1.5625rem;
  }
}

.access__map {
  width: 100%;
  padding-top: 42.2115384615%;
  position: relative;
}
.access__map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* attempt */
.attempt {
  text-align: center;
}

.attempt__logo {
  width: 6.25rem;
  max-width: 100%;
  margin: 0.9375rem auto 0;
}

.attempt__subtitle {
  font-size: 1.25rem;
  margin-top: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .attempt__subtitle {
    font-size: 1.125rem;
  }
}
.attempt__subtitle span {
  font-size: 1.0625rem;
}
@media screen and (max-width: 767px) {
  .attempt__subtitle span {
    font-size: 1rem;
  }
}

.attempt__text {
  font-size: 0.9375rem;
  line-height: 1.8;
  letter-spacing: 0.04em;
  margin-top: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .attempt__text {
    font-size: 0.875rem;
  }
}

.attempt__link {
  margin: 3.125rem auto 0;
}

/* greeting */
.greeting__items {
  margin-top: 2rem;
}
@media screen and (max-width: 767px) {
  .greeting__items {
    margin-top: 1.5625rem;
  }
}

.greeting__item + .greeting__item {
  margin-top: 4.3125rem;
}
@media screen and (max-width: 767px) {
  .greeting__item + .greeting__item {
    margin-top: 3.125rem;
  }
}

.greeting__item-title {
  text-align: center;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .greeting__item-title {
    font-size: 1rem;
  }
}

.greeting__item-text {
  font-size: 0.9375rem;
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .greeting__item-text {
    font-size: 0.875rem;
  }
}

.greeting__name {
  text-align: right;
  letter-spacing: 0.08em;
  margin-top: 4.375rem;
}
@media screen and (max-width: 767px) {
  .greeting__name {
    font-size: 0.9375rem;
    margin-top: 3.125rem;
  }
}

/* member */
.member__items {
  width: 55rem;
  max-width: 100%;
  margin: 2.1875rem auto 0;
}

.member__item {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .member__item {
    display: block;
  }
}
.member__item + .member__item {
  margin-top: 3.125rem;
}

.member__item-img {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .member__item-img {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }
}

.member__item-body {
  width: 45%;
  margin-top: -0.1875rem;
}
@media screen and (max-width: 767px) {
  .member__item-body {
    width: 100%;
    margin-top: 0.9375rem;
  }
}

.member__item-position {
  font-size: 0.875rem;
  letter-spacing: 0.08em;
  color: #445F39;
}

.member__item-names {
  display: flex;
  align-items: center;
  margin-top: 0.625rem;
}

.member__item-name {
  font-size: 1.25rem;
  letter-spacing: 0.04em;
}
@media screen and (max-width: 767px) {
  .member__item-name {
    font-size: 1.125rem;
  }
}

.member__item-enname {
  font-size: 0.8125rem;
  letter-spacing: 0.04em;
  margin-left: 1.25rem;
}

.member__item-text {
  font-size: 0.9375rem;
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: 0.75rem;
}
@media screen and (max-width: 767px) {
  .member__item-text {
    font-size: 0.875rem;
  }
}

/* akiya-other */
.akiya-other {
  margin-top: 4.375rem;
  padding-bottom: 3.75rem;
}

.akiya-other__title {
  text-align: center;
  font-size: 1.875rem;
  letter-spacing: 0.08em;
  line-height: 1.7;
  font-weight: 700;
}
@media screen and (max-width: 767px) {
  .akiya-other__title {
    font-size: 1.25rem;
  }
}

.akiya-other__subtitle {
  text-align: center;
  letter-spacing: 0.08em;
  line-height: 1.7;
  font-size: 1.5rem;
  margin-top: 4.0625rem;
}
@media screen and (max-width: 767px) {
  .akiya-other__subtitle {
    text-align: left;
    font-size: 1.125rem;
    margin-top: 2.5rem;
  }
}

.akiya-other__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .akiya-other__items {
    display: block;
  }
}

.akiya-other__item {
  width: calc(33.33333333% - 4.0625rem * 2 / 3);
  margin-top: 2.625rem;
}
@media screen and (max-width: 767px) {
  .akiya-other__item {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }
  .akiya-other__item + .akiya-other__item {
    margin-top: 1.875rem;
  }
}
.akiya-other__item:not(:nth-child(3n+1)) {
  margin-left: 4.0625rem;
}
@media screen and (max-width: 767px) {
  .akiya-other__item:not(:nth-child(3n+1)) {
    margin-left: auto;
  }
}

.akiya-other__item-text {
  letter-spacing: 0.08em;
  line-height: 1.68;
  margin-top: 0.125rem;
}
@media screen and (max-width: 767px) {
  .akiya-other__item-text {
    font-size: 0.9375rem;
  }
}

.akiya-other__text {
  text-align: center;
  font-size: 1.5rem;
  letter-spacing: 0.08em;
  line-height: 1.7;
  margin-top: 3.125rem;
}
@media screen and (max-width: 767px) {
  .akiya-other__text {
    font-size: 1.125rem;
    text-align: left;
    margin-top: 2.5rem;
  }
}

.akiya-other__box {
  display: flex;
  align-items: center;
  border: 0.0625rem solid #707070;
  border-radius: 0.75rem;
  width: 37.875rem;
  max-width: 100%;
  margin: 2rem auto 0;
  padding: 1.375rem 0.625rem 1.3125rem 2.5rem;
}
@media screen and (max-width: 767px) {
  .akiya-other__box {
    display: block;
    padding: 1.5625rem 1.25rem;
  }
}

.akiya-other__box-icon {
  width: 6.9375rem;
}
@media screen and (max-width: 767px) {
  .akiya-other__box-icon {
    margin: 0 auto;
  }
}

.akiya-other__box-body {
  margin-left: 0.8125rem;
}
@media screen and (max-width: 767px) {
  .akiya-other__box-body {
    margin-left: 0;
    margin-top: 1.25rem;
  }
}

.akiya-other__box-text {
  font-size: 1.125rem;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .akiya-other__box-text {
    font-size: 0.9375rem;
  }
}

.akiya-other__box-tel {
  display: inline-block;
  font-size: 2.5625rem;
  letter-spacing: 0.08em;
  margin-top: 0.3125rem;
  margin-left: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .akiya-other__box-tel {
    font-size: 1.5rem;
    margin-top: 0.625rem;
  }
}
.akiya-other__box-tel span {
  padding-left: 5.0625rem;
  background: url(../img/tel-icon.png) no-repeat center left/4.5rem;
}
@media screen and (max-width: 767px) {
  .akiya-other__box-tel span {
    padding-left: 3.125rem;
    background-size: 2.5rem;
  }
}

/* akiya */
.akiya__subtitle {
  text-align: center;
  font-size: 1.375rem;
  letter-spacing: 0.08em;
  line-height: 1.68;
  margin-top: -0.375rem;
}
@media screen and (max-width: 767px) {
  .akiya__subtitle {
    font-size: 1.125rem;
    margin-top: 0;
  }
}

.akiya__text {
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.71;
  margin-top: 3.125rem;
}
@media screen and (max-width: 767px) {
  .akiya__text {
    text-align: left;
    margin-top: 2.5rem;
  }
}

/* consultation */
.consultation {
  background: #fff;
  border: 0.0625rem solid #ccc;
  padding: 4.0625rem 7.5rem 4.0625rem;
}
@media screen and (max-width: 767px) {
  .consultation {
    padding: 2.5rem 1.25rem;
  }
}

.consultation__form {
  margin-top: 1.875rem;
}

/* excluded */
.excluded {
  border: 0.0625rem solid #707070;
  width: 60.875rem;
  max-width: 100%;
  margin: 4.0625rem auto 0;
  padding: 1.25rem 2.8125rem 3.125rem 3.125rem;
}
@media screen and (max-width: 767px) {
  .excluded {
    padding: 1.25rem 0.9375rem;
  }
}

.excluded__flex {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .excluded__flex {
    display: block;
  }
}

.excluded__img {
  width: 33%;
}
@media screen and (max-width: 767px) {
  .excluded__img {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }
}

.excluded__body {
  width: 65%;
  margin-top: -0.3125rem;
}
@media screen and (max-width: 767px) {
  .excluded__body {
    width: 100%;
    font-size: 0.9375rem;
    margin-top: 1.25rem;
  }
}

.excluded__text {
  line-height: 1.69;
}

.excluded__subtext {
  font-size: 0.875rem;
  line-height: 1;
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .excluded__subtext {
    line-height: 1.5;
  }
}

.excluded__link {
  display: inline-block;
  font-size: 0.875rem;
  text-decoration: underline;
  word-break: break-all;
}
/* feature */
.feature {
  margin-top: 5.9375rem;
}

.feature__text {
  text-align: center;
  font-size: 1.25rem;
  letter-spacing: 0.08em;
  margin-top: 1.875rem;
}
@media screen and (max-width: 767px) {
  .feature__text {
    font-size: 1rem;
    margin-top: 1.25rem;
  }
}

.feature__items {
  margin-top: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .feature__items {
    margin-top: 2.5rem;
  }
}

.feature__item {
  padding: 2.1875rem 2.8125rem 2.5rem;
  border: 0.0625rem solid #707070;
}
@media screen and (max-width: 767px) {
  .feature__item {
    padding: 1.5625rem 1.25rem;
  }
}
.feature__item + .feature__item {
  margin-top: 1.875rem;
}
.feature__item:last-child {
  padding: 0.9375rem 0 1.25rem 1.875rem;
}
@media screen and (max-width: 767px) {
  .feature__item:last-child {
    padding: 1.5625rem 1.25rem;
  }
}

.feature__item-title {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.08em;
  color: #445F39;
}
@media screen and (max-width: 767px) {
  .feature__item-title {
    font-size: 1.125rem;
  }
}

.feature__item-text {
  font-size: 0.9375rem;
  line-height: 1.8;
  letter-spacing: 0.04em;
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .feature__item-text {
    font-size: 0.875rem;
    margin-top: 0.9375rem;
  }
}

.feature__item-flex {
  display: flex;
  justify-content: space-between;
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .feature__item-flex {
    display: block;
  }
}

.feature__item-body {
  width: 62%;
}
@media screen and (max-width: 767px) {
  .feature__item-body {
    width: 100%;
  }
}
.feature__item-body .feature__item-text {
  margin-top: 0;
}

.feature__item-imgs {
  width: 30%;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .feature__item-imgs {
    width: 100%;
    margin-top: 1.25rem;
  }
}

.feature__item-img-wrapper {
  width: 44%;
}
@media screen and (max-width: 767px) {
  .feature__item-img-wrapper {
    width: 46%;
  }
}

.feature__item-zoom {
  font-size: 0.6875rem;
  letter-spacing: 0.08em;
  padding-left: 1.1875rem;
  background: url(../img/search-icon.png) no-repeat center left/0.8125rem;
  margin-top: 0.3125rem;
}

.feature__item-heading {
  text-align: center;
  font-size: 1.625rem;
  font-weight: 700;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .feature__item-heading {
    font-size: 1.125rem;
  }
}

.feature__item-logo {
  width: 40%;
}
@media screen and (max-width: 767px) {
  .feature__item-logo {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }
}

.feature__item-subtext {
  width: 59%;
  margin-top: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .feature__item-subtext {
    width: 100%;
    font-size: 0.9375rem;
    margin-top: 0.9375rem;
  }
}
.feature__item-subtext a {
  text-decoration: underline;
}

/* plan */
.plan {
  margin-top: 5.75rem;
}

.plan__subtitle {
  text-align: center;
  font-size: 1.25rem;
  letter-spacing: 0.08em;
  line-height: 1.7;
}
@media screen and (max-width: 767px) {
  .plan__subtitle {
    font-size: 1.125rem;
  }
}

.plan__title {
  text-align: center;
  color: #445F39;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0.08em;
  margin-top: 0.375rem;
}
@media screen and (max-width: 767px) {
  .plan__title {
    font-size: 1rem;
  }
}
.plan__title span {
  display: inline-block;
  font-size: 1.75rem;
  margin-left: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .plan__title span {
    display: block;
    font-size: 1.5rem;
  }
}

.plan__items {
  display: flex;
  margin-top: 1.875rem;
}
@media screen and (max-width: 767px) {
  .plan__items {
    display: block;
  }
}

.plan__item {
  text-align: center;
  border: 0.0625rem solid #707070;
  border-top: 0.5rem solid #445F39;
  width: calc(33.33333333% - 2.625rem * 2 / 3);
  padding: 2.1875rem 2.1875rem;
}
@media screen and (max-width: 1350px) {
  .plan__item {
    width: calc(33.33333333% - 1.25rem * 2 / 3);
    padding: 2.1875rem 0.9375rem;
  }
}
@media screen and (max-width: 767px) {
  .plan__item {
    width: 100%;
    padding: 1.875rem 1.25rem;
  }
  .plan__item + .plan__item {
    margin-top: 1.25rem;
  }
}
.plan__item:not(:nth-child(3n+1)) {
  margin-left: 2.625rem;
}
@media screen and (max-width: 1350px) {
  .plan__item:not(:nth-child(3n+1)) {
    margin-left: 1.25rem;
  }
}
@media screen and (max-width: 767px) {
  .plan__item:not(:nth-child(3n+1)) {
    margin-left: auto;
  }
}

.plan__item-subtitle {
  font-size: 0.8125rem;
  letter-spacing: 0.08em;
}

.plan__item-title {
  font-size: 1.25rem;
  letter-spacing: 0.08em;
  font-weight: 700;
  margin-top: 0.625rem;
}

.plan__item-text {
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  border-top: 0.0625rem dashed #ccc;
  padding-top: 0.625rem;
  margin-top: 0.625rem;
}

.plan__item-price {
  letter-spacing: 0.08em;
  margin-top: 0.625rem;
}
.plan__item-price span:first-child {
  font-size: 1.4375rem;
  font-weight: 700;
}
.plan__item-price span:nth-child(2) {
  margin-left: 0.1875rem;
}
.plan__item-price span:last-child {
  font-size: 0.875rem;
  margin-left: 0.1875rem;
}

.plan__item-units {
  display: flex;
  justify-content: space-between;
  max-width: 15.625rem;
  margin: 0 auto;
}

.plan__item-unit {
  text-align: left;
  margin-top: 0.9375rem;
}

.plan__item-unit-title {
  text-align: center;
  display: inline-block;
  font-size: 0.875rem;
  color: #445F39;
  letter-spacing: 0.08em;
  background: #F5F5F2;
  min-width: 6.125rem;
  padding: 0.125rem 0.125rem;
}

.plan__item-list {
  margin-top: 0.3125rem;
}

.plan__item-list-item {
  font-size: 0.8125rem;
  letter-spacing: 0.08em;
  line-height: 1.77;
}

.plan__boxes {
  display: flex;
  margin-top: 3.75rem;
}
@media screen and (max-width: 767px) {
  .plan__boxes {
    display: block;
    margin-top: 2.5rem;
  }
}

.plan__box {
  width: calc(25% - 1.375rem * 3 / 4);
}
@media screen and (max-width: 767px) {
  .plan__box {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }
  .plan__box + .plan__box {
    margin-top: 1.875rem;
  }
}
.plan__box:not(:nth-child(4n+1)) {
  margin-left: 1.375rem;
}
@media screen and (max-width: 767px) {
  .plan__box:not(:nth-child(4n+1)) {
    margin-left: auto;
  }
}

.plan__box-text {
  letter-spacing: 0.08em;
  margin-top: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .plan__box-text {
    font-size: 0.9375rem;
  }
}

/* process */
.process {
  margin-top: 6.25rem;
}

.process__title {
  text-align: center;
  font-size: 1.375rem;
  font-weight: 700;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .process__title {
    font-size: 1.25rem;
  }
}

.process__subtitle {
  text-align: center;
  font-size: 1.125rem;
  letter-spacing: 0.08em;
  margin-top: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .process__subtitle {
    font-size: 1rem;
  }
}

.process__items {
  width: 48.4375rem;
  max-width: 100%;
  margin: 2rem auto 0;
}

.process__item + .process__item {
  margin-top: 1.25rem;
}

.process__item-title {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .process__item-title {
    font-size: 1rem;
  }
}

.process__item-text {
  font-size: 0.9375rem;
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: 0.1875rem;
}
@media screen and (max-width: 767px) {
  .process__item-text {
    font-size: 0.875rem;
  }
}

.process__box {
  background: #F2F2F2;
  border-radius: 0.9375rem;
  width: 50.3125rem;
  max-width: 100%;
  margin: 2.5rem auto 0;
  padding: 1.5625rem 2.1875rem 2.5rem;
}
@media screen and (max-width: 767px) {
  .process__box {
    padding: 1.5625rem 1.25rem;
  }
}

.process__unit + .process__unit {
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .process__unit + .process__unit {
    margin-top: 1.875rem;
  }
}

.process__unit-title {
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .process__unit-title {
    font-size: 1rem;
  }
}

.process__unit-text {
  font-size: 0.75rem;
  letter-spacing: 0.04em;
  line-height: 2.25;
}
@media screen and (max-width: 767px) {
  .process__unit-text {
    margin-top: 0.625rem;
  }
}

/* risk */
.risk {
  margin-top: 4.8125rem;
}

.risk__title {
  text-align: center;
  font-size: 1.375rem;
  letter-spacing: 0.08em;
  line-height: 1.68;
}
@media screen and (max-width: 767px) {
  .risk__title {
    font-size: 1.25rem;
  }
}

.risk__items {
  display: flex;
  margin-top: 2.875rem;
}
@media screen and (max-width: 767px) {
  .risk__items {
    display: block;
  }
}

.risk__item {
  width: calc(33.33333333% - 3.125rem * 2 / 3);
}
@media screen and (max-width: 767px) {
  .risk__item {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }
  .risk__item + .risk__item {
    margin-top: 2.5rem;
  }
}
.risk__item:not(:nth-child(3n+1)) {
  margin-left: 3.125rem;
}
@media screen and (max-width: 767px) {
  .risk__item:not(:nth-child(3n+1)) {
    margin-left: auto;
  }
}

.risk__item-text {
  font-size: 0.875rem;
  line-height: 1.71;
  margin-top: 0.3125rem;
}

/* case-archive */
.case-archive__youtube-wrapper {
  width: 40.625rem;
  max-width: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .case-archive__youtube-wrapper {
    width: 31.25rem;
  }
}

.case-archive__youtube {
  width: 100%;
  padding-top: 56.2883435583%;
  position: relative;
}
.case-archive__youtube iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.case-archive__category {
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .case-archive__category {
    margin-top: 3.75rem;
  }
}
.case-archive__category.categories {
  justify-content: flex-start;
}

.case-archive__items {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  .case-archive__items {
    display: block;
    margin-top: 2.5rem;
  }
}

.case-archive__item {
  width: calc(33.33333333% - 2.5rem * 2 / 3);
  margin-top: 4.375rem;
}
@media screen and (max-width: 767px) {
  .case-archive__item {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }
  .case-archive__item + .case-archive__item {
    margin-top: 2.5rem;
  }
}
.case-archive__item:not(:nth-child(3n+1)) {
  margin-left: 2.5rem;
}
@media screen and (max-width: 767px) {
  .case-archive__item:not(:nth-child(3n+1)) {
    margin-left: auto;
  }
}
.case-archive__item .card__title {
  margin-top: 0.75rem;
}

/* case-single */
.case-single__title {
  text-align: center;
  font-size: 1.25rem;
  letter-spacing: 0.08em;
}

.case-single__category {
  margin-top: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .case-single__category {
    margin-top: 2.5rem;
  }
}
.case-single__category span {
  text-align: center;
  min-width: 6.125rem;
  padding: 0.25rem 0.625rem;
}
@media screen and (max-width: 767px) {
  .case-single__category span {
    min-width: unset;
  }
}

.case-single__thumbnail {
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .case-single__thumbnail {
    max-width: 25rem;
    margin: 0.625rem auto 0;
  }
}

.case-single__post {
  padding: 3.125rem 0;
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .case-single__post {
    margin-top: 3.75rem;
    padding: 2.5rem 0;
  }
}
.case-single__post p {
  font-size: 0.875rem;
  letter-spacing: 0.08em;
  line-height: 3.6;
  margin-bottom: 0;
}
@media screen and (max-width: 767px) {
  .case-single__post p {
    line-height: 2;
  }
}
.case-single__post p:not(:last-child) {
  margin-bottom: 2.5rem;
}

.case-single__modal {
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .case-single__modal {
    margin-top: 3.75rem;
  }
}

.case-single__modal-imgs {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1.25rem;
}
@media screen and (max-width: 767px) {
  .case-single__modal-imgs {
    margin-top: -0.625rem;
  }
}

.case-single__modal-img-wrapper {
  width: calc(33.33333333% - 1.25rem * 2 / 3);
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .case-single__modal-img-wrapper {
    width: calc(50% - 0.625rem * 1 / 2);
    margin-top: 0.625rem;
  }
}
.case-single__modal-img-wrapper:not(:nth-child(3n+1)) {
  margin-left: 1.25rem;
}
@media screen and (max-width: 767px) {
  .case-single__modal-img-wrapper:not(:nth-child(3n+1)) {
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .case-single__modal-img-wrapper:not(:nth-child(2n+1)) {
    margin-left: 0.625rem;
  }
}

.case-single__modal-img {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}
.case-single__modal-img:hover {
  opacity: 1;
}
.case-single__modal-img:hover img {
  transform: scale(1.2);
}
.case-single__modal-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s;
}

.case-single__links {
  margin-top: 4.0625rem;
}

/* news */
.new__cards {
  display: flex;
  margin-top: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .new__cards {
    margin-top: 1.875rem;
  }
}
.new__cards .cards__item {
  width: calc(33.33333333% - 2.5rem * 2 / 3);
}
.new__cards .cards__item:not(:nth-child(3n+1)) {
  margin-left: 2.5rem;
}
.new__cards .cards__item .card__title {
  margin-top: 0.75rem;
}

/* column-archive */
.column-archive__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .column-archive__items {
    display: block;
    margin-top: 2.5rem;
  }
}

.column-archive__item {
  width: calc(50% - 2.5rem * 1 / 2);
  margin-top: 3.25rem;
}
@media screen and (max-width: 767px) {
  .column-archive__item {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }
  .column-archive__item + .column-archive__item {
    margin-top: 2.5rem;
  }
}
.column-archive__item:not(:nth-child(2n+1)) {
  margin-left: 2.5rem;
}
@media screen and (max-width: 767px) {
  .column-archive__item:not(:nth-child(2n+1)) {
    margin-left: auto;
  }
}
.column-archive__item .card__subtitle {
  margin-top: 0.625rem;
}
.column-archive__item .card__heading {
  margin-top: 0.375rem;
  letter-spacing: 0.04em;
}
.column-archive__item .card__img {
  padding-top: 46%;
}

/* column-single */
.column-single__wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
}
@media screen and (max-width: 767px) {
  .column-single__wrapper {
    display: block;
    margin-top: 3.75rem;
  }
}

.column-single__content {
  width: 69%;
}
@media screen and (max-width: 767px) {
  .column-single__content {
    width: 100%;
  }
}

.column-single__header {
  display: flex;
}
@media screen and (max-width: 767px) {
  .column-single__header {
    display: block;
  }
}

.column-single__icon-wrapper {
  width: 4.3125rem;
}
@media screen and (max-width: 767px) {
  .column-single__icon-wrapper {
    width: 5rem;
    margin: 0 auto;
  }
}

.column-single__icon {
  width: 100%;
  padding-top: 100%;
  position: relative;
}
.column-single__icon img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.column-single__info {
  margin-left: 1.0625rem;
  margin-top: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .column-single__info {
    margin-left: 0;
    margin-top: 1.25rem;
  }
}

.column-single__category {
  margin-top: 1.875rem;
}

.column-single__title {
  font-size: 1.625rem;
  letter-spacing: 0.12em;
  line-height: 1.3;
  font-weight: 700;
  margin-top: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .column-single__title {
    font-size: 1.375rem;
  }
}

.column-single__thumbnail {
  margin-top: 1.875rem;
}
@media screen and (max-width: 767px) {
  .column-single__thumbnail {
    max-width: 25rem;
    margin: 1.25rem auto 0;
  }
}

.column-single__lead {
  font-size: 0.9375rem;
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: 1.5625rem;
}

.column-single__table {
  margin-top: 3.75rem;
}
@media screen and (max-width: 767px) {
  .column-single__table {
    margin-top: 2.5rem;
  }
}
.column-single__table #toc_container {
  background: #F5F5F2;
  border: 0.0625rem solid #ccc;
  padding: 2.1875rem 3.125rem 2.1875rem;
}
@media screen and (max-width: 767px) {
  .column-single__table #toc_container {
    padding: 1.25rem 1.25rem;
  }
}
.column-single__table .toc_title {
  text-align: center;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.12em;
}
.column-single__table .toc_list {
  border-top: 0.0625rem solid #ccc;
  padding: 1.375rem 1.375rem 0;
  margin-top: 0.6875rem;
}
@media screen and (max-width: 767px) {
  .column-single__table .toc_list {
    padding: 1.25rem 0 0;
  }
}
.column-single__table .toc_list li {
  font-size: 0.875rem;
  letter-spacing: 0.12em;
  font-weight: 700;
  line-height: 1.93;
  padding-left: 1em;
  position: relative;
}
.column-single__table .toc_list li::before {
  position: absolute;
  content: "・";
  left: 0;
  top: 0;
}

.column-single__post {
  margin-top: 4.0625rem;
}
@media screen and (max-width: 767px) {
  .column-single__post {
    margin-top: 3.125rem;
  }
}

.column-single__sidebar {
  width: 22%;
}
@media screen and (max-width: 767px) {
  .column-single__sidebar {
    width: 100%;
    max-width: 25rem;
    margin: 3.75rem auto 0;
  }
}

/* concept-nav */
@media screen and (max-width: 767px) {
  .concept-nav__inner {
    padding: 0 0.9375rem;
  }
}

.concept-nav__items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.concept-nav__item {
  font-size: 0.875rem;
  letter-spacing: 0.12em;
}
.concept-nav__item + .concept-nav__item {
  margin-left: 2.1875rem;
  position: relative;
}
.concept-nav__item + .concept-nav__item::before {
  position: absolute;
  content: "|";
  color: #9FA0A0;
  left: -1.25rem;
  top: 0.3125rem;
}
.concept-nav__item a,
.concept-nav__item span {
  display: inline-block;
  padding: 0.3125rem 0;
}

/* concept */
.concept__video {
  margin-top: 0.8125rem;
}
.concept__video video {
  width: 100%;
  height: 43.75rem;
  object-fit: cover;
}
@media screen and (max-width: 767px) {
  .concept__video video {
    height: auto;
  }
}

.concept__header {
  display: flex;
  justify-content: space-between;
  width: 58.75rem;
  max-width: 100%;
  margin: 0 auto;
  padding: 5.9375rem 0;
}
@media screen and (max-width: 767px) {
  .concept__header {
    display: block;
    padding: 3.75rem 0;
  }
}

.concept__header-body {
  width: 55%;
}
@media screen and (max-width: 767px) {
  .concept__header-body {
    width: 100%;
  }
}

.concept__title {
  display: flex;
  align-items: center;
  font-size: 2.5rem;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .concept__title {
    font-size: 1.5rem;
  }
}
.concept__title span {
  display: inline-block;
  font-size: 1.5rem;
  margin-left: 1.625rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .concept__title span {
    font-size: 1.125rem;
    margin-top: 0.375rem;
  }
}
.concept__title span::before {
  position: absolute;
  content: "";
  height: 0.0625rem;
  width: 0.75rem;
  background: #000;
  left: -1.0625rem;
  top: 1.125rem;
}
@media screen and (max-width: 767px) {
  .concept__title span::before {
    top: 0.9375rem;
  }
}

.concept__text {
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  line-height: 2.4;
}
@media screen and (max-width: 767px) {
  .concept__text {
    font-size: 0.8125rem;
    line-height: 1.7;
  }
}

.concept__header-text {
  margin-top: 1rem;
}

.concept__header-img {
  width: 38.3%;
}
@media screen and (max-width: 767px) {
  .concept__header-img {
    width: 100%;
    max-width: 25rem;
    margin: 1.25rem auto 0;
  }
}

.concept__middle {
  padding: 5.9375rem 0 5.9375rem;
  background: url(../img/concept-bg.png) no-repeat center/cover;
}
@media screen and (max-width: 767px) {
  .concept__middle {
    padding: 3.75rem 0;
  }
}

.concept__body {
  width: 45rem;
  max-width: 100%;
  margin: 0 auto;
}

.concept__body-text {
  margin-top: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .concept__body-text {
    margin-top: 1.25rem;
  }
}

.concept__units {
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .concept__units {
    margin-top: 3.75rem;
  }
}

.concept__unit {
  max-width: 100%;
  margin: 0 auto;
}
.concept__unit + .concept__unit {
  margin-top: 7.1875rem;
}
@media screen and (max-width: 767px) {
  .concept__unit + .concept__unit {
    margin-top: 3.75rem;
  }
}

.concept__unit-img {
  width: 33.75rem;
  max-width: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .concept__unit-img {
    width: 25rem;
  }
}

.concept__unit-text {
  display: flex;
  justify-content: center;
  margin-top: 1.875rem;
}
@media screen and (max-width: 767px) {
  .concept__unit-text {
    margin-top: 1.25rem;
  }
}

.concept__flex {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 59.8125rem;
  max-width: 100%;
  margin: 7.1875rem auto 0;
}
@media screen and (max-width: 767px) {
  .concept__flex {
    display: block;
    margin-top: 3.75rem;
  }
}

.concept__flex-text {
  width: 49.8%;
}
@media screen and (max-width: 767px) {
  .concept__flex-text {
    width: 100%;
  }
}

.concept__flex-img {
  width: 42.5%;
}
@media screen and (max-width: 767px) {
  .concept__flex-img {
    width: 100%;
    max-width: 25rem;
    margin: 1.25rem auto 0;
  }
}

.concept__img {
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .concept__img {
    margin-top: 3.75rem;
  }
}
.concept__img img {
  max-height: 62.5rem;
  object-fit: cover;
}

.concept__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7.5rem 0;
}
@media screen and (max-width: 767px) {
  .concept__footer {
    display: block;
    padding: 3.75rem 0;
  }
}

.concept__footer-title {
  font-size: 1.5rem;
  line-height: 1.46;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .concept__footer-title {
    font-size: 1.125rem;
  }
}

.concept__footer-img {
  width: 15rem;
  max-width: 100%;
  margin-left: 3.75rem;
}
@media screen and (max-width: 767px) {
  .concept__footer-img {
    width: 100%;
    max-width: 25rem;
    margin: 1.25rem auto 0;
  }
}

.concept__footer-text {
  margin-left: 3.75rem;
}
@media screen and (max-width: 767px) {
  .concept__footer-text {
    margin-left: 0;
    margin-top: 1.25rem;
  }
}

.concept__item {
  padding: 2.5rem 0 3.75rem;
}
.concept__item:nth-child(odd) {
  background: #ECE9E8;
}
.concept__item:nth-child(even) {
  background: #E2E6E7;
}

.concept__item-inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
@media screen and (max-width: 767px) {
  .concept__item-inner {
    display: block;
  }
}

.concept__item-body {
  width: 50%;
  padding-left: 2.5rem;
}
@media screen and (max-width: 767px) {
  .concept__item-body {
    width: 100%;
    padding-left: 0;
  }
}

.concept__item-number {
  font-size: 4.375rem;
  letter-spacing: 0.04em;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .concept__item-number {
    font-size: 2rem;
  }
}

.concept__item-title {
  font-size: 1.5rem;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .concept__item-title {
    font-size: 1rem;
  }
}

.concept__item-text {
  width: 92%;
  margin-top: 3.125rem;
}
@media screen and (max-width: 767px) {
  .concept__item-text {
    width: 100%;
    margin-top: 1.25rem;
  }
}

.concept__item-link {
  margin-top: 3.4375rem;
}
@media screen and (max-width: 767px) {
  .concept__item-link {
    font-size: 0.875rem;
    margin-top: 2.5rem;
  }
}

.concept__item-img {
  width: 48%;
}
@media screen and (max-width: 767px) {
  .concept__item-img {
    width: 100%;
    max-width: 25rem;
    margin: 1.25rem auto 0;
  }
}

/* obsession-fv */
.obsession-fv {
  text-align: center;
  min-height: 17.0625rem;
  padding: 5rem 0;
}
@media screen and (max-width: 767px) {
  .obsession-fv {
    padding: 3.75rem 0;
    min-height: 12.5rem;
  }
}

.obsession-fv--genbahinshitsu {
  background: url(../img/obsession-fv-genbahinshitsu.jpg) no-repeat center/cover;
}

.obsession-fv--sekkei {
  background: url(../img/obsession-fv-sekkei.jpg) no-repeat center/cover;
}

.obsession-fv--seinou {
  background: url(../img/obsession-fv-seinou.jpg) no-repeat center/cover;
}

.obsession-fv--kenkou {
  background: url(../img/obsession-fv-kenkou.jpg) no-repeat center/cover;
}

.obsession-fv--shizensozai {
  background: url(../img/obsession-fv-shizensozai.jpg) no-repeat center/cover;
}

.obsession-fv__title {
  font-size: 1.5rem;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .obsession-fv__title {
    font-size: 1.25rem;
  }
}

.obsession-fv__text {
  letter-spacing: 0.08em;
  margin-top: 1.875rem;
}
@media screen and (max-width: 767px) {
  .obsession-fv__text {
    font-size: 0.875rem;
  }
}

/* obsession */
.obsession .concept-nav {
  margin: 1.375rem 0;
}
@media screen and (max-width: 767px) {
  .obsession .concept-nav {
    margin: 0.9375rem 0;
  }
}
@media screen and (max-width: 767px) {
  .obsession .section-title {
    font-size: 1.125rem;
  }
}

.obsession__text {
  text-align: center;
  letter-spacing: 0.04em;
  line-height: 2;
  margin-top: 7.3125rem;
}
@media screen and (max-width: 767px) {
  .obsession__text {
    text-align: left;
    font-size: 0.8125rem;
    line-height: 1.7;
    margin-top: 5rem;
  }
}

.obsession__footer {
  background: #F5F5F5;
  padding: 2.5rem 0;
  margin-top: 7.5rem;
}
@media screen and (max-width: 767px) {
  .obsession__footer {
    margin-top: 5rem;
  }
}

.obsession__banner {
  border-top: 0.0625rem solid #E3E6E6;
  margin-top: 2.5rem;
  padding-top: 2.5rem;
}

/* catalog */
.catalog .consultation {
  padding: 4.375rem 6.6875rem 4.375rem;
}
@media screen and (max-width: 767px) {
  .catalog .consultation {
    padding: 2.5rem 1.25rem;
  }
}
@media screen and (max-width: 767px) {
  .catalog .form__item:last-child {
    margin-top: 3.125rem;
  }
}
.catalog .form__item-label > span, .catalog .form__item-label-inner > span {
  background: #5A3234;
}
.catalog .form__item-input {
  width: 56%;
}
@media screen and (max-width: 767px) {
  .catalog .form__item-input {
    width: 100%;
  }
}
.catalog .form__item--post .form__item-input {
  padding-left: 1.5625rem;
  position: relative;
}
.catalog .form__item--post .form__item-input::before {
  position: absolute;
  content: "〒";
  font-size: 0.9375rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.catalog .form__item--post input {
  width: 11.9375rem;
}
.catalog .form__check {
  display: block;
  position: relative;
}
.catalog .form__check > span {
  display: block;
  margin-right: 0;
}
.catalog .form__check > span:not(:last-of-type) {
  margin-bottom: 1.125rem;
}
@media screen and (max-width: 767px) {
  .catalog .form__check > span:not(:last-of-type) {
    margin-bottom: 1.4375rem;
  }
}
.catalog .form__check input[type=checkbox] + span::after {
  border-color: #000;
}
.catalog .form__check input {
  position: absolute;
  right: 0;
}
@media screen and (max-width: 767px) {
  .catalog .form__check input {
    height: 2.1875rem;
  }
}
.catalog .form__check input:first-of-type {
  width: calc(100% - 4.6875rem);
  bottom: 3.125rem;
}
@media screen and (max-width: 767px) {
  .catalog .form__check input:first-of-type {
    bottom: 2.5rem;
  }
}
.catalog .form__check input:last-child {
  width: calc(100% - 5.625rem);
  bottom: 0.4375rem;
}
@media screen and (max-width: 767px) {
  .catalog .form__check input:last-child {
    bottom: -0.4375rem;
  }
}
.catalog .form__privacy {
  text-align: left;
  margin: 0;
  margin-left: -1.25rem;
}
.catalog .form__privacy-box + span::after {
  border-color: #000;
}
.catalog .form__btn {
  margin-top: 2.5rem;
}

/* event-archive */
.event-archive__calendar {
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .event-archive__calendar {
    overflow-x: auto;
    white-space: nowrap;
    margin-top: 3.75rem;
    padding-bottom: 1.25rem;
  }
  .event-archive__calendar .calendar {
    width: 43.75rem;
  }
}

.event-archive__items {
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .event-archive__items {
    margin-top: 2.5rem;
  }
}

.event-archive__item {
  border: 0.0625rem solid #ccc;
}
.event-archive__item + .event-archive__item {
  margin-top: 3.125rem;
}
@media screen and (max-width: 767px) {
  .event-archive__item + .event-archive__item {
    margin-top: 2.5rem;
  }
}

.event-archive__item-inner {
  display: flex;
  flex-direction: row-reverse;
}
@media screen and (max-width: 767px) {
  .event-archive__item-inner {
    display: block;
  }
}
.event-archive__item-inner:hover {
  opacity: 1;
}
.event-archive__item-inner:hover img {
  transform: scale(1.2);
}

.event-archive__item-img-wrapper {
  width: 37%;
}
@media screen and (max-width: 767px) {
  .event-archive__item-img-wrapper {
    width: 100%;
    height: 12.5rem;
  }
}

.event-archive__item-img {
  width: 100%;
  height: 100%;
  padding-top: 71.472392638%;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 767px) {
  .event-archive__item-img {
    padding-top: unset;
  }
}
.event-archive__item-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s;
}

.event-archive__item-body {
  width: 63%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1.25rem 3.125rem;
}
@media screen and (max-width: 767px) {
  .event-archive__item-body {
    width: 100%;
    padding: 1.25rem 0.9375rem;
  }
}

.event-archive__item-title {
  font-weight: 700;
  letter-spacing: 0.08em;
  color: #445F39;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.event-archive__item-info {
  margin-top: 1.4375rem;
}
.event-archive__item-info .info__item:not(:first-child) {
  margin-top: 0.625rem;
}
.event-archive__item-info .info__item-title {
  padding: 0.125rem 0.75rem 0.1875rem;
}

.event-archive__item-text {
  font-size: 0.875rem;
  letter-spacing: 0.04em;
  margin-top: 0.9375rem;
}

.event-archive__banner {
  background: #F5F5F2;
  padding: 4.375rem 0;
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .event-archive__banner {
    padding: 3.75rem 0;
    margin-top: 5rem;
  }
}

/* event-single */
.event-single {
  overflow-x: hidden;
}

.event-single__content {
  margin-top: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .event-single__content {
    margin-top: 2.5rem;
  }
}

.event-single__thumbnail {
  margin-left: 15rem;
}
@media screen and (max-width: 767px) {
  .event-single__thumbnail {
    margin-left: 0;
  }
}

.event-single__body {
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .event-single__body {
    margin-top: 3.75rem;
  }
}

.event-single__header .event-single__info {
  margin-top: 2.1875rem;
}

.event-single__footer .event-single__info {
  margin-top: 1.875rem;
}

.event-single__info .info__item-title {
  padding: 0.1875rem 0.75rem 0.25rem;
}

.event-single__subtitle {
  text-align: center;
  color: #445F39;
  font-weight: 700;
  letter-spacing: 0.08em;
  background: #F5F5F2;
  border: 0.0625rem solid #445F39;
  padding: 0.375rem 0.625rem;
}
@media screen and (max-width: 767px) {
  .event-single__subtitle {
    font-size: 0.9375rem;
  }
}

.event-single__title {
  font-size: 1.5rem;
  letter-spacing: 0.08em;
  font-weight: 700;
  margin-top: 2rem;
}
@media screen and (max-width: 767px) {
  .event-single__title {
    font-size: 1.375rem;
  }
}

.event-single__post {
  margin-top: 2.5rem;
}
.event-single__post h2, .event-single__post h3, .event-single__post h4, .event-single__post h5, .event-single__post h6 {
  font-weight: 500;
  letter-spacing: 0.08em;
  margin-bottom: 2.1875rem;
}
.event-single__post h2 {
  font-size: 1.25rem;
}
@media screen and (max-width: 767px) {
  .event-single__post h2 {
    font-size: 1.125rem;
  }
}
.event-single__post figure {
  margin-bottom: 2.1875rem;
}

.event-single__form {
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .event-single__form {
    margin-top: 3.75rem;
  }
}

.event-single__form-box {
  background: #fff;
}

.event-single__form-title {
  text-align: center;
}

.event-single__form-subtitle {
  text-align: center;
}

.event-single__anchor {
  margin: 0 auto 3.75rem;
}
@media screen and (max-width: 767px) {
  .event-single__anchor {
    margin-bottom: 2.5rem;
  }
}

.event-single__link {
  margin: 3.75rem auto 0;
}
@media screen and (max-width: 767px) {
  .event-single__link {
    margin-top: 2.5rem;
  }
}

/* latest */
.latest__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: -0.3125rem;
}
@media screen and (max-width: 767px) {
  .latest__items {
    display: block;
    margin-top: 2.5rem;
  }
}

.latest__item {
  border: 0.0625rem solid #ccc;
  width: calc(50% - 1.375rem * 1 / 2);
  margin-top: 3.125rem;
}
@media screen and (max-width: 767px) {
  .latest__item {
    width: 100%;
  }
  .latest__item + .latest__item {
    margin-top: 2.5rem;
  }
}
.latest__item:not(:nth-child(2n+1)) {
  margin-left: 1.375rem;
}
@media screen and (max-width: 767px) {
  .latest__item:not(:nth-child(2n+1)) {
    margin-left: auto;
  }
}

.latest__item-inner {
  display: flex;
  flex-direction: row-reverse;
}
@media screen and (max-width: 767px) {
  .latest__item-inner {
    display: block;
  }
}
.latest__item-inner:hover {
  opacity: 1;
}
.latest__item-inner:hover img {
  transform: scale(1.2);
}

.latest__item-body {
  width: 64%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.1875rem 1.375rem;
}
@media screen and (max-width: 767px) {
  .latest__item-body {
    width: 100%;
    padding: 1.25rem 1.25rem;
  }
}

.latest__item-title {
  color: #445F39;
  font-weight: 700;
  letter-spacing: 0.08em;
  line-height: 1.37;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.latest__item-info {
  margin-top: 1.0625rem;
}
.latest__item-info .info__item:not(:first-child) {
  margin-top: 0.625rem;
}
.latest__item-info .info__item-title {
  padding: 0.1875rem 0.75rem 0.25rem;
}

.latest__item-text {
  font-size: 0.8125rem;
  letter-spacing: 0.08em;
  line-height: 1.77;
  margin-top: 0.75rem;
}

.latest__item-img-wrapper {
  width: 36%;
}
@media screen and (max-width: 767px) {
  .latest__item-img-wrapper {
    width: 100%;
    height: 12.5rem;
  }
}

.latest__item-img {
  width: 100%;
  height: 100%;
  padding-top: 73.0158730159%;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 767px) {
  .latest__item-img {
    padding: unset;
  }
}
.latest__item-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s;
}

/* reservation */
.reservation {
  background: #fff;
  padding: 4.0625rem 3.125rem 4.375rem;
}
@media screen and (max-width: 767px) {
  .reservation {
    padding: 2.5rem 1.25rem;
  }
}

.reservation__text {
  text-align: center;
  letter-spacing: 0.12em;
  font-weight: 700;
  color: #445F39;
  margin-top: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .reservation__text {
    font-size: 0.9375rem;
  }
}

.reservation__form {
  margin-top: 1.875rem;
}
.reservation__form .form__item-label {
  width: 25%;
}
@media screen and (max-width: 767px) {
  .reservation__form .form__item-label {
    width: 100%;
  }
}
.reservation__form .form__item-input {
  width: 53%;
}
@media screen and (max-width: 767px) {
  .reservation__form .form__item-input {
    width: 100%;
  }
}

/* exhibition */
.exhibition__heading {
  color: #000;
}

.exhibition__text {
  text-align: center;
  font-size: 0.9375rem;
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin: 1.875rem 0 4.6875rem;
}
@media screen and (max-width: 767px) {
  .exhibition__text {
    text-align: left;
    font-size: 0.875rem;
    margin-bottom: 3.75rem;
  }
}

.exhibition__items {
  margin-top: 2.5rem;
}

.exhibition__item + .exhibition__item {
  margin-top: 5rem;
}

.exhibition__item-inner {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .exhibition__item-inner {
    display: block;
  }
}

.exhibition__item-img-wrapper {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .exhibition__item-img-wrapper {
    width: 100%;
  }
}

.exhibition__item-topimg {
  width: 100%;
  padding-top: 55.7692307692%;
  position: relative;
}
.exhibition__item-topimg img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.exhibition__item-imgs {
  display: flex;
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .exhibition__item-imgs {
    margin-top: 0.9375rem;
  }
}

.exhibition__item-img {
  width: calc(25% - 1.25rem * 3 / 4);
  cursor: pointer;
  position: relative;
}
@media screen and (max-width: 767px) {
  .exhibition__item-img {
    width: calc(25% - 0.625rem * 3 / 4);
  }
}
.exhibition__item-img:not(:nth-child(4n+1)) {
  margin-left: 1.25rem;
}
@media screen and (max-width: 767px) {
  .exhibition__item-img:not(:nth-child(4n+1)) {
    margin-left: 0.625rem;
  }
}
.exhibition__item-img::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.5;
}
.exhibition__item-img.current::after {
  display: none;
}

.exhibition__item-body {
  width: 46%;
}
@media screen and (max-width: 767px) {
  .exhibition__item-body {
    width: 100%;
    margin-top: 1.5625rem;
  }
}

.exhibition__item-title {
  font-size: 1.125rem;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .exhibition__item-title {
    font-size: 1rem;
  }
}

.exhibition__item-list {
  border-top: 0.0625rem solid #ccc;
  padding-top: 1.75rem;
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .exhibition__item-list {
    margin-top: 0.9375rem;
    padding-top: 1.25rem;
  }
}

.exhibition__item-list-item {
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .exhibition__item-list-item {
    font-size: 0.875rem;
  }
}
.exhibition__item-list-item + .exhibition__item-list-item {
  margin-top: 1.75rem;
}
@media screen and (max-width: 767px) {
  .exhibition__item-list-item + .exhibition__item-list-item {
    margin-top: 1.25rem;
  }
}

.exhibition__item-list-title {
  width: 20%;
}
@media screen and (max-width: 767px) {
  .exhibition__item-list-title {
    width: 22%;
  }
}

.exhibition__item-list-text {
  width: 78%;
}
@media screen and (max-width: 767px) {
  .exhibition__item-list-text {
    width: 76%;
  }
}

.exhibition__item-link {
  margin: 3.75rem auto 0;
}
@media screen and (max-width: 767px) {
  .exhibition__item-link {
    margin-top: 2.5rem;
  }
}

.exhibition__flex {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .exhibition__flex {
    display: block;
  }
}

.exhibition__flex-body {
  width: 53%;
}
@media screen and (max-width: 767px) {
  .exhibition__flex-body {
    width: 100%;
  }
}

.exhibition__flex-title {
  font-size: 1.125rem;
  letter-spacing: 0.08em;
  line-height: 1.45;
}
@media screen and (max-width: 767px) {
  .exhibition__flex-title {
    font-size: 1rem;
  }
}

.exhibition__flex-text {
  font-size: 0.9375rem;
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .exhibition__flex-text {
    font-size: 0.875rem;
    margin-top: 1.25rem;
  }
}

.exhibition__flex-img {
  width: 42%;
}
@media screen and (max-width: 767px) {
  .exhibition__flex-img {
    width: 100%;
    max-width: 25rem;
    margin: 1.25rem auto 0;
  }
}

/* model */
.model .appointment {
  padding-bottom: 3.125rem;
}
.model .appointment__text {
  margin-top: 2rem;
}
@media screen and (max-width: 767px) {
  .model .appointment__text {
    margin-top: 1.25rem;
  }
}
.model .appointment__tel {
  color: #000;
  margin-top: 1.625rem;
}
@media screen and (max-width: 767px) {
  .model .appointment__tel {
    margin-top: 1.25rem;
  }
}
.model .appointment__form {
  margin-top: 2.5rem;
}
.model .appointment__form .form__item-input {
  width: 52%;
}
@media screen and (max-width: 767px) {
  .model .appointment__form .form__item-input {
    width: 100%;
  }
}
.model .appointment__form .form__item--block .form__item-input {
  width: 100%;
}
.model .appointment__form .form__item--chance .form__item-input {
  margin-top: 1.25rem;
}
.model .appointment__form .form__item--chance .form__check > span {
  margin-bottom: 0.9375rem;
}
.model .appointment__form .form__item--chance .form__check > span:nth-child(3n+1) {
  width: 28%;
}
@media screen and (max-width: 767px) {
  .model .appointment__form .form__item--chance .form__check > span:nth-child(3n+1) {
    width: 100%;
  }
}
.model .appointment__form .form__item--chance .form__check > span:nth-child(3n+2) {
  width: 43%;
  margin-left: 0;
}
@media screen and (max-width: 767px) {
  .model .appointment__form .form__item--chance .form__check > span:nth-child(3n+2) {
    width: 100%;
  }
}
.model .appointment__form .form__item--chance .form__check > span:nth-child(3n+3) {
  width: 29%;
  margin-left: 0;
}
@media screen and (max-width: 767px) {
  .model .appointment__form .form__item--chance .form__check > span:nth-child(3n+3) {
    width: 100%;
  }
}
.model .appointment__form .form__item--chance .form__check > span:last-of-type {
  margin-top: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .model .appointment__form .form__item--chance .form__check > span:last-of-type {
    margin-top: 0;
  }
}
.model .appointment__form .form__privacy {
  margin-top: 1.375rem;
}
.model .appointment__form .form__btn {
  margin-top: 2.5rem;
}

.model__heading {
  color: #000;
}

.model__topimg {
  margin-top: 2.5rem;
}

.model__text {
  text-align: center;
  letter-spacing: 0.04em;
  line-height: 2.5;
  margin-top: 4.5rem;
}
@media screen and (max-width: 767px) {
  .model__text {
    text-align: left;
    font-size: 0.9375rem;
    line-height: 2;
    margin-top: 2.5rem;
  }
}

.model__imgs {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}
@media screen and (max-width: 767px) {
  .model__imgs {
    display: block;
  }
}

.model__img-wrapper {
  width: calc(50% - 4rem * 1 / 2);
  margin-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  .model__img-wrapper {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }
  .model__img-wrapper + .model__img-wrapper {
    margin-top: 1.25rem;
  }
}
.model__img-wrapper:not(:nth-child(2n+1)) {
  margin-left: 4rem;
}
@media screen and (max-width: 767px) {
  .model__img-wrapper:not(:nth-child(2n+1)) {
    margin-left: auto;
  }
}

.model__img {
  display: block;
  width: 100%;
  padding-top: 59.4164456233%;
  position: relative;
}
.model__img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.model__flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3.75rem;
}
@media screen and (max-width: 767px) {
  .model__flex {
    display: block;
    margin-top: 2.5rem;
  }
}

.model__list {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .model__list {
    width: 100%;
  }
}

.model__list-item {
  display: flex;
  align-items: center;
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  line-height: 1.4;
}
@media screen and (max-width: 767px) {
  .model__list-item {
    justify-content: space-between;
    font-size: 0.875rem;
  }
}
.model__list-item + .model__list-item {
  margin-top: 1.25rem;
}

.model__list-title {
  width: 21%;
}
@media screen and (max-width: 767px) {
  .model__list-title {
    width: 25%;
  }
}

.model__list-text {
  width: 79%;
}
@media screen and (max-width: 767px) {
  .model__list-text {
    width: 74%;
  }
}

.model__map-wrapper {
  width: 46.5%;
}
@media screen and (max-width: 767px) {
  .model__map-wrapper {
    width: 100%;
    margin-top: 1.875rem;
  }
}

.model__map {
  width: 100%;
  padding-top: 71.2809917355%;
  border: 0.0625rem solid #ccc;
  position: relative;
}
.model__map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.model__link {
  margin: 3.75rem auto 0;
}

/* quality */
.quality {
  margin-top: 7.1875rem;
}
@media screen and (max-width: 767px) {
  .quality {
    margin-top: 5rem;
  }
}

.quality__items {
  margin-top: 5rem;
}
@media screen and (max-width: 767px) {
  .quality__items {
    margin-top: 2.5rem;
  }
}

.quality__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
}
@media screen and (max-width: 767px) {
  .quality__item {
    display: block;
  }
}
.quality__item:first-child {
  width: 57.5rem;
  margin: 0 auto;
  flex-direction: row-reverse;
}
.quality__item:first-child .quality__item-body {
  width: 48%;
  margin-bottom: 1.25rem;
}
@media screen and (max-width: 767px) {
  .quality__item:first-child .quality__item-body {
    width: 100%;
    margin-bottom: 0;
  }
}
.quality__item:first-child .quality__item-img {
  width: 42%;
}
@media screen and (max-width: 767px) {
  .quality__item:first-child .quality__item-img {
    width: 100%;
  }
}
.quality__item:nth-child(2) {
  width: 60rem;
  margin: 6.25rem auto 0;
}
@media screen and (max-width: 767px) {
  .quality__item:nth-child(2) {
    margin-top: 3.75rem;
  }
}
.quality__item:nth-child(2) .quality__item-body {
  width: 35%;
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .quality__item:nth-child(2) .quality__item-body {
    width: 100%;
    margin-top: 0;
  }
}
.quality__item:nth-child(2) .quality__item-img {
  width: 58.5%;
}
@media screen and (max-width: 767px) {
  .quality__item:nth-child(2) .quality__item-img {
    width: 100%;
  }
}

.quality__item-title {
  font-size: 1.25rem;
  line-height: 1.7;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .quality__item-title {
    font-size: 1rem;
  }
}

.quality__item-text {
  font-size: 0.9375rem;
  line-height: 2.3;
  letter-spacing: 0.08em;
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .quality__item-text {
    font-size: 0.8125rem;
    line-height: 1.7;
  }
}

@media screen and (max-width: 767px) {
  .quality__item-img {
    max-width: 25rem;
    margin: 1.25rem auto 0;
  }
}

.quality__box {
  border: 0.0625rem solid #E3E6E6;
  padding: 3.75rem 3.75rem 3.4375rem;
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .quality__box {
    padding: 1.875rem 1.25rem;
    margin-top: 5rem;
  }
}

.quality__units {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .quality__units {
    display: block;
  }
}

.quality__unit {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .quality__unit {
    width: 100%;
  }
}
.quality__unit:first-child {
  border-right: 0.0625rem solid #E3E6E6;
  padding-right: 3.125rem;
}
@media screen and (max-width: 767px) {
  .quality__unit:first-child {
    border-right: unset;
    padding-right: 0;
  }
}
.quality__unit:last-child {
  padding-left: 3.125rem;
}
@media screen and (max-width: 767px) {
  .quality__unit:last-child {
    border-left: none;
    border-top: 0.0625rem solid #E3E6E6;
    padding-left: 0;
    margin-top: 1.875rem;
    padding-top: 1.875rem;
  }
}

.quality__unit-title {
  font-size: 1.25rem;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .quality__unit-title {
    font-size: 1rem;
  }
}

.quality__unit-text {
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  line-height: 2;
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .quality__unit-text {
    font-size: 0.8125rem;
    line-height: 1.7;
  }
}
.quality__unit-text:first-of-type {
  margin-top: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .quality__unit-text:first-of-type {
    margin-top: 1.25rem;
  }
}

/* strict */
.strict {
  width: 60rem;
  max-width: 100%;
  margin: 7.5rem auto 0;
}
@media screen and (max-width: 767px) {
  .strict {
    margin-top: 5rem;
  }
}

.strict__items {
  margin-top: 5rem;
}
@media screen and (max-width: 767px) {
  .strict__items {
    margin-top: 2.5rem;
  }
}

.strict__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .strict__item {
    display: block;
  }
}
.strict__item:first-child {
  flex-direction: row-reverse;
}
.strict__item:first-child .strict__item-body {
  width: 37.5%;
}
@media screen and (max-width: 767px) {
  .strict__item:first-child .strict__item-body {
    width: 100%;
  }
}
.strict__item:first-child .strict__item-img {
  width: 56%;
}
@media screen and (max-width: 767px) {
  .strict__item:first-child .strict__item-img {
    width: 100%;
  }
}
.strict__item:nth-child(2) {
  width: 57.5rem;
  max-width: 100%;
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .strict__item:nth-child(2) {
    margin-top: 3.75rem;
  }
}
.strict__item:nth-child(2) .strict__item-body {
  width: 63%;
}
@media screen and (max-width: 767px) {
  .strict__item:nth-child(2) .strict__item-body {
    width: 100%;
  }
}
.strict__item:nth-child(2) .strict__item-img {
  width: 28.5%;
}
@media screen and (max-width: 767px) {
  .strict__item:nth-child(2) .strict__item-img {
    width: 100%;
  }
}

.strict__item-title {
  font-size: 1.25rem;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .strict__item-title {
    font-size: 1rem;
  }
}

.strict__item-text {
  font-size: 0.9375rem;
  line-height: 2.3;
  letter-spacing: 0.08em;
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .strict__item-text {
    font-size: 0.8125rem;
    line-height: 1.7;
    margin-top: 0.9375rem;
  }
}

@media screen and (max-width: 767px) {
  .strict__item-img {
    max-width: 25rem;
    margin: 1.25rem auto 0;
  }
}

/* healthy */
.healthy {
  margin: 7.1875rem auto 0;
}
@media screen and (max-width: 767px) {
  .healthy {
    margin-top: 5rem;
  }
}

.healthy__items {
  margin-top: 5rem;
}
@media screen and (max-width: 767px) {
  .healthy__items {
    margin-top: 2.5rem;
  }
}

.healthy__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .healthy__item {
    display: block;
  }
}
.healthy__item + .healthy__item {
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .healthy__item + .healthy__item {
    margin-top: 2.5rem;
  }
}
.healthy__item:nth-child(even) {
  flex-direction: row-reverse;
}
.healthy__item:first-child {
  width: 63.75rem;
  max-width: 100%;
  margin: 0 auto;
}
.healthy__item:first-child .healthy__item-body {
  width: 54%;
}
@media screen and (max-width: 767px) {
  .healthy__item:first-child .healthy__item-body {
    width: 100%;
  }
}
.healthy__item:first-child .healthy__item-img {
  width: 40%;
}
@media screen and (max-width: 767px) {
  .healthy__item:first-child .healthy__item-img {
    width: 100%;
  }
}
.healthy__item:nth-child(2) {
  width: 59.6875rem;
  max-width: 100%;
  margin: 6.25rem auto 0;
}
@media screen and (max-width: 767px) {
  .healthy__item:nth-child(2) {
    margin-top: 2.5rem;
  }
}
.healthy__item:nth-child(2) .healthy__item-body {
  width: 58%;
}
@media screen and (max-width: 767px) {
  .healthy__item:nth-child(2) .healthy__item-body {
    width: 100%;
  }
}
.healthy__item:nth-child(2) .healthy__item-img {
  width: 31%;
}
@media screen and (max-width: 767px) {
  .healthy__item:nth-child(2) .healthy__item-img {
    width: 100%;
  }
}
.healthy__item:nth-child(3) {
  width: 61.75rem;
  max-width: 100%;
  margin: 6.25rem auto 0;
}
@media screen and (max-width: 767px) {
  .healthy__item:nth-child(3) {
    margin-top: 2.5rem;
  }
}
.healthy__item:nth-child(3) .healthy__item-body {
  width: 53%;
}
@media screen and (max-width: 767px) {
  .healthy__item:nth-child(3) .healthy__item-body {
    width: 100%;
  }
}
.healthy__item:nth-child(3) .healthy__item-img {
  width: 41%;
}
@media screen and (max-width: 767px) {
  .healthy__item:nth-child(3) .healthy__item-img {
    width: 100%;
  }
}
.healthy__item:last-child {
  width: 62.9375rem;
  max-width: 100%;
  margin: 6.25rem auto 0;
}
@media screen and (max-width: 767px) {
  .healthy__item:last-child {
    margin-top: 3.75rem;
  }
}
.healthy__item:last-child .healthy__item-body {
  width: 62%;
}
@media screen and (max-width: 767px) {
  .healthy__item:last-child .healthy__item-body {
    width: 100%;
  }
}
.healthy__item:last-child .healthy__item-img {
  width: 33%;
}
@media screen and (max-width: 767px) {
  .healthy__item:last-child .healthy__item-img {
    width: 100%;
  }
}

.healthy__item-title {
  font-size: 1.25rem;
  letter-spacing: 0.06em;
}
@media screen and (max-width: 767px) {
  .healthy__item-title {
    font-size: 0.9375rem;
  }
}

.healthy__item-text {
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  line-height: 2.3;
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .healthy__item-text {
    font-size: 0.8125rem;
    line-height: 1.7;
    margin-top: 0.9375rem;
  }
}

@media screen and (max-width: 767px) {
  .healthy__item-img {
    max-width: 25rem;
    margin: 1.25rem auto 0;
  }
}

.healthy__box {
  border: 0.0625rem solid #E3E6E6;
  margin-top: 8.5625rem;
  padding: 3.75rem 2.5rem 3.4375rem;
}
@media screen and (max-width: 767px) {
  .healthy__box {
    margin-top: 5rem;
    padding: 1.875rem 1.25rem;
  }
}

.healthy__imgs {
  display: flex;
}
@media screen and (max-width: 767px) {
  .healthy__imgs {
    display: block;
  }
}

.healthy__img {
  width: calc(50% - 6.8125rem * 1 / 2);
}
@media screen and (max-width: 767px) {
  .healthy__img {
    width: 18.75rem;
    max-width: 100%;
    margin: 0 auto;
  }
  .healthy__img + .healthy__img {
    margin-top: 1.25rem;
  }
}
.healthy__img:not(:nth-child(2n+1)) {
  margin-left: 6.8125rem;
}
@media screen and (max-width: 767px) {
  .healthy__img:not(:nth-child(2n+1)) {
    margin-left: auto;
  }
}

.healthy__subtitle {
  text-align: center;
  font-size: 1.25rem;
  letter-spacing: 0.08em;
  margin-top: 3.4375rem;
}
@media screen and (max-width: 767px) {
  .healthy__subtitle {
    font-size: 0.9375rem;
    margin-top: 2.5rem;
  }
}

.healthy__text {
  text-align: center;
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  line-height: 2;
  margin-top: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .healthy__text {
    font-size: 0.8125rem;
    line-height: 1.7;
  }
}

/* news-archive */
.news-archive__body {
  background: #fff;
  padding: 4.0625rem 5rem;
}
@media screen and (max-width: 767px) {
  .news-archive__body {
    padding: 2.5rem 1.25rem;
  }
}

.news-archive__items {
  border-top: 0.0625rem dashed #ccc;
}

.news-archive__item {
  border-bottom: 0.0625rem dashed #ccc;
  padding: 2.8125rem 0;
}
@media screen and (max-width: 767px) {
  .news-archive__item {
    padding: 1.5625rem 0;
  }
}

.news-archive__item-inner {
  display: block;
}

.news-archive__item-date {
  letter-spacing: 0.12em;
}
@media screen and (max-width: 767px) {
  .news-archive__item-date {
    font-size: 0.9375rem;
  }
}

.news-archive__item-title {
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.12em;
  color: #445F39;
  margin-top: 0.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .news-archive__item-title {
    font-size: 1.25rem;
  }
}

.news-archive__item-text {
  font-size: 0.875rem;
  line-height: 1.7;
  letter-spacing: 0.04em;
  margin-top: 0.9375rem;
}

/* news-single */
.news-single__date {
  font-size: 0.9375rem;
  letter-spacing: 0.12em;
}

.news-single__title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #445F39;
  letter-spacing: 0.12em;
  line-height: 1.2;
  margin-top: 1.125rem;
}
@media screen and (max-width: 767px) {
  .news-single__title {
    font-size: 1.375rem;
  }
}

.news-single__thumbnail {
  margin-top: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .news-single__thumbnail {
    max-width: 31.25rem;
    margin: 1.875rem auto 0;
  }
}

.news-single__post {
  margin-top: 3.125rem;
}
@media screen and (max-width: 767px) {
  .news-single__post {
    margin-top: 2.5rem;
  }
}

.news-single__links {
  margin-top: 3.75rem;
}

.news-single .section-entitle {
  color: #445F39;
}
.news-single .news__item-inner {
  padding: 1.5rem 1.5625rem 1.5rem 0;
}
@media screen and (max-width: 767px) {
  .news-single .news__item-inner {
    padding: 1.1875rem 1.25rem 1.1875rem 0;
  }
}
.news-single .news__item-inner::after {
  background-image: url(../img/arrow-icon06.png);
}
.news-single .news__item-category span {
  background: #F5F5F2;
  color: #445F39;
  font-weight: 700;
}
.news-single .news__link {
  color: #445F39;
  background-image: url(../img/arrow-icon06.png);
}

/* privacy */
.privacy__content {
  padding: 1.5625rem 0 6.25rem;
}

.privacy__item + .privacy__item {
  margin-top: 4.875rem;
}
@media screen and (max-width: 767px) {
  .privacy__item + .privacy__item {
    margin-top: 3.75rem;
  }
}

.privacy__item-title {
  text-align: center;
  font-size: 1.25rem;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .privacy__item-title {
    font-size: 1.125rem;
  }
}

.privacy__item-text {
  font-size: 0.9375rem;
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: 1.5625rem;
}

.privacy__link {
  margin: 5.3125rem auto 0;
}
@media screen and (max-width: 767px) {
  .privacy__link {
    margin-top: 3.75rem;
  }
}

/* qa */
.qa__content {
  padding-bottom: 6.25rem;
}
@media screen and (max-width: 767px) {
  .qa__content {
    padding-bottom: 3.75rem;
  }
}

.qa__tab {
  display: flex;
  margin-bottom: -0.0625rem;
}

.qa__tab-item {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 0.08em;
  background: #F5F5F2;
  border-top: 0.375rem solid #F5F5F2;
  border-bottom: 0.0625rem solid #707070;
  cursor: pointer;
  padding: 1.1875rem 0.9375rem;
  width: calc(33.33333333% - 0.625rem * 2 / 3);
}
@media screen and (max-width: 767px) {
  .qa__tab-item {
    font-size: 0.875rem;
    padding: 0.625rem 0.625rem;
  }
}
.qa__tab-item:not(:nth-child(3n+1)) {
  margin-left: 0.625rem;
}
.qa__tab-item.current {
  background: #fff;
  border-left: 0.0625rem solid #707070;
  border-right: 0.0625rem solid #707070;
  border-top: 0.375rem solid #445F39;
  border-bottom: none;
}

.qa__menu {
  border: 0.0625rem solid #707070;
  padding: 2.5rem 3.125rem;
}
@media screen and (max-width: 767px) {
  .qa__menu {
    padding: 1.5625rem 1.25rem;
  }
}

.qa__menu-item {
  display: none;
}
.qa__menu-item.active {
  display: block;
  animation-name: displayAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.qa__list-item {
  letter-spacing: 0.08em;
  line-height: 2;
  padding-left: 1em;
  position: relative;
}
@media screen and (max-width: 767px) {
  .qa__list-item {
    font-size: 0.875rem;
  }
}
.qa__list-item::before {
  position: absolute;
  content: "・";
  left: 0;
  top: 0;
}

.qa__block {
  margin-top: 3.4375rem;
}
@media screen and (max-width: 767px) {
  .qa__block {
    margin-top: 2.5rem;
  }
}

.qa__block-item {
  display: none;
}
.qa__block-item.active {
  display: block;
  animation-name: displayAnime;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.qa__group-item {
  font-size: 1.125rem;
  line-height: 1.72;
}
@media screen and (max-width: 767px) {
  .qa__group-item {
    font-size: 0.875rem;
  }
}
.qa__group-item + .qa__group-item {
  margin-top: 4.0625rem;
}
@media screen and (max-width: 767px) {
  .qa__group-item + .qa__group-item {
    margin-top: 2.5rem;
  }
}

.qa__group-item-header {
  display: flex;
}

.qa__group-item-body {
  display: flex;
  margin-top: 0.75rem;
}

.qa__group-item-initials {
  color: #445F39;
  font-weight: 700;
  margin-right: 1.25rem;
}
@media screen and (max-width: 767px) {
  .qa__group-item-initials {
    margin-right: 0.9375rem;
  }
}

.qa__group-item-question {
  font-weight: 700;
}

@keyframes displayAnime {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* application */
.application {
  background: #fff;
  border: 0.0625rem solid #ccc;
  padding: 4.0625rem 7.5rem 4.0625rem;
}
@media screen and (max-width: 767px) {
  .application {
    padding: 2.5rem 1.25rem;
  }
}

.application__text {
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  margin-top: 1.25rem;
}

.application__form {
  margin-top: 1.875rem;
}
.application__form .form__item-input {
  width: 70%;
}
@media screen and (max-width: 767px) {
  .application__form .form__item-input {
    width: 100%;
  }
}

/* charm */
.charm__items {
  display: flex;
  margin-top: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .charm__items {
    display: block;
  }
}

.charm__item {
  width: calc(33.33333333% - 2.5rem * 2 / 3);
}
@media screen and (max-width: 767px) {
  .charm__item {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }
  .charm__item + .charm__item {
    margin-top: 2.5rem;
  }
}
.charm__item:not(:nth-child(3n+1)) {
  margin-left: 2.5rem;
}
@media screen and (max-width: 767px) {
  .charm__item:not(:nth-child(3n+1)) {
    margin-left: auto;
  }
}

.charm__item-title {
  text-align: center;
  font-weight: 700;
  line-height: 1.68;
  letter-spacing: 0.08em;
  margin-top: 0.9375rem;
}

.charm__item-text {
  font-size: 0.9375rem;
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: 0.625rem;
}

/* demand */
.demand__text {
  font-size: 0.9375rem;
  letter-spacing: 0.04em;
  line-height: 1.8;
  width: 43.375rem;
  max-width: 100%;
  margin: 1.875rem auto 0;
}

.demand__items {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  .demand__items {
    display: block;
    margin-top: 2.5rem;
  }
}

.demand__item {
  width: calc(33.33333333% - 2.5rem * 2 / 3);
  margin-top: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .demand__item {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }
  .demand__item + .demand__item {
    margin-top: 2.5rem;
  }
}
.demand__item:not(:nth-child(3n+1)) {
  margin-left: 2.5rem;
}
@media screen and (max-width: 767px) {
  .demand__item:not(:nth-child(3n+1)) {
    margin-left: auto;
  }
}

.demand__item-text {
  text-align: center;
  font-size: 0.9375rem;
  line-height: 1.8;
  letter-spacing: 0.04em;
  margin-top: 0.75rem;
}

/* recruit */
.recruit__text {
  font-size: 0.9375rem;
  letter-spacing: 0.04em;
  line-height: 1.8;
  width: 57.5rem;
  max-width: 100%;
  margin: 0 auto;
}

/* requirements */
.requirements__list {
  margin-top: 2.1875rem;
}
.requirements__list .list__item:last-child {
  border-bottom: none;
}

/* talk */
.talk__items {
  display: flex;
  margin-top: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .talk__items {
    display: block;
  }
}

.talk__item {
  width: calc(25% - 1.6875rem * 3 / 4);
}
@media screen and (max-width: 767px) {
  .talk__item {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }
  .talk__item + .talk__item {
    margin-top: 2.5rem;
  }
}
.talk__item:not(:nth-child(4n+1)) {
  margin-left: 1.6875rem;
}
@media screen and (max-width: 767px) {
  .talk__item:not(:nth-child(4n+1)) {
    margin-left: auto;
  }
}

.talk__item-inner {
  display: block;
  width: 100%;
  text-align: left;
}
.talk__item-inner:hover {
  opacity: 1;
}
.talk__item-inner:hover img {
  transform: scale(1.2);
}

.talk__item-img {
  width: 100%;
  padding-top: 70.8333333333%;
  overflow: hidden;
  position: relative;
}
.talk__item-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s;
}

.talk__item-name {
  font-size: 0.9375rem;
  color: #000;
  letter-spacing: 0.08em;
  margin-top: 0.9375rem;
}

/* appointment */
.appointment {
  background: #fff;
  border: 0.0625rem solid #ccc;
  padding: 4.0625rem 7.625rem 4.375rem 7.625rem;
}
@media screen and (max-width: 767px) {
  .appointment {
    padding: 2.5rem 1.25rem;
  }
}

.appointment__header {
  text-align: center;
  border-bottom: 0.0625rem solid #ccc;
  padding-bottom: 2.8125rem;
  margin-bottom: 2.8125rem;
}

.appointment__text {
  text-align: center;
  font-size: 0.9375rem;
  line-height: 1.73;
  letter-spacing: 0.08em;
  margin-top: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .appointment__text {
    text-align: left;
    margin-top: 1.25rem;
  }
}

.appointment__tel {
  display: inline-block;
  font-size: 2.375rem;
  letter-spacing: 0.08em;
  color: #445F39;
  margin-top: 0.75rem;
}
@media screen and (max-width: 767px) {
  .appointment__tel {
    font-size: 1.75rem;
    margin-top: 1.25rem;
  }
}

.appointment__flex {
  display: flex;
  justify-content: center;
  margin-top: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .appointment__flex {
    display: block;
    margin-top: 0.9375rem;
  }
}

.appointment__flex-text {
  font-size: 0.75rem;
  letter-spacing: 0.08em;
}
.appointment__flex-text:last-child {
  margin-left: 0.75rem;
}
@media screen and (max-width: 767px) {
  .appointment__flex-text:last-child {
    margin-left: 0;
    margin-top: 0.3125rem;
  }
}

.appointment__form {
  margin-top: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .appointment__form {
    margin-top: 1.875rem;
  }
}
.appointment__form .form__item--chance .form__check > span:nth-child(3n+1) {
  width: 38%;
}
@media screen and (max-width: 767px) {
  .appointment__form .form__item--chance .form__check > span:nth-child(3n+1) {
    width: 100%;
  }
}
.appointment__form .form__item--chance .form__check > span:nth-child(3n+2) {
  width: 30%;
  margin-left: 3%;
}
@media screen and (max-width: 767px) {
  .appointment__form .form__item--chance .form__check > span:nth-child(3n+2) {
    width: 100%;
    margin-left: 0;
  }
}
.appointment__form .form__item--chance .form__check > span:nth-child(3n+3) {
  width: 21%;
  margin-left: auto;
}
@media screen and (max-width: 767px) {
  .appointment__form .form__item--chance .form__check > span:nth-child(3n+3) {
    width: 100%;
    margin-left: 0;
  }
}
.appointment__form .form__item--chance textarea {
  width: 62%;
}
@media screen and (max-width: 767px) {
  .appointment__form .form__item--chance textarea {
    width: 100%;
  }
}

/* case */
.case__cards {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1.875rem;
}
@media screen and (max-width: 767px) {
  .case__cards {
    display: block;
    margin-top: 2.5rem;
  }
}

.case__card {
  width: calc(33.33333333% - 2.5rem * 2 / 3);
  margin-top: 4.0625rem;
}
@media screen and (max-width: 767px) {
  .case__card {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }
  .case__card + .case__card {
    margin-top: 2.5rem;
  }
}
.case__card:not(:nth-child(3n+1)) {
  margin-left: 2.5rem;
}
@media screen and (max-width: 767px) {
  .case__card:not(:nth-child(3n+1)) {
    margin-left: auto;
  }
}
.case__card .card__text {
  font-size: 0.9375rem;
  line-height: 1.73;
  margin-top: 0.625rem;
}

.case__link {
  margin: 3.75rem auto 0;
}
@media screen and (max-width: 767px) {
  .case__link {
    margin-top: 2.5rem;
  }
}

/* flow */
.flow__items {
  margin-top: 2.8125rem;
}
@media screen and (max-width: 767px) {
  .flow__items {
    margin-top: 2.5rem;
  }
}

.flow__item + .flow__item {
  margin-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  .flow__item + .flow__item {
    margin-top: 1.25rem;
  }
}

.flow__item-header {
  display: block;
  width: 100%;
  color: #000;
  text-align: left;
  background: #F5F5F2;
  position: relative;
  padding: 0.8125rem 5rem 0.8125rem 2.75rem;
}
@media screen and (max-width: 767px) {
  .flow__item-header {
    padding: 0.625rem 2.5rem 0.625rem 1.25rem;
  }
}
.flow__item-header.opened::before {
  transform: rotate(0deg);
}
.flow__item-header::before, .flow__item-header::after {
  position: absolute;
  content: "";
  width: 1.875rem;
  height: 0.4375rem;
  background: #445F39;
  top: 50%;
  right: 2.5rem;
  transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .flow__item-header::before, .flow__item-header::after {
    width: 1.25rem;
    height: 0.25rem;
    right: 0.9375rem;
  }
}
.flow__item-header::before {
  top: 45%;
  transition: 0.2s;
  transform: rotate(90deg);
}
@media screen and (max-width: 767px) {
  .flow__item-header::before {
    top: 47%;
  }
}
.flow__item-header:hover {
  opacity: 1;
}

.flow__item-title {
  font-size: 1.25rem;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .flow__item-title {
    font-size: 1.125rem;
  }
}

.flow__item-body {
  display: none;
}

.flow__item-inner {
  display: flex;
  justify-content: space-between;
  padding: 1.875rem 2.5rem 0.9375rem;
}
@media screen and (max-width: 767px) {
  .flow__item-inner {
    display: block;
    padding: 0.9375rem 0;
  }
}

.flow__item-texts {
  width: 56%;
  margin-top: -0.5rem;
}
@media screen and (max-width: 767px) {
  .flow__item-texts {
    width: 100%;
    margin-top: 0;
  }
}

.flow__item-subtitle {
  font-size: 1.125rem;
  letter-spacing: 0.08em;
  line-height: 1.72;
}
@media screen and (max-width: 767px) {
  .flow__item-subtitle {
    font-size: 1rem;
  }
}

.flow__item-text {
  font-size: 0.9375rem;
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .flow__item-text {
    font-size: 0.875rem;
    margin-top: 0.625rem;
  }
}

.flow__item-img {
  width: 40.5%;
}
@media screen and (max-width: 767px) {
  .flow__item-img {
    width: 100%;
    max-width: 25rem;
    margin: 1.25rem auto 0;
  }
}

/* follow */
.follow__items {
  display: flex;
  justify-content: space-between;
  border-top: 0.0625rem solid #ccc;
  padding-top: 1.5625rem;
  margin-top: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .follow__items {
    display: block;
    padding-top: 1.25rem;
    margin-top: 1.25rem;
  }
}

.follow__item {
  font-size: 0.9375rem;
  letter-spacing: 0.04em;
  line-height: 1.8;
}
.follow__item:last-child {
  margin-left: 0.625rem;
  margin-right: 1.25rem;
}
@media screen and (max-width: 767px) {
  .follow__item:last-child {
    margin: 1.25rem 0 0;
  }
}

.follow__item-title {
  font-weight: 700;
}

/* reform */
.reform__subtitle {
  text-align: center;
  font-size: 1.25rem;
  letter-spacing: 0.08em;
  line-height: 1.7;
}
@media screen and (max-width: 767px) {
  .reform__subtitle {
    font-size: 1.125rem;
  }
}

.reform__text {
  text-align: center;
  font-size: 0.9375rem;
  letter-spacing: 0.04em;
  line-height: 1.87;
  margin-top: 1.5rem;
}
@media screen and (max-width: 767px) {
  .reform__text {
    text-align: left;
    font-size: 0.875rem;
  }
}

/* venue */
.venue__items {
  display: flex;
  margin-top: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .venue__items {
    display: block;
  }
}

.venue__item {
  width: calc(50% - 2.25rem * 1 / 2);
}
@media screen and (max-width: 767px) {
  .venue__item {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }
  .venue__item:last-child {
    margin-top: 2.5rem;
  }
}
.venue__item:not(:nth-child(2n+1)) {
  margin-left: 2.25rem;
}
@media screen and (max-width: 767px) {
  .venue__item:not(:nth-child(2n+1)) {
    margin-left: auto;
  }
}

.venue__item-map {
  width: 100%;
  padding-top: 81.5165876777%;
  position: relative;
}
.venue__item-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.venue__item-title {
  letter-spacing: 0.08em;
  margin-top: 0.9375rem;
}

.venue__item-text {
  font-size: 0.875rem;
  letter-spacing: 0.08em;
  line-height: 1.93;
  margin-top: 0.1875rem;
}

/* voice */
.voice__cards {
  margin-top: 2.1875rem;
}

.voice__card + .voice__card {
  margin-top: 3.125rem;
}
@media screen and (max-width: 767px) {
  .voice__card + .voice__card {
    margin-top: 2.5rem;
  }
}

.voice__link {
  margin: 3.75rem auto 0;
}
@media screen and (max-width: 767px) {
  .voice__link {
    margin-top: 2.5rem;
  }
}

/* sdgs */
.sdgs__content {
  padding: 1.5625rem 0 6.25rem;
}
@media screen and (max-width: 767px) {
  .sdgs__content {
    padding-top: 0.625rem;
  }
}

.sdgs__logo {
  width: 28.625rem;
  max-width: 100%;
  margin: 0 auto;
}

.sdgs__text {
  text-align: center;
  font-size: 1.0625rem;
  letter-spacing: 0.08em;
  line-height: 1.6;
  margin-top: 2.3125rem;
}
@media screen and (max-width: 767px) {
  .sdgs__text {
    text-align: left;
    font-size: 0.9375rem;
  }
}

.sdgs__subtext {
  display: flex;
  justify-content: flex-end;
  font-size: 0.9375rem;
  line-height: 2;
  letter-spacing: 0.08em;
  margin-top: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .sdgs__subtext {
    font-size: 0.8125rem;
    margin-top: 1.5625rem;
  }
}

.sdgs__heading {
  color: #000;
  margin-top: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .sdgs__heading {
    margin-top: 3.75rem;
  }
}

.sdgs__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1.25rem;
}
@media screen and (max-width: 767px) {
  .sdgs__items {
    display: block;
    margin-top: 2.5rem;
  }
}

.sdgs__item {
  display: flex;
  flex-direction: column;
  width: calc(50% - 4.6875rem * 1 / 2);
  margin-top: 3.75rem;
}
@media screen and (max-width: 767px) {
  .sdgs__item {
    width: 100%;
    margin-top: 0;
  }
  .sdgs__item + .sdgs__item {
    margin-top: 3.75rem;
  }
}
.sdgs__item:not(:nth-child(2n+1)) {
  margin-left: 4.6875rem;
}
@media screen and (max-width: 767px) {
  .sdgs__item:not(:nth-child(2n+1)) {
    margin-left: 0;
  }
}

.sdgs__item-title {
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0.08em;
}

.sdgs__item-subtitle {
  font-size: 1.125rem;
  line-height: 1.67;
  letter-spacing: 0.08em;
  border-top: 0.0625rem solid #ccc;
  padding-top: 1.4375rem;
  margin-top: 1.125rem;
  min-height: 7.1875rem;
}
@media screen and (max-width: 767px) {
  .sdgs__item-subtitle {
    font-size: 0.9375rem;
    min-height: unset;
    padding-top: 0.625rem;
    margin-top: 0.625rem;
  }
}

.sdgs__item-text {
  font-size: 0.9375rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
  margin-top: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .sdgs__item-text {
    font-size: 0.875rem;
  }
}

.sdgs__item-list {
  font-size: 0.9375rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
  margin-bottom: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .sdgs__item-list {
    font-size: 0.875rem;
  }
}

.sdgs__item-list-item {
  padding-left: 1em;
  text-indent: -1em;
}

.sdgs__item-imgs {
  display: flex;
  width: 27.1875rem;
  max-width: 100%;
  margin: auto auto 0;
}

.sdgs__item-img {
  width: calc(25% - 0.6875rem * 3 / 4);
}
.sdgs__item-img:not(:nth-child(4n+1)) {
  margin-left: 0.6875rem;
}

.sdgs__link {
  margin: 5.625rem auto 0;
}
@media screen and (max-width: 767px) {
  .sdgs__link {
    margin-top: 3.75rem;
  }
}

/* durability */
.durability {
  width: 58.875rem;
  max-width: 100%;
  margin: 7.1875rem auto 0;
}
@media screen and (max-width: 767px) {
  .durability {
    margin-top: 5rem;
  }
}

.durability__text {
  text-align: center;
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  line-height: 2;
  margin-top: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .durability__text {
    font-size: 0.8125rem;
    line-height: 1.7;
    margin-top: 1.5625rem;
  }
}

.durability__items {
  display: flex;
  margin-top: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .durability__items {
    display: block;
  }
}

.durability__item {
  width: calc(50% - 2.75rem * 1 / 2);
}
@media screen and (max-width: 767px) {
  .durability__item {
    width: 100%;
  }
  .durability__item + .durability__item {
    margin-top: 1.875rem;
  }
}
.durability__item:not(:nth-child(2n+1)) {
  margin-left: 2.75rem;
}
@media screen and (max-width: 767px) {
  .durability__item:not(:nth-child(2n+1)) {
    margin-left: auto;
  }
}

@media screen and (max-width: 767px) {
  .durability__item-img {
    max-width: 25rem;
    margin: 0 auto;
  }
}

.durability__item-text {
  text-align: center;
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .durability__item-text {
    font-size: 0.8125rem;
    margin-top: 0.9375rem;
  }
}

/* performance */
.performance {
  width: 57.5rem;
  max-width: 100%;
  margin: 5.25rem auto 0;
}
@media screen and (max-width: 767px) {
  .performance {
    margin-top: 5rem;
  }
}

.performance__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: -0.9375rem;
}
@media screen and (max-width: 767px) {
  .performance__items {
    display: block;
    margin-top: 1.875rem;
  }
}

.performance__item {
  width: calc(50% - 3.75rem * 1 / 2);
  margin-top: 5.9375rem;
}
@media screen and (max-width: 767px) {
  .performance__item {
    width: 100%;
    margin: 0 auto;
  }
  .performance__item + .performance__item {
    margin-top: 2.5rem;
  }
}
.performance__item:not(:nth-child(2n+1)) {
  margin-left: 3.75rem;
}
@media screen and (max-width: 767px) {
  .performance__item:not(:nth-child(2n+1)) {
    margin-left: auto;
  }
}

@media screen and (max-width: 767px) {
  .performance__item-img {
    max-width: 25rem;
    margin: 0 auto;
  }
}

.performance__item-point {
  letter-spacing: 0.08em;
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .performance__item-point {
    font-size: 0.9375rem;
    margin-top: 0.9375rem;
  }
}

.performance__item-title {
  font-size: 1.25rem;
  letter-spacing: 0.08em;
  margin-top: 0.3125rem;
}
@media screen and (max-width: 767px) {
  .performance__item-title {
    font-size: 1rem;
  }
}

.performance__item-text {
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  line-height: 2;
  margin-top: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .performance__item-text {
    font-size: 0.8125rem;
    line-height: 1.7;
    margin-top: 0.625rem;
  }
}
.performance__item-text:nth-of-type(2) {
  margin-top: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .performance__item-text:nth-of-type(2) {
    margin-top: 0.9375rem;
  }
}

.performance__item-subtext {
  font-size: 0.8125rem;
  line-height: 2.3;
  letter-spacing: 0.08em;
  margin-top: 0.75rem;
}

/* design */
.design {
  width: 57.625rem;
  max-width: 100%;
  margin: 7.1875rem auto 0;
}
@media screen and (max-width: 767px) {
  .design {
    margin-top: 5rem;
  }
}

.design__title {
  white-space: unset;
}

.design__items {
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .design__items {
    margin-top: 2.5rem;
  }
}

.design__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .design__item {
    display: block;
  }
}
.design__item + .design__item {
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .design__item + .design__item {
    margin-top: 5rem;
  }
}

.design__item--reverse {
  flex-direction: row-reverse;
}

.design__item--a .design__item-img {
  width: 39%;
}
@media screen and (max-width: 767px) {
  .design__item--a .design__item-img {
    width: 100%;
  }
}
.design__item--a .design__item-body {
  width: 55%;
}
@media screen and (max-width: 767px) {
  .design__item--a .design__item-body {
    width: 100%;
  }
}

.design__item--b .design__item-img {
  width: 28%;
}
@media screen and (max-width: 767px) {
  .design__item--b .design__item-img {
    width: 100%;
  }
}
.design__item--b .design__item-body {
  width: 64%;
}
@media screen and (max-width: 767px) {
  .design__item--b .design__item-body {
    width: 100%;
  }
}

.design__item-texts + .design__item-texts {
  margin-top: 4.0625rem;
}
@media screen and (max-width: 767px) {
  .design__item-texts + .design__item-texts {
    margin-top: 1.875rem;
  }
}

.design__item-title {
  font-size: 1.25rem;
  letter-spacing: 0.08em;
  line-height: 1.7;
}
@media screen and (max-width: 767px) {
  .design__item-title {
    font-size: 1rem;
  }
}

.design__item-text {
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  line-height: 2.3;
  margin-top: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .design__item-text {
    font-size: 0.8125rem;
    line-height: 1.7;
    margin-top: 0.9375rem;
  }
}

@media screen and (max-width: 767px) {
  .design__item-img {
    max-width: 25rem;
    margin: 1.25rem auto 0;
  }
}

/* customer */
.customer {
  margin-top: 7.5rem;
}
@media screen and (max-width: 767px) {
  .customer {
    margin-top: 5rem;
  }
}

.customer__items {
  margin-top: 4.375rem;
}
@media screen and (max-width: 767px) {
  .customer__items {
    margin-top: 2.5rem;
  }
}

.customer__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .customer__item {
    display: block;
  }
}
.customer__item:first-child {
  flex-direction: row-reverse;
  margin-left: 6.25rem;
}
@media screen and (max-width: 767px) {
  .customer__item:first-child {
    margin-left: 0;
  }
}
.customer__item:first-child .customer__item-img {
  width: 38%;
}
@media screen and (max-width: 767px) {
  .customer__item:first-child .customer__item-img {
    width: 100%;
  }
}
.customer__item:nth-child(2) {
  margin-right: 6.875rem;
  margin-top: 5.625rem;
}
@media screen and (max-width: 767px) {
  .customer__item:nth-child(2) {
    margin-right: 0;
    margin-top: 3.75rem;
  }
}
.customer__item:nth-child(2) .customer__item-img {
  width: 29%;
}
@media screen and (max-width: 767px) {
  .customer__item:nth-child(2) .customer__item-img {
    width: 100%;
  }
}

.customer__item-body {
  width: 53%;
}
@media screen and (max-width: 767px) {
  .customer__item-body {
    width: 100%;
  }
}

.customer__item-text {
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  line-height: 2.3;
}
@media screen and (max-width: 767px) {
  .customer__item-text {
    font-size: 0.8125rem;
    line-height: 1.7;
  }
}
.customer__item-text + .customer__item-text {
  border-top: 0.0625rem solid #E3E6E6;
  padding-top: 2.125rem;
  margin-top: 2.125rem;
}
@media screen and (max-width: 767px) {
  .customer__item-text + .customer__item-text {
    padding-top: 1.25rem;
    margin-top: 1.25rem;
  }
}

@media screen and (max-width: 767px) {
  .customer__item-img {
    max-width: 25rem;
    margin: 1.25rem auto 0;
  }
}

.customer__link {
  margin: 5.9375rem auto 0;
}
@media screen and (max-width: 767px) {
  .customer__link {
    margin-top: 3.75rem;
  }
}

/* humidity */
.humidity {
  margin-top: 7.1875rem;
}
@media screen and (max-width: 767px) {
  .humidity {
    margin-top: 5rem;
  }
}

.humidity__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 62.5rem;
  max-width: 100%;
  padding-left: 3.125rem;
  margin: 4.6875rem auto 0;
}
@media screen and (max-width: 767px) {
  .humidity__inner {
    display: block;
    margin-top: 2.5rem;
    padding-left: 0;
  }
}

.humidity__img {
  width: 27.7%;
}
@media screen and (max-width: 767px) {
  .humidity__img {
    width: 100%;
    max-width: 25rem;
    margin: 1.875rem auto 0;
  }
}

.humidity__text {
  width: 60.2%;
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  line-height: 2.3;
}
@media screen and (max-width: 767px) {
  .humidity__text {
    width: 100%;
    font-size: 0.8125rem;
    line-height: 1.7;
  }
}

.humidity__box {
  border: 0.0625rem solid #E3E6E6;
  padding: 3.75rem 3.75rem 3.4375rem;
  margin-top: 6.875rem;
}
@media screen and (max-width: 767px) {
  .humidity__box {
    padding: 1.875rem 1.25rem;
    margin-top: 5rem;
  }
}

.humidity__items {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 767px) {
  .humidity__items {
    display: block;
  }
}

.humidity__item {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .humidity__item {
    width: 100%;
  }
}
.humidity__item:first-child {
  padding-right: 3.125rem;
  border-right: 0.0625rem solid #E3E6E6;
}
@media screen and (max-width: 767px) {
  .humidity__item:first-child {
    border-right: none;
    padding-right: 0;
  }
}
.humidity__item:last-child {
  padding-left: 3.125rem;
}
@media screen and (max-width: 767px) {
  .humidity__item:last-child {
    border-top: 0.0625rem solid #E3E6E6;
    padding-left: 0;
    margin-top: 1.875rem;
    padding-top: 1.875rem;
  }
}

.humidity__item-title {
  font-size: 1.25rem;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .humidity__item-title {
    font-size: 1rem;
  }
}

.humidity__item-text {
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  line-height: 2;
  margin-top: 0.625rem;
}
@media screen and (max-width: 767px) {
  .humidity__item-text {
    font-size: 0.8125rem;
    line-height: 1.7;
  }
}
.humidity__item-text:first-of-type {
  margin-top: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .humidity__item-text:first-of-type {
    margin-top: 1.25rem;
  }
}

/* iyashiro */
.iyashiro {
  margin-top: 7.1875rem;
}
@media screen and (max-width: 767px) {
  .iyashiro {
    margin-top: 5rem;
  }
}

.iyashiro__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5rem;
}
@media screen and (max-width: 767px) {
  .iyashiro__inner {
    display: block;
    margin-top: 2.5rem;
  }
}

.iyashiro__body {
  width: 56.5%;
}
@media screen and (max-width: 767px) {
  .iyashiro__body {
    width: 100%;
  }
}

.iyashiro__subtitle {
  font-size: 1.25rem;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .iyashiro__subtitle {
    font-size: 1rem;
  }
}

.iyashiro__text {
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  line-height: 2.3;
  margin-top: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .iyashiro__text {
    font-size: 0.8125rem;
    line-height: 1.7;
    margin-top: 1.25rem;
  }
}

.iyashiro__subtext {
  font-size: 0.8125rem;
  line-height: 2.7;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .iyashiro__subtext {
    line-height: 1.7;
  }
}

.iyashiro__img {
  width: 33.3%;
}
@media screen and (max-width: 767px) {
  .iyashiro__img {
    width: 100%;
    max-width: 25rem;
    margin: 1.25rem auto 0;
  }
}

/* natural */
.natural {
  margin-top: 7.1875rem;
}
@media screen and (max-width: 767px) {
  .natural {
    margin-top: 5rem;
  }
}

.natural__text {
  text-align: center;
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  line-height: 2;
  margin-top: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .natural__text {
    font-size: 0.8125rem;
    line-height: 1.7;
  }
}

.natural__items {
  display: flex;
  width: 57rem;
  max-width: 100%;
  margin: 1.5625rem auto 0;
}
@media screen and (max-width: 767px) {
  .natural__items {
    display: block;
    margin-top: 2.5rem;
  }
}

.natural__item {
  width: calc(50% - 3.75rem * 1 / 2);
}
@media screen and (max-width: 767px) {
  .natural__item {
    width: 100%;
  }
  .natural__item + .natural__item {
    margin-top: 2.5rem;
  }
}
.natural__item:not(:nth-child(2n+1)) {
  margin-left: 3.75rem;
}
@media screen and (max-width: 767px) {
  .natural__item:not(:nth-child(2n+1)) {
    margin-left: 0;
  }
}

@media screen and (max-width: 767px) {
  .natural__item-img {
    max-width: 25rem;
    margin: 0 auto;
  }
}

.natural__item-title {
  text-align: center;
  font-size: 1.125rem;
  letter-spacing: 0.08em;
  margin-top: 1.125rem;
}
@media screen and (max-width: 767px) {
  .natural__item-title {
    font-size: 1rem;
  }
}

.natural__item-text {
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  line-height: 2;
  margin-top: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .natural__item-text {
    font-size: 0.8125rem;
    line-height: 1.7;
  }
}

/* staff */
.staff__items {
  width: 55rem;
  max-width: 100%;
  margin: 3.125rem auto 0;
}
@media screen and (max-width: 767px) {
  .staff__items {
    margin-top: 1.875rem;
  }
}

.staff__item {
  min-height: 18.75rem;
  padding: 1.875rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .staff__item {
    padding: 0.9375rem;
  }
}
.staff__item + .staff__item {
  margin-top: 3.75rem;
}
@media screen and (max-width: 767px) {
  .staff__item + .staff__item {
    margin-top: 2.5rem;
  }
}
.staff__item:nth-child(even) .staff__item-body {
  margin-left: auto;
}

.staff__item-img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.staff__item-img img {
  height: 100%;
  object-fit: cover;
}

.staff__item-body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: inherit;
  min-height: 15.625rem;
  width: 50%;
  position: relative;
}
@media screen and (max-width: 767px) {
  .staff__item-body {
    width: 52%;
  }
}

.staff__item-header {
  display: flex;
}
@media screen and (max-width: 767px) {
  .staff__item-header {
    display: block;
  }
}

.staff__item-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(33.33333333% - 0.9375rem * 2 / 3);
  text-align: center;
  letter-spacing: 0.08em;
  font-size: 0.9375rem;
  font-weight: 700;
  color: #fff;
  background: #3F4040;
  padding: 0.5rem;
}
@media screen and (max-width: 767px) {
  .staff__item-info {
    font-size: 0.875rem;
    width: auto;
    padding: 0.3125rem;
  }
  .staff__item-info + .staff__item-info {
    margin-top: 0.3125rem;
  }
}
.staff__item-info:not(:nth-child(3n+1)) {
  margin-left: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .staff__item-info:not(:nth-child(3n+1)) {
    margin-left: 0;
  }
}

.staff__item-text {
  font-size: 0.9375rem;
  letter-spacing: 0.08em;
  margin-top: 1.25rem;
}
@media screen and (max-width: 767px) {
  .staff__item-text {
    font-size: 0.875rem;
    margin-top: 0.625rem;
  }
}

.staff__units {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3.75rem;
}

.staff__unit {
  width: 25%;
  position: relative;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .staff__unit {
    width: 50%;
  }
}
.staff__unit:hover .staff__unit-body {
  opacity: 1;
}

.staff__unit-img {
  width: 100%;
  padding-top: 75.3787878788%;
  position: relative;
}
.staff__unit-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: 0.0625rem solid #CECECE;
}

.staff__unit-body {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  transition: opacity 0.3s;
  padding: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .staff__unit-body {
    padding: 0.625rem;
  }
}

.staff__unit-text {
  font-size: 1.0625rem;
  font-weight: 700;
  color: #fff;
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .staff__unit-text {
    font-size: 0.875rem;
  }
}
.staff__unit-text + .staff__unit-text {
  margin-top: 0.375rem;
}
.staff__unit-text:last-child {
  font-size: 0.9375rem;
}
@media screen and (max-width: 767px) {
  .staff__unit-text:last-child {
    font-size: 0.8125rem;
  }
}

.staff__flex {
  display: flex;
  justify-content: space-between;
  width: 55rem;
  max-width: 100%;
  margin: 4.75rem auto 0;
}
@media screen and (max-width: 767px) {
  .staff__flex {
    display: block;
    margin-top: 3.75rem;
  }
}

.staff__flex-item {
  font-size: 0.9375rem;
  line-height: 1.8;
  letter-spacing: 0.04em;
}
.staff__flex-item:last-child {
  margin-left: 1.25rem;
}
@media screen and (max-width: 767px) {
  .staff__flex-item:last-child {
    margin-left: 0;
    margin-top: 1.875rem;
  }
}

.staff__flex-title {
  font-weight: 700;
}

.staff__banner {
  background: #F5F5F5;
  padding: 6.25rem 0;
  margin-top: 5.9375rem;
}
@media screen and (max-width: 767px) {
  .staff__banner {
    padding: 5rem 0;
    margin-top: 5rem;
  }
}

/* top-column */
.top-column {
  padding: 4.6875rem 0 4.6875rem;
}
@media screen and (max-width: 767px) {
  .top-column {
    padding: 3rem 0 3rem;
  }
}

.top-column__items {
  display: flex;
  width: 50rem;
  max-width: 100%;
  margin: 2.4375rem auto 0;
}
@media screen and (max-width: 767px) {
  .top-column__items {
    display: block;
    margin-top: 1.8125rem;
  }
}

.top-column__item {
  width: calc(50% - 2.5rem * 1 / 2);
}
@media screen and (max-width: 767px) {
  .top-column__item {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }
  .top-column__item + .top-column__item {
    margin-top: 2.5rem;
  }
}
.top-column__item:not(:nth-child(2n+1)) {
  margin-left: 2.5rem;
}
@media screen and (max-width: 767px) {
  .top-column__item:not(:nth-child(2n+1)) {
    margin-left: auto;
  }
}
.top-column__item .card__img {
  padding-top: 47.3684210526%;
}

.top-column__link {
  margin: 3.75rem auto 0;
}
@media screen and (max-width: 767px) {
  .top-column__link {
    width: 14.6875rem;
    margin-top: 2.5625rem;
    padding: 0.75rem 0.625rem;
  }
}

/* top-concept */
.top-concept {
  padding: 3.4375rem 0 3.75rem;
}
@media screen and (max-width: 767px) {
  .top-concept {
    padding: 3rem 0 3.125rem;
  }
}

.top-concept__text {
  text-align: center;
  font-size: 0.9375rem;
  letter-spacing: 0.04em;
  margin-top: 1.9375rem;
}
@media screen and (max-width: 767px) {
  .top-concept__text {
    line-height: 1.73;
  }
}

.top-concept__items {
  display: flex;
  margin-top: 2.5rem;
}
@media screen and (max-width: 767px) {
  .top-concept__items {
    display: block;
    margin-top: 2.1875rem;
  }
}

.top-concept__item {
  width: calc(33.33333333% - 0.625rem * 2 / 3);
}
@media screen and (max-width: 767px) {
  .top-concept__item {
    width: 100%;
    max-width: 25rem;
    margin: 0 auto;
  }
  .top-concept__item + .top-concept__item {
    margin-top: 3.125rem;
  }
}
.top-concept__item:not(:nth-child(3n+1)) {
  margin-left: 0.625rem;
}
@media screen and (max-width: 767px) {
  .top-concept__item:not(:nth-child(3n+1)) {
    margin-left: auto;
  }
}

.top-concept__item-img {
  width: 100%;
  padding-top: 64.7058823529%;
  position: relative;
  overflow: hidden;
}
.top-concept__item-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s;
}

.top-concept__item-title {
  text-align: center;
  font-size: 1.125rem;
  letter-spacing: 0.08em;
  margin-top: 1.5625rem;
}
@media screen and (max-width: 767px) {
  .top-concept__item-title {
    margin-top: 1.375rem;
  }
}

.top-concept__link {
  margin: 3.875rem auto 0;
}
@media screen and (max-width: 767px) {
  .top-concept__link {
    width: 18.75rem;
    padding: 0.875rem 0.625rem;
    margin-top: 3rem;
  }
}

/* top-info */
.top-info {
  background: #F5F5F5;
  padding: 2.3125rem 0 3.75rem;
}
@media screen and (max-width: 767px) {
  .top-info {
    padding: 2.25rem 0 3.125rem;
  }
}
.top-info .section-entitle {
  white-space: nowrap;
}

.top-info__schedule {
  margin-top: 2.5rem;
}
.top-info__schedule .calendar {
  border: 0.0625rem solid #707070;
}
@media screen and (max-width: 767px) {
  .top-info__schedule .calendar {
    border: none;
  }
}

/* top-menu */
.top-menu {
  background: url(../img/top-menu-bg_pc.png) no-repeat center/cover;
  padding: 0.625rem 0 2.8125rem;
}
@media screen and (max-width: 767px) {
  .top-menu {
    padding: 1.875rem 0 1.75rem;
    background: url(../img/top-menu-bg_sp.png) no-repeat center/cover;
  }
}

.top-menu__inner {
  padding: 0;
}
@media screen and (max-width: 767px) {
  .top-menu__inner {
    padding: 0 1.875rem;
  }
}

.top-menu__items {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
  .top-menu__items {
    margin-top: -1.875rem;
  }
}

.top-menu__item {
  background: #fff;
  width: calc(33.33333333% - 2.75rem * 2 / 3);
  margin-top: 2.5rem;
}
@media screen and (max-width: 1350px) {
  .top-menu__item {
    width: calc(33.33333333% - 1.25rem * 2 / 3);
    margin-top: 1.25rem;
  }
}
@media screen and (max-width: 767px) {
  .top-menu__item {
    width: calc(50% - 0.9375rem * 1 / 2);
    margin-top: 1.875rem;
  }
}
.top-menu__item:not(:nth-child(3n+1)) {
  margin-left: 2.75rem;
}
@media screen and (max-width: 1350px) {
  .top-menu__item:not(:nth-child(3n+1)) {
    margin-left: 1.25rem;
  }
}
@media screen and (max-width: 767px) {
  .top-menu__item:not(:nth-child(3n+1)) {
    margin-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .top-menu__item:not(:nth-child(2n+1)) {
    margin-left: 0.9375rem;
  }
}
@media screen and (max-width: 767px) {
  .top-menu__item:nth-child(3), .top-menu__item:nth-child(4) {
    margin-top: 1rem;
  }
}

.top-menu__item-inner {
  display: flex;
}
@media screen and (max-width: 767px) {
  .top-menu__item-inner {
    display: block;
  }
}
.top-menu__item-inner:hover {
  opacity: 1;
}
.top-menu__item-inner:hover .top-menu__item-body {
  background: #f7f7f7;
}
.top-menu__item-inner:hover img {
  transform: scale(1.2);
}

.top-menu__item-img {
  overflow: hidden;
  width: 39%;
}
@media screen and (max-width: 767px) {
  .top-menu__item-img {
    width: 100%;
  }
}
.top-menu__item-img img {
  height: 100%;
  object-fit: cover;
  transition: 0.4s;
}

.top-menu__item-body {
  width: 61%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.875rem;
  transition: background 0.3s;
}
@media screen and (max-width: 767px) {
  .top-menu__item-body {
    width: 100%;
    padding: 0.625rem;
  }
}

.top-menu__item-title {
  letter-spacing: 0.08em;
}
@media screen and (max-width: 767px) {
  .top-menu__item-title {
    font-size: 0.875rem;
  }
}
.top-menu__item-title span {
  display: block;
  font-size: 0.8125rem;
  line-height: 1.4;
  color: #7E8080;
}
@media screen and (max-width: 767px) {
  .top-menu__item-title span {
    font-size: 0.625rem;
  }
}

/* top-mv */
@media screen and (max-width: 767px) {
  .top-mv {
    margin-top: 3.75rem;
  }
}

.top-mv__slider {
  overflow: hidden;
}
@media screen and (max-width: 767px) {
  .top-mv__slider .swiper-container .swiper-wrapper .swiper-slide .slide-img {
    width: 100%;
    padding-top: 177.7777777778%;
    position: relative;
  }
}
.top-mv__slider .swiper-container .swiper-wrapper .swiper-slide .slide-img img {
  height: 100vh;
  object-fit: cover;
}
@media screen and (max-width: 767px) {
  .top-mv__slider .swiper-container .swiper-wrapper .swiper-slide .slide-img img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.top-mv__slider .swiper-slide-active .slide-img,
.top-mv__slider .swiper-slide-duplicate-active .slide-img,
.top-mv__slider .swiper-slide-prev .slide-img {
  animation: zoomUp 12s linear 0s;
  animation-fill-mode: both;
}

@keyframes zoomUp {
  0% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
/* top-news */
.top-news {
  padding: 3.4375rem 0 5rem;
}
@media screen and (max-width: 767px) {
  .top-news {
    padding: 2.875rem 0 3.125rem;
  }
}

/* top-reservation */
.top-reservation {
  margin-top: 5rem;
}
@media screen and (max-width: 767px) {
  .top-reservation {
    max-width: 25rem;
    margin: 1.875rem auto 0;
  }
}

.top-reservation__inner {
  display: flex;
}
@media screen and (max-width: 767px) {
  .top-reservation__inner {
    display: block;
  }
}

.top-reservation__body {
  width: 52%;
  background: #F5F5F5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .top-reservation__body {
    width: 100%;
    padding: 2.6875rem 1.25rem 3.125rem;
  }
}

.top-reservation__title {
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 1.37;
}
@media screen and (max-width: 767px) {
  .top-reservation__title {
    font-size: 1.5rem;
    line-height: 1.58;
  }
}

.top-reservation__link {
  margin: 1.6875rem auto 0;
}
@media screen and (max-width: 767px) {
  .top-reservation__link {
    margin-top: 2.125rem;
  }
}

.top-reservation__video-wrapper {
  width: 48%;
}
@media screen and (max-width: 767px) {
  .top-reservation__video-wrapper {
    width: 100%;
  }
}

.top-reservation__video {
  width: 100%;
  padding-top: 56%;
  position: relative;
}
.top-reservation__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* top-voice */
.top-voice {
  padding-bottom: 3.75rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .top-voice {
    padding-bottom: 2.5rem;
  }
}
.top-voice::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 19.8125rem;
  background: url(../img/top-voice-bg_pc.png) no-repeat center/cover;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .top-voice::after {
    height: 13.625rem;
    background: url(../img/top-voice-bg_sp.png) no-repeat center/cover;
  }
}

@media screen and (max-width: 767px) {
  .top-voice__inner {
    padding: 0 1.25rem;
  }
}

.top-voice__items {
  margin-top: 2.125rem;
}
@media screen and (max-width: 767px) {
  .top-voice__items {
    margin-top: 1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .top-voice__items .card__title {
    margin-top: 0.75rem;
  }
}
.top-voice__items .card__img {
  padding-top: 59.375%;
}

.top-voice__link {
  margin: 3.625rem auto 0;
}
@media screen and (max-width: 767px) {
  .top-voice__link {
    margin-top: 2.5rem;
  }
}

/* top-works */
.top-works {
  padding: 4.6875rem 0 3.75rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .top-works {
    padding: 2.875rem 0 2.5rem;
  }
}
.top-works::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 20.3125rem;
  background: #F5F5F5;
  z-index: -1;
}
@media screen and (max-width: 767px) {
  .top-works::after {
    height: 13.5rem;
  }
}

@media screen and (max-width: 767px) {
  .top-works__inner {
    padding: 0 1.25rem;
  }
}

.top-works__items {
  margin-top: 2.1875rem;
}
@media screen and (max-width: 767px) {
  .top-works__items {
    margin-top: 1.5625rem;
  }
}

.top-works__link {
  width: 17.1875rem;
  margin: 3.4375rem auto 0;
}
@media screen and (max-width: 767px) {
  .top-works__link {
    width: 14.6875rem;
    margin-top: 1.75rem;
  }
}

/* other */
.other__title {
  font-weight: 500;
}

.other__items {
  display: flex;
  align-items: flex-start;
  margin-top: 3rem;
}
@media screen and (max-width: 767px) {
  .other__items {
    display: block;
    margin-top: 2.5rem;
  }
}

.other__item {
  border: 0.0625rem solid #ccc;
  width: calc(50% - 1.25rem * 1 / 2);
}
@media screen and (max-width: 767px) {
  .other__item {
    width: 100%;
  }
  .other__item + .other__item {
    margin-top: 2.5rem;
  }
}
.other__item:not(:nth-child(2n+1)) {
  margin-left: 1.25rem;
}
@media screen and (max-width: 767px) {
  .other__item:not(:nth-child(2n+1)) {
    margin-left: auto;
  }
}

.other__item-inner {
  display: flex;
  flex-direction: row-reverse;
}
@media screen and (max-width: 767px) {
  .other__item-inner {
    display: block;
  }
}
.other__item-inner:hover {
  opacity: 1;
}
.other__item-inner:hover img {
  transform: scale(1.2);
}

.other__item-img-wrapper {
  width: 32%;
}
@media screen and (max-width: 767px) {
  .other__item-img-wrapper {
    width: 100%;
    height: 12.5rem;
  }
}

.other__item-img {
  width: 100%;
  height: 100%;
  padding-top: 91.4110429448%;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 767px) {
  .other__item-img {
    padding-top: unset;
  }
}
.other__item-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.4s;
}

.other__item-body {
  width: 68%;
  display: flex;
  align-items: center;
  padding: 1.25rem 0.625rem 1.25rem 1.25rem;
}
@media screen and (max-width: 767px) {
  .other__item-body {
    width: 100%;
    padding: 1.25rem 1.25rem;
  }
}

.other__item-text {
  font-size: 0.8125rem;
  letter-spacing: 0.08em;
  line-height: 1.77;
}
.other__item-text span {
  display: inline-block;
  color: #445F39;
  font-weight: 700;
  margin-left: 0.3125rem;
  margin-top: 0.3125rem;
}

/* voice-archive */
.voice-archive__card + .voice-archive__card {
  margin-top: 3.125rem;
}
@media screen and (max-width: 767px) {
  .voice-archive__card + .voice-archive__card {
    margin-top: 2.5rem;
  }
}

/* voice-single */
.voice-single__inner {
  padding: 0;
}

.voice-single__thumbnail {
  width: 100%;
  padding-top: 28.7962962963%;
  position: relative;
}
.voice-single__thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.voice-single__header {
  border-top: 0.1875rem solid #445F39;
  background: #F5F5F2;
  margin-top: 6.25rem;
  padding: 1.25rem 0 1.5625rem;
}
@media screen and (max-width: 767px) {
  .voice-single__header {
    padding: 1.25rem 1.25rem;
    margin-top: 3.75rem;
  }
}

.voice-single__list {
  display: flex;
}
@media screen and (max-width: 767px) {
  .voice-single__list {
    display: block;
  }
}

.voice-single__list-item {
  width: 50%;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .voice-single__list-item {
    width: 100%;
  }
  .voice-single__list-item:not(:first-child) {
    margin-top: 0.9375rem;
  }
}

.voice-single__list-item-title {
  font-weight: 700;
}

.voice-single__list-item-text {
  margin-left: 3.125rem;
}
@media screen and (max-width: 767px) {
  .voice-single__list-item-text {
    margin-left: 2.5rem;
  }
}

.voice-single__items {
  margin-top: 6.25rem;
}
@media screen and (max-width: 767px) {
  .voice-single__items {
    margin-top: 3.75rem;
  }
}

.voice-single__item {
  border: 0.0625rem solid #ccc;
  padding: 2.8125rem 4.375rem 2.8125rem;
}
@media screen and (max-width: 767px) {
  .voice-single__item {
    padding: 1.25rem 1.25rem;
  }
}
.voice-single__item + .voice-single__item {
  margin-top: 3.125rem;
}
@media screen and (max-width: 767px) {
  .voice-single__item + .voice-single__item {
    margin-top: 2.5rem;
  }
}

.voice-single__item-title {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.7;
  color: #445F39;
  border-bottom: 0.0625rem dashed #ccc;
  padding-bottom: 0.9375rem;
  margin-bottom: 1.25rem;
}
@media screen and (max-width: 767px) {
  .voice-single__item-title {
    font-size: 0.9375rem;
    font-weight: 500;
  }
}
.voice-single__item-title span {
  display: inline-block;
  margin-left: 0.3125rem;
  margin-right: 0.625rem;
}

.voice-single__item-text {
  font-size: 0.9375rem;
  letter-spacing: 0.04em;
  line-height: 1.8;
}

.voice-single__item-flex {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .voice-single__item-flex {
    display: block;
  }
}
.voice-single__item-flex .voice-single__item-text {
  width: 66%;
  margin-top: -0.4375rem;
}
@media screen and (max-width: 767px) {
  .voice-single__item-flex .voice-single__item-text {
    width: 100%;
    margin-top: 0.9375rem;
  }
}

.voice-single__item-img-wrapper {
  width: 31%;
}
@media screen and (max-width: 767px) {
  .voice-single__item-img-wrapper {
    width: 18.75rem;
    max-width: 100%;
    margin: 0 auto;
  }
}

.voice-single__item-img {
  width: 100%;
  padding-top: 100%;
  position: relative;
}
.voice-single__item-img img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}