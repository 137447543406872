@use "global" as *;

/* cards */
.cards {
  display: flex;
  @include mq(sm) {
    overflow-x: auto;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
    flex-wrap: nowrap;
    margin-right: calc(50% - 50vw);
  }
}

.cards__item {
  width: calc(33.33333333% - #{rem(40)} * 2 / 3);
  @include mq(sm) {
    width: rem(190);
    min-width: rem(190);
    width: 54%;
    min-width: 54%;
    position: relative;
    &:last-child {
      &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 100%;
        height: rem(1);
        width: rem(20);
        background-color: transparent;
      }
    }
  }
  &:not(:nth-child(3n + 1)) {
    margin-left: rem(40);
    @include mq(sm) {
      margin-left: rem(20);
    }
  }
}