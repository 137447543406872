@use "global" as *;

/* form */
.form {
  .mw_wp_form .horizontal-item + .horizontal-item {
    margin-left: 0;
  }
}

.form__content {

}

.form__item {
  display: flex;
  justify-content: space-between;
  @include mq(sm) {
    display: block;
  }
  & + & {
    margin-top: rem(35);
    @include mq(sm) {
      margin-top: rem(25);
    }
  }
}

.form__item--block {
  display: block;
  &.form__item {
    .form__item-label {
      width: 100%;
    }
    .form__item-input {
      width: 100%;
      margin-top: rem(15);
    }
  }
}

.form__item-label {
  width: 40%;
  @include mq(sm) {
    width: 100%;
  }
  label {
    font-size: rem(15);
    letter-spacing: 0.08em;
    font-weight: 700;
    span {
      display: block;
      font-size: rem(10);
      font-weight: 500;
      letter-spacing: 0.12em;
    }
  }
  > span {
    display: inline-block;
    font-size: rem(12);
    letter-spacing: 0.12em;
    color: #fff;
    background: $main-color;
    padding: rem(2) rem(10);
    margin-left: rem(6);
  }
}

.form__item-label-inner {
  > span {
    display: inline-block;
    font-size: rem(12);
    letter-spacing: 0.12em;
    color: #fff;
    background: $main-color;
    padding: rem(2) rem(10);
    margin-left: rem(6);
  }
}

.form__item-label-text {
  font-size: rem(12);
  letter-spacing: 0.08em;
  margin-top: rem(5);
}

.form__item-input {
  width: 58.5%;
  @include mq(sm) {
    width: 100%;
    margin-top: rem(10);
  }
}

.form__item-input--short {
  input {
    width: rem(100);
  }
}

input, textarea {
  border: rem(1) solid #ccc;
  width: 100%;
  padding: 0 rem(10);
}

input {
  height: rem(25);
  @include mq(sm) {
    height: rem(40);
  }
}

textarea {
  height: rem(59);
  resize: none;
  @include mq(sm) {
    height: rem(80);
  }
}

// セレクトボックス
select {
  border: rem(1) solid #ccc;
  width: rem(100);
  height: rem(25);
  padding: 0 rem(15) 0 rem(12);
  color: $font-color;
  @include mq(sm) {
    height: rem(40);
  }
}

.form__select-wrapper {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    left: rem(85);
    top: rem(10);
    border-right: rem(4) solid transparent;
    border-top: rem(6) solid #ccc;
    border-left: rem(4) solid transparent;
    @include mq(sm) {
      top: rem(18);
    }
  }
}

// ラジオボタン
.form__radio {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: rem(-5);
  > span {
    margin-right: rem(65);
    margin-bottom: rem(5);
  }
  input[type="radio"]+span {
    cursor: pointer;
    font-size: rem(14);
    letter-spacing: 0.08em;
    display: inline-block;
    padding-left: rem(26);
    position: relative;
    &::before,
    &::after {
      position: absolute;
      content: "";
    }
    &::before {
      display: block;
      background: #fff;
      border-radius: 50%;
      border: rem(1) solid #ccc;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: rem(16);
      height: rem(16);
    }
    &::after {
      height: rem(10);
      width: rem(10);
      background: $main-color;
      border-radius: 50%;
      opacity: 0;
      transform: rotate(-45deg);
      transition: all 0.3s ease 0s;
      left: rem(3);
      top: rem(6);
    }
  }

  input[type="radio"]:checked+span::after {
    opacity: 1;
  }
}

// チェックボックス
.form__check {
  margin-bottom: rem(-10);
  display: flex;
  flex-wrap: wrap;
  > span {
    margin-right: rem(60);
    margin-bottom: rem(10);
  }
  input[type="checkbox"]+span {
    cursor: pointer;
    font-size: rem(15);
    letter-spacing: 0.08em;
    display: inline-block;
    padding-left: rem(26);
    position: relative;
    &::before,
    &::after {
      position: absolute;
      content: "";
    }
    &::before {
      display: block;
      background: #fff;
      border: rem(1) solid #ccc;
      top: rem(5);
      left: 0;
      width: rem(14);
      height: rem(14);
    }
    &::after {
      top: rem(7);
      left: rem(3);
      width: rem(9);
      height: rem(7);
      border-left: rem(2) solid $main-color;
      border-bottom: rem(2) solid $main-color;
      transform: rotate(-45deg);
      opacity: 0;
    }
  }

  input[type="checkbox"]:checked+span::after {
    opacity: 1;
  }
}

// 希望日
.form__item--day {
  .form__selects {
    display: flex;
    align-items: center;
  }
  .form__select-wrapper {
    margin-right: rem(7);
    &:last-of-type {
      margin-left: rem(30);
    }
    &::after {
      left: rem(48);
    }
  }
  select {
    width: rem(64);
  }
}

// ご来場日
.form__item--date {
  &.form__item {
    .form__item-input {
      width: 73%;
      @include mq(sm) {
        width: 100%;
      }
    }
  }
}

// ご希望の連絡方法
.form__item--method {
  .form__check {
    flex-wrap: nowrap;
    @include mq(sm) {
      flex-wrap: wrap;
    }
    > span {
      margin-right: rem(20);
    }
  }
}

// 連絡可能時間帯
.form__item--time {
  .form__check {
   > span {
      margin-right: 0;
      min-width: rem(175);
    }
  }
}

// 当社を知ったきっかけをお聞かせください
.form__item--chance {
  .form__check {
    > span {
      width: 35%;
      margin-right: 0;
      @include mq(sm) {
        width: 100%;
      }
      &:nth-child(3n + 1) {
        width: 30%;
        @include mq(sm) {
          width: 100%;
        }
      }
      &:last-of-type {
        margin-top: rem(10);
        @include mq(sm) {
          margin-top: 0;
        }
      }
    }
  }
  .form__check input[type=checkbox] + span {
    padding-left: rem(20);
  }
  .form__item-input {
    textarea {
      margin-left: auto;
      width: 66%;
      margin-top: rem(10);
      @include mq(sm) {
        width: 100%;
        margin-top: 0;
      }
    }
  }
}

// ご質問・ご要望など
.form__item--request {
  &.form__item {
    margin-top: rem(40);
  }
  textarea {
    height: rem(76);
  }
}

// 生年月日
.form__item--birth {
  .form__item-input {
    display: flex;
    align-items: center;
    font-size: rem(14);
    letter-spacing: 0.08em;
    input {
      &:first-of-type {
        width: rem(64);
        margin: 0 rem(8);
      }
      &:nth-of-type(2), &:last-of-type {
        width: rem(34);
        margin: 0 rem(8) 0 rem(25);
        @include mq(sm) {
          margin: 0 rem(8) 0 rem(10);
        }
      }
    }
  }
}

// 郵便番号
.form__item--post {
  input {
    width: rem(166);
    max-width: 100%;
  }
}

// 見学希望日
.form__item--hope {
  &.form__item {
    .form__item-label {
      width: 20%;
      @include mq(sm) {
        width: 100%;
      }
    }
    .form__item-input {
      width: 80%;
      @include mq(sm) {
        width: 100%;
      }
    }
  }
  .form__item-input-inner {
    display: flex;
  }
  .form__item-input {
    li {
      display: flex;
      justify-content: flex-end;
      @include mq(sm) {
        display: block;
      }
      &:not(:first-child) {
        margin-top: rem(20);
      }
    }
    span {
      display: inline-block;
      font-size: rem(14);
      letter-spacing: 0.08em;
    }
    .form__selects {
      display: flex;
      margin-left: rem(8);
      @include mq(sm) {
        align-items: center;
        margin-left: 0;
        margin-top: rem(10);
      }
    }
    .form__select-wrapper {
      margin-right: rem(8);
      &:last-of-type {
        margin-left: rem(20);
        @include mq(sm) {
          margin-left: rem(13);
        }
      }
      &::after {
        left: rem(48);
      }
    }
    select {
      width: rem(64);
    }
    .form__radio {
      @include mq(sm) {
        display: block;
        margin-top: rem(10);
      }
      > span {
        margin-left: rem(24);
        margin-right: 0;
        margin-bottom: 0;
        @include mq(sm) {
          display: block;
          margin-left: rem(13);
        }
        &:not(:first-child) {
          margin-left: rem(24);
          @include mq(sm) {
            margin-left: rem(13);
          }
        }
      }
      input[type="radio"]+span {
        padding-left: rem(22);
        &::before {
          border-radius: unset;
          width: rem(14);
          height: rem(14);
        }
        &::after {
          top: 45%;
          left: rem(5);
          width: rem(9);
          height: rem(7);
          border-left: rem(2) solid $main-color;
          border-bottom: rem(2) solid $main-color;
          transform: rotate(-45deg) translateY(-50%);
          background: none;
          border-radius: unset;
        }
      }
    }
  }
}

// 自己PR、またはご質問
.form__item--pr {
  textarea {
    height: rem(121);
  }
}

// 履歴書・職務経歴書添付
.form__item--resume {
  .form__item-input {
    input[type="file"] {
      display: none;
    }
    label {
      display: inline-block;
      text-align: center;
      font-size: rem(15);
      letter-spacing: 0.08em;
      background: #F5F5F2;
      border: rem(1) solid #ccc;
      padding: rem(10) rem(50);
      transition: opacity .3s;
      &:hover {
        opacity: 0.7;
      }
    }
    > span {
      display: inline-block;
      font-size: rem(14);
      letter-spacing: 0.08em;
      margin-left: rem(20);
      @include mq(sm) {
        margin-left: 0;
        margin-top: rem(10);
      }
    }
    .mwform-file-delete {
      display: none;
    }
  }
}

// プライバシーポリシー
.form__privacy {
  text-align: center;
  margin-top: rem(30);
  span {
    display: inline-block;
    font-size: rem(13);
    letter-spacing: 0.08em;
  }
  label {
    display: block;
  }
  a {
    text-decoration: underline;
  }
}

.form__privacy-box+span {
  cursor: pointer;
  display: inline-block;
  padding-left: rem(33);
  position: relative;

  &::before,
  &::after {
    position: absolute;
    content: "";
    display: block;
    top: 50%;
  }

  &::before {
    border: rem(1) solid #ccc;
    width: rem(14);
    height: rem(14);
    left: rem(18);
    top: rem(-12);
  }

  &::after {
    border-bottom: rem(2) solid $main-color;
    border-left: rem(2) solid $main-color;
    height: rem(6);
    width: rem(10);
    opacity: 0;
    transform: rotate(-45deg);
    transition: all 0.3s ease 0s;
    left: rem(20);
    top: rem(-9);
  }
}

.form__privacy-box:checked+span::after {
  opacity: 1;
}

.mw_wp_form input[name="__children[privacypolicy][]"] + .error {
	display : inline;
	padding-right:.5em;
}

// 送信ボタン
.form__btn {
  margin: rem(20) auto 0;
  @include mq(sm) {
    margin-top: rem(40);
  }
}