@use "global" as *;

/* sdgs */
.sdgs {

}

.sdgs__content {
  padding: rem(25) 0 rem(100);
  @include mq(sm) {
    padding-top: rem(10);
  }
}

.sdgs__logo {
  width: rem(458);
  max-width: 100%;
  margin: 0 auto;
}

.sdgs__text {
  text-align: center;
  font-size: rem(17);
  letter-spacing: 0.08em;
  line-height: 1.6;
  margin-top: rem(37);
  @include mq(sm) {
    text-align: left;
    font-size: rem(15);
  }
}

.sdgs__subtext {
  display: flex;
  justify-content: flex-end;
  font-size: rem(15);
  line-height: 2;
  letter-spacing: 0.08em;
  margin-top: rem(35);
  @include mq(sm) {
    font-size: rem(13);
    margin-top: rem(25);
  }
}

.sdgs__heading {
  color: $font-color;
  margin-top: rem(35);
  @include mq(sm) {
    margin-top: rem(60);
  }
}

.sdgs__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(-20);
  @include mq(sm) {
    display: block;
    margin-top: rem(40);
  }
}

.sdgs__item {
  display: flex;
  flex-direction: column;
  width: calc(50% - #{rem(75)} * 1 / 2);
  margin-top: rem(60);
  @include mq(sm) {
    width: 100%;
    margin-top: 0;
    & + & {
      margin-top: rem(60);
    }
  }
  &:not(:nth-child(2n + 1)) {
    margin-left: rem(75);
    @include mq(sm) {
      margin-left: 0;
    }
  }
}

.sdgs__item-title {
  font-size: rem(18);
  font-weight: 700;
  letter-spacing: 0.08em;
}

.sdgs__item-subtitle {
  font-size: rem(18);
  line-height: 1.67;
  letter-spacing: 0.08em;
  border-top: rem(1) solid #ccc;
  padding-top: rem(23);
  margin-top: rem(18);
  min-height: rem(115);
  @include mq(sm) {
    font-size: rem(15);
    min-height: unset;
    padding-top: rem(10);
    margin-top: rem(10);
  }
}

.sdgs__item-text {
  font-size: rem(15);
  line-height: 1.8;
  letter-spacing: 0.08em;
  margin-top: rem(25);
  @include mq(sm) {
    font-size: rem(14);
  }
}

.sdgs__item-list {
  font-size: rem(15);
  line-height: 1.8;
  letter-spacing: 0.08em;
  margin-bottom: rem(25);
  @include mq(sm) {
    font-size: rem(14);
  }
}

.sdgs__item-list-item {
  padding-left: 1em;
  text-indent: -1em;
}

.sdgs__item-imgs {
  display: flex;
  width: rem(435);
  max-width: 100%;
  margin: auto auto 0;
}

.sdgs__item-img {
  width: calc(25% - #{rem(11)} * 3 / 4);
  &:not(:nth-child(4n + 1)) {
    margin-left: rem(11);
  }
}

.sdgs__link {
  margin: rem(90) auto 0;
  @include mq(sm) {
    margin-top: rem(60);
  }
}