@use "global" as *;

/* other */
.other {
  
}

.other__title {
  font-weight: 500;
}

.other__items {
  display: flex;
  align-items: flex-start;
  margin-top: rem(48);
  @include mq(sm) {
    display: block;
    margin-top: rem(40);
  }
}

.other__item {
  border: rem(1) solid #ccc;
  width: calc(50% - #{rem(20)} * 1 / 2);
  @include mq(sm) {
    width: 100%;
    & + & {
      margin-top: rem(40);
    }
  }
  &:not(:nth-child(2n + 1)) {
    margin-left: rem(20);
    @include mq(sm) {
      margin-left: auto;
    }
  }
}

.other__item-inner {
  display: flex;
  flex-direction: row-reverse;
  @include mq(sm) {
    display: block;
  }
  &:hover {
    opacity: 1;
    img {
      transform: scale(1.2);
    }
  }
}

.other__item-img-wrapper {
  width: 32%;
  @include mq(sm) {
    width: 100%;
    height: rem(200);
  }
}

.other__item-img {
  width: 100%;
  height: 100%;
  padding-top: calc(149 / 163 * 100%);
  overflow: hidden;
  position: relative;
  @include mq(sm) {
    padding-top: unset;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .4s;
  }
}

.other__item-body {
  width: 68%;
  display: flex;
  align-items: center;
  padding: rem(20) rem(10) rem(20) rem(20);
  @include mq(sm) {
    width: 100%;
    padding: rem(20) rem(20);
  }
}

.other__item-text {
  font-size: rem(13);
  letter-spacing: 0.08em;
  line-height: 1.77;
  span {
    display: inline-block;
    color: $main-color;
    font-weight: 700;
    margin-left: rem(5);
    margin-top: rem(5);
  }
}