@use "global" as *;

/* natural */
.natural {
  margin-top: rem(115);
  @include mq(sm) {
    margin-top: rem(80);
  }
}

.natural__text {
  text-align: center;
  font-size: rem(15);
  letter-spacing: 0.08em;
  line-height: 2;
  margin-top: rem(35);
  @include mq(sm) {
    font-size: rem(13);
    line-height: 1.7;
  }
}

.natural__items {
  display: flex;
  width: rem(912);
  max-width: 100%;
  margin: rem(25) auto 0;
  @include mq(sm) {
    display: block;
    margin-top: rem(40);
  }
}

.natural__item {
  width: calc(50% - #{rem(60)} * 1 / 2);
  @include mq(sm) {
    width: 100%;
    & + & {
      margin-top: rem(40);
    }
  }
  &:not(:nth-child(2n + 1)) {
    margin-left: rem(60);
    @include mq(sm) {
      margin-left: 0;
    }
  }
}

.natural__item-img {
  @include mq(sm) {
    max-width: rem(400);
    margin: 0 auto;
  }
}

.natural__item-title {
  text-align: center;
  font-size: rem(18);
  letter-spacing: 0.08em;
  margin-top: rem(18);
  @include mq(sm) {
    font-size: rem(16);
  }
}

.natural__item-text {
  font-size: rem(15);
  letter-spacing: 0.08em;
  line-height: 2;
  margin-top: rem(15);
  @include mq(sm) {
    font-size: rem(13);
    line-height: 1.7;
  }
}