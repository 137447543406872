@use "global" as *;

/* plan */
.plan {
  margin-top: rem(92);
}

.plan__subtitle {
  text-align: center;
  font-size: rem(20);
  letter-spacing: 0.08em;
  line-height: 1.7;
  @include mq(sm) {
    font-size: rem(18);
  }
}

.plan__title {
  text-align: center;
  color: $main-color;
  font-size: rem(18);
  font-weight: 700;
  letter-spacing: 0.08em;
  margin-top: rem(6);
  @include mq(sm) {
    font-size: rem(16);
  }
  span {
    display: inline-block;
    font-size: rem(28);
    margin-left: rem(5);
    @include mq(sm) {
      display: block;
      font-size: rem(24);
    }
  }
}

.plan__items {
  display: flex;
  margin-top: rem(30);
  @include mq(sm) {
    display: block;
  }
}

.plan__item {
  text-align: center;
  border: rem(1) solid #707070;
  border-top: rem(8) solid $main-color;
  width: calc(33.33333333% - #{rem(42)} * 2 / 3);
  padding: rem(35) rem(35);
  @include mq(lg) {
    width: calc(33.33333333% - #{rem(20)} * 2 / 3);
    padding: rem(35) rem(15);
  }
  @include mq(sm) {
    width: 100%;
    padding: rem(30) rem(20);
    & + & {
      margin-top: rem(20);
    }
  }
  &:not(:nth-child(3n + 1)) {
    margin-left: rem(42);
    @include mq(lg) {
      margin-left: rem(20);
    }
    @include mq(sm) {
      margin-left: auto;
    }
  }
}

.plan__item-subtitle {
  font-size: rem(13);
  letter-spacing: 0.08em;
}

.plan__item-title {
  font-size: rem(20);
  letter-spacing: 0.08em;
  font-weight: 700;
  margin-top: rem(10);
}

.plan__item-text {
  font-size: rem(15);
  letter-spacing: 0.08em;
  border-top: rem(1) dashed #ccc;
  padding-top: rem(10);
  margin-top: rem(10);
}

.plan__item-price {
  letter-spacing: 0.08em;
  margin-top: rem(10);
  span {
    &:first-child {
      font-size: rem(23);
      font-weight: 700;
    }
    &:nth-child(2) {
      margin-left: rem(3);
    }
    &:last-child {
      font-size: rem(14);
      margin-left: rem(3);
    }
  }
}

.plan__item-units {
  display: flex;
  justify-content: space-between;
  max-width: rem(250);
  margin: 0 auto;
}

.plan__item-unit {
  text-align: left;
  margin-top: rem(15);
}

.plan__item-unit-title {
  text-align: center;
  display: inline-block;
  font-size: rem(14);
  color: $main-color;
  letter-spacing: 0.08em;
  background: #F5F5F2;
  min-width: rem(98);
  padding: rem(2) rem(2);
}

.plan__item-list {
  margin-top: rem(5);
}

.plan__item-list-item {
  font-size: rem(13);
  letter-spacing: 0.08em;
  line-height: 1.77;
}

.plan__boxes {
  display: flex;
  margin-top: rem(60);
  @include mq(sm) {
    display: block;
    margin-top: rem(40);
  }
}

.plan__box {
  width: calc(25% - #{rem(22)} * 3 / 4);
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: 0 auto;
    & + & {
      margin-top: rem(30);
    }
  }
  &:not(:nth-child(4n + 1)) {
    margin-left: rem(22);
    @include mq(sm) {
      margin-left: auto;
    }
  }
}

.plan__box-img {

}

.plan__box-text {
  letter-spacing: 0.08em;
  margin-top: rem(5);
  @include mq(sm) {
    font-size: rem(15);
  }
}