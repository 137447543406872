@use "global" as *;

/* reform */
.reform {
  
}

.reform__content {

}

.reform__subtitle {
  text-align: center;
  font-size: rem(20);
  letter-spacing: 0.08em;
  line-height: 1.7;
  @include mq(sm) {
    font-size: rem(18);
  }
}

.reform__text {
  text-align: center;
  font-size: rem(15);
  letter-spacing: 0.04em;
  line-height: 1.87;
  margin-top: rem(24);
  @include mq(sm) {
    text-align: left;
    font-size: rem(14);
  }
}