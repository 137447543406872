@use "global"as *;

/* akiya */
.akiya {
  
}

.akiya__subtitle {
  text-align: center;
  font-size: rem(22);
  letter-spacing: 0.08em;
  line-height: 1.68;
  margin-top: rem(-6);
  @include mq(sm) {
    font-size: rem(18);
    margin-top: 0;
  }
}

.akiya__text {
  text-align: center;
  font-size: rem(14);
  line-height: 1.71;
  margin-top: rem(50);
  @include mq(sm) {
    text-align: left;
    margin-top: rem(40);
  }
}