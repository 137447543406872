@use "global" as *;

/* menu */
.menu {

}

.menu__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(-10);
}

.menu__item {
  text-align: center;
  font-size: rem(15);
  width: calc(33.33333333% - #{rem(10)} * 2 / 3);
  margin-top: rem(10);
  @include mq(sm) {
    width: calc(50% - #{rem(10)} * 1 / 2);
  }
  &:not(:nth-child(3n + 1)) {
    margin-left: rem(10);
    @include mq(sm) {
      margin-left: 0;
    }
  }
  &:not(:nth-child(2n + 1)) {
    @include mq(sm) {
      margin-left: rem(10);
    }
  }
  span, a {
    display: block;
    border: rem(1) solid #3F4040;
    padding: rem(14) rem(5);
    @include mq(sm) {
      font-size: rem(14);
    }
  }
  &.current {
    background: #fff;
  }
}