@use "global" as *;

/* access */
.access {

}

.access__map-wrapper {
  margin-top: rem(35);
  @include mq(sm) {
    margin-top: rem(25);
  }
}

.access__map {
  width: 100%;
  padding-top: calc(439 / 1040 * 100%);
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}