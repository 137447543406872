@use "global" as *;

/* voice */
.voice {
  
}

.voice__cards {
  margin-top: rem(35);
}

.voice__card {
  & + & {
    margin-top: rem(50);
    @include mq(sm) {
      margin-top: rem(40);
    }
  }
}

.voice__link {
  margin: rem(60) auto 0;
  @include mq(sm) {
    margin-top: rem(40);
  }
}