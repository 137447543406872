@use "global" as *;

/* healthy */
.healthy {
  margin: rem(115) auto 0;
  @include mq(sm) {
    margin-top: rem(80);
  }
}

.healthy__items {
  margin-top: rem(80);
  @include mq(sm) {
    margin-top: rem(40);
  }
}

.healthy__item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(sm) {
    display: block;
  }
  & + & {
    margin-top: rem(100);
    @include mq(sm) {
      margin-top: rem(40);
    }
  }
  &:nth-child(even) {
    flex-direction: row-reverse;
  }
  &:first-child {
    width: rem(1020);
    max-width: 100%;
    margin: 0 auto;
    .healthy__item-body {
      width: 54%;
      @include mq(sm) {
        width: 100%;
      }
    }
    .healthy__item-img {
      width: 40%;
      @include mq(sm) {
        width: 100%;
      }
    }
  }
  &:nth-child(2) {
    width: rem(955);
    max-width: 100%;
    margin: rem(100) auto 0;
    @include mq(sm) {
      margin-top: rem(40);
    }
    .healthy__item-body {
      width: 58%;
      @include mq(sm) {
        width: 100%;
      }
    }
    .healthy__item-img {
      width: 31%;
      @include mq(sm) {
        width: 100%;
      }
    }
  }
  &:nth-child(3) {
    width: rem(988);
    max-width: 100%;
    margin: rem(100) auto 0;
    @include mq(sm) {
      margin-top: rem(40);
    }
    .healthy__item-body {
      width: 53%;
      @include mq(sm) {
        width: 100%;
      }
    }
    .healthy__item-img {
      width: 41%;
      @include mq(sm) {
        width: 100%;
      }
    }
  }
  &:last-child {
    width: rem(1007);
    max-width: 100%;
    margin: rem(100) auto 0;
    @include mq(sm) {
      margin-top: rem(60);
    }
    .healthy__item-body {
      width: 62%;
      @include mq(sm) {
        width: 100%;
      }
    }
    .healthy__item-img {
      width: 33%;
      @include mq(sm) {
        width: 100%;
      }
    }
  }
}

.healthy__item-body {

}

.healthy__item-title {
  font-size: rem(20);
  letter-spacing: 0.06em;
  @include mq(sm) {
    font-size: rem(15);
  }
}

.healthy__item-text {
  font-size: rem(15);
  letter-spacing: 0.08em;
  line-height: 2.3;
  margin-top: rem(20);
  @include mq(sm) {
    font-size: rem(13);
    line-height: 1.7;
    margin-top: rem(15);
  }
}

.healthy__item-img {
  @include mq(sm) {
    max-width: rem(400);
    margin: rem(20) auto 0;
  }
}

.healthy__box {
  border: rem(1) solid #E3E6E6;
  margin-top: rem(137);
  padding: rem(60) rem(40) rem(55);
  @include mq(sm) {
    margin-top: rem(80);
    padding: rem(30) rem(20);
  }
}

.healthy__imgs {
  display: flex;
  @include mq(sm) {
    display: block;
  }
}

.healthy__img {
  width: calc(50% - #{rem(109)} * 1 / 2);
  @include mq(sm) {
    width: rem(300);
    max-width: 100%;
    margin: 0 auto;
    & + & {
      margin-top: rem(20);
    }
  }
  &:not(:nth-child(2n + 1)) {
    margin-left: rem(109);
    @include mq(sm) {
      margin-left: auto;
    }
  }
}

.healthy__subtitle {
  text-align: center;
  font-size: rem(20);
  letter-spacing: 0.08em;
  margin-top: rem(55);
  @include mq(sm) {
    font-size: rem(15);
    margin-top: rem(40);
  }
}

.healthy__text {
  text-align: center;
  font-size: rem(15);
  letter-spacing: 0.08em;
  line-height: 2;
  margin-top: rem(15);
  @include mq(sm) {
    font-size: rem(13);
    line-height: 1.7;
  }
}