@use "global" as *;

/* fnav */
.fnav {
  display: flex;
  @include mq(sm) {
    text-align: left;
    display: block;
    border-top: rem(1) solid #999;
  }
}

.fnav__items {
  @include mq(sm) {
    border-bottom: rem(1) solid #999;
    padding: rem(12) 0;
  }
  & + & {
    margin-left: rem(62);
    @include mq(lg) {
      margin-left: rem(40);
    }
    @include mq(sm) {
      margin-left: 0;
    }
  }
}

.fnav__item {
  font-size: rem(14);
  font-weight: 500;
  letter-spacing: 0.08em;
  position: relative;
  &::before, &::after {
    position: absolute;
    @include mq(sm) {
      width: rem(15);
      height: rem(1);
      background: #999;
      right: rem(10);
    }
  }
  &::before {
    content: "-";
    left: -1em;
    top: .2em;
    @include mq(sm) {
      content: "";
      left: unset;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &::after {
    @include mq(sm) {
      content: "";
      top: 48%;
      transform: rotate(90deg);
      transition: .2s;
    }
  }
  &.opened {
    &::after {
      @include mq(sm) {
        transform: rotate(0deg);
      }
    }
  }
  a, span {
    display: inline-block;
    padding: rem(5) 0;
  }
}

.fnav__subitem-wrapper {
  @include mq(sm) {
    display: none;
  }
}

.fnav__subitems {
  margin-top: rem(12);
  @include mq(sm) {
    margin-top: rem(5);
  }
}

.fnav__subitem {
  font-size: rem(14);
  letter-spacing: 0.08em;
  color: #666;
  a {
    display: block;
    padding: rem(5) 0;
  }
}