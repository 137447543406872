@use "global" as *;

/* venue */
.venue {

}

.venue__items {
  display: flex;
  margin-top: rem(35);
  @include mq(sm) {
    display: block;
  }
}

.venue__item {
  width: calc(50% - #{rem(36)} * 1 / 2);
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: 0 auto;
    &:last-child {
      margin-top: rem(40);
    }
  }
  &:not(:nth-child(2n + 1)) {
    margin-left: rem(36);
    @include mq(sm) {
      margin-left: auto;
    }
  }
}

.venue__item-map {
  width: 100%;
  padding-top: calc(344 / 422 * 100%);
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.venue__item-title {
  letter-spacing: 0.08em;
  margin-top: rem(15);
}

.venue__item-text {
  font-size: rem(14);
  letter-spacing: 0.08em;
  line-height: 1.93;
  margin-top: rem(3);
}