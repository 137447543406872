@use "global" as *;

/* link-a */
.link-a {
  display: block;
  text-align: center;
  width: rem(300);
  max-width: 100%;
  padding: rem(13) 0;
  position: relative;
  border: rem(1) solid $font-color;
  color: $font-color;
  transition: color .3s, background .3s;
  @include mq(sm) {
    margin: 0 auto;
  }
  &::after {
    position: absolute;
    content: "";
    width: rem(9);
    height: rem(9);
    right: rem(23);
    top: 50%;
    transform: translateY(-50%);
    background: url(../img/arrow-icon02.png) no-repeat center / contain;
    transition: background .3s;
  }
  &:hover {
    background: $font-color;
    opacity: 1;
    color: #fff;
    &::after {
      background-image: url(../img/arrow-icon01.png);
    }
  }
}