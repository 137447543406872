@use "global" as *;

/* risk */
.risk {
  margin-top: rem(77);
}

.risk__title {
  text-align: center;
  font-size: rem(22);
  letter-spacing: 0.08em;
  line-height: 1.68;
  @include mq(sm) {
    font-size: rem(20);
  }
}

.risk__items {
  display: flex;
  margin-top: rem(46);
  @include mq(sm) {
    display: block;
  }
}

.risk__item {
  width: calc(33.33333333% - #{rem(50)} * 2 / 3);
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: 0 auto;
    & + & {
      margin-top: rem(40);
    }
  }
  &:not(:nth-child(3n + 1)) {
    margin-left: rem(50);
    @include mq(sm) {
      margin-left: auto;
    }
  }
}

.risk__item-img {

}

.risk__item-text {
  font-size: rem(14);
  line-height: 1.71;
  margin-top: rem(5);
}