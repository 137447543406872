@use "global" as *;

/* column-single */
.column-single {
  
}

.column-single__wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: rem(80);
  @include mq(sm) {
    display: block;
    margin-top: rem(60);
  }
}

.column-single__content {
  width: 69%;
  @include mq(sm) {
    width: 100%;
  }
}

.column-single__header {
  display: flex;
  @include mq(sm) {
    display: block;
  }
}

.column-single__icon-wrapper {
  width: rem(69);
  @include mq(sm) {
    width: rem(80);
    margin: 0 auto;
  }
}

.column-single__icon {
  width: 100%;
  padding-top: calc(69 / 69 * 100%);
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.column-single__info {
  margin-left: rem(17);
  margin-top: rem(5);
  @include mq(sm) {
    margin-left: 0;
    margin-top: rem(20);
  }
}

.column-single__category {
  margin-top: rem(30);
}

.column-single__title {
  font-size: rem(26);
  letter-spacing: 0.12em;
  line-height: 1.3;
  font-weight: 700;
  margin-top: rem(25);
  @include mq(sm) {
    font-size: rem(22);
  }
}

.column-single__thumbnail {
  margin-top: rem(30);
  @include mq(sm) {
    max-width: rem(400);
    margin: rem(20) auto 0;
  }
}

.column-single__lead {
  font-size: rem(15);
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: rem(25);
}

.column-single__table {
  margin-top: rem(60);
  @include mq(sm) {
    margin-top: rem(40);
  }
  #toc_container {
    background: #F5F5F2;
    border: rem(1) solid #ccc;
    padding: rem(35) rem(50) rem(35);
    @include mq(sm) {
      padding: rem(20) rem(20);
    }
  }
  .toc_title {
    text-align: center;
    font-size: rem(14);
    font-weight: 700;
    letter-spacing: 0.12em;
  }
  .toc_list {
    border-top: rem(1) solid #ccc;
    padding: rem(22) rem(22) 0;
    margin-top: rem(11);
    @include mq(sm) {
      padding: rem(20) 0 0;
    }
    li {
      font-size: rem(14);
      letter-spacing: 0.12em;
      font-weight: 700;
      line-height: 1.93;
      padding-left: 1em;
      position: relative;
      &::before {
        position: absolute;
        content: "・";
        left: 0;
        top: 0;
      }
    }
  }
}

.column-single__post {
  margin-top: rem(65);
  @include mq(sm) {
    margin-top: rem(50);
  }
}

.column-single__sidebar {
  width: 22%;
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: rem(60) auto 0;
  }
}