@use "global" as *;

/* header */
.header {
  position: fixed;
  left: 0;
  top: 0;
  width: rem(240);
  max-width: 100%;
  height: 100%;
  padding: rem(37) rem(17);
  z-index: 100;
  @include mq(sm) {
    background: #fff;
    width: 100%;
    height: rem(60);
    padding: 0;
  }
}

.header__inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  @include mq(sm) {
    flex-direction: row;
    justify-content: center;
  }
}

.header__top {

}

.header__logo {
  width: rem(161);
  max-width: 100%;
  margin: 0 auto;
  @include mq(sm) {
    width: rem(107);
  }
  a {
    display: block;
  }
}

.header__bottom {
  width: calc(100% - #{rem(10)});
}

.header__nav {
  margin-top: rem(25);
}

.header__menu {

}

.header__menu-item {
  text-align: center;
  & + & {
    margin-top: rem(10);
  }
  a {
    display: inline-block;
    background: #5A3234;
    width: 100%;
    font-size: rem(15);
    font-weight: 500;
    letter-spacing: 0.04em;
    color: #fff;
    border: rem(1) solid transparent;
    text-align: center;
    padding: rem(11) rem(10);
    position: relative;
    transition: background .3s, color .3s, border .3s;
    &::after {
      position: absolute;
      content: "";
      width: rem(6);
      height: rem(6);
      top: 50%;
      transform: translateY(-50%);
      right: rem(15);
      background: url(../img/arrow-icon01.png) no-repeat center / contain;
      transition: background .3s;
    }
    &:hover {
      opacity: 1;
      border-color: #5A3234;
      color: #5A3234;
      background: #fff;
      &::after {
        background: url(../img/arrow-icon08.png) no-repeat center / contain;
      }
    }
  }
}

.header__icon {
  display: block;
  width: rem(32);
  margin: rem(20) auto 0;
}