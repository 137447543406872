@use "global"as *;

/* model */
.model {
  .appointment {
    padding-bottom: rem(50);
  }
  .appointment__text {
    margin-top: rem(32);
    @include mq(sm) {
      margin-top: rem(20);
    }
  }
  .appointment__tel {
    color: $font-color;
    margin-top: rem(26);
    @include mq(sm) {
      margin-top: rem(20);
    }
  }
  .appointment__form {
    margin-top: rem(40);
    .form__item-input {
      width: 52%;
      @include mq(sm) {
        width: 100%;
      }
    }
    .form__item--block {
      .form__item-input {
        width: 100%;
      }
    }
    .form__item--chance {
      .form__item-input {
        margin-top: rem(20);
      }
      .form__check {
        > span {
          margin-bottom: rem(15);
          &:nth-child(3n + 1) {
            width: 28%;
            @include mq(sm) {
              width: 100%;
            }
          }
          &:nth-child(3n + 2) {
            width: 43%;
            margin-left: 0;
            @include mq(sm) {
              width: 100%;
            }
          }
          &:nth-child(3n + 3) {
            width: 29%;
            margin-left: 0;
            @include mq(sm) {
              width: 100%;
            }
          }
          &:last-of-type {
            margin-top: rem(5);
            @include mq(sm) {
              margin-top: 0;
            }
          }
        }
      }
    }
    .form__privacy {
      margin-top: rem(22);
    }
    .form__btn {
      margin-top: rem(40);
    }
  }
}

.model__heading {
  color: $font-color;
}

.model__topimg {
  margin-top: rem(40);
}

.model__text {
  text-align: center;
  letter-spacing: 0.04em;
  line-height: 2.5;
  margin-top: rem(72);
  @include mq(sm) {
    text-align: left;
    font-size: rem(15);
    line-height: 2;
    margin-top: rem(40);
  }
}

.model__imgs {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(32);
  @include mq(sm) {
    display: block;
  }
}

.model__img-wrapper {
  width: calc(50% - #{rem(64)} * 1 / 2);
  margin-top: rem(40);
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: 0 auto;
    & + & {
      margin-top: rem(20);
    }
  }
  &:not(:nth-child(2n + 1)) {
    margin-left: rem(64);
    @include mq(sm) {
      margin-left: auto;
    }
  }
}

.model__img {
  display: block;
  width: 100%;
  padding-top: calc(224 / 377 * 100%);
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.model__flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: rem(60);
  @include mq(sm) {
    display: block;
    margin-top: rem(40);
  }
}

.model__list {
  width: 50%;
  @include mq(sm) {
    width: 100%;
  }
}

.model__list-item {
  display: flex;
  align-items: center;
  font-size: rem(15);
  letter-spacing: 0.08em;
  line-height: 1.4;
  @include mq(sm) {
    justify-content: space-between;
    font-size: rem(14);
  }
  & + & {
    margin-top: rem(20);
  }
}

.model__list-title {
  width: 21%;
  @include mq(sm) {
    width: 25%;
  }
}

.model__list-text {
  width: 79%;
  @include mq(sm) {
    width: 74%;
  }
}

.model__map-wrapper {
  width: 46.5%;
  @include mq(sm) {
    width: 100%;
    margin-top: rem(30);
  }
}

.model__map {
  width: 100%;
  padding-top: calc(345 / 484 * 100%);
  border: rem(1) solid #ccc;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.model__link {
  margin: rem(60) auto 0;
}