@use "global" as *;

/* calendar */
.calendar {
  position: relative;
  table {
    width: 100%;
    text-align: center;
    tr {
      &:first-child {
        th:nth-of-type(1) {
          color: #DB5050;
        }
        th:nth-of-type(7) {
          color: #496BF8;
        }
      }
    }
    th {
      font-weight: 400;
      @include mq(sm) {
        font-size: rem(10);
      }
    }
    td {
      cursor: pointer;
      font-size: rem(20);
      padding: rem(7) 0;
      position: relative;
      z-index: 2;
      @include mq(sm) {
        font-size: rem(15);
        padding: rem(6) 0;
      }
      &::after {
        position: absolute;
        content: "";
        width: rem(31);
        height: rem(29);
        border-radius: rem(5);
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        @include mq(sm) {
          width: rem(24);
          height: rem(22);
        }
      }
      &.yesterday {
        &:after {
          background: #A6DD95;
        }
      }
      &.today {
        color: #fff;
        &::after {
          background: #445F39;
        }
      }
    }
  }
}

.calendar__arrow {
  position: absolute;
  top: 53%;
  transform: translateY(-50%);
  width: rem(14);
  @include mq(sm) {
    width: rem(10);
  }
}

.calendar__prev {
  left: rem(25);
  @include mq(sm) {
    left: rem(-20);
  }
}

.calendar__next {
  right: rem(35);
  @include mq(sm) {
    right: rem(-20);
  }
}

.calendar__header {
  display: flex;
  align-items: center;
  margin-left: rem(10);
}

.calendar__number {
  font-size: rem(70);
  line-height: 1;
  margin-bottom: rem(10);
  @include mq(sm) {
    font-size: rem(53);
  }
}

.calendar__years {
  padding-left: rem(20);
  margin-left: rem(20);
  border-left: rem(1) solid #000;
  padding-bottom: rem(5);
  @include mq(sm) {
    padding-left: rem(15);
    margin-left: rem(15);
  }
}

.calendar__year {
  display: block;
  font-size: rem(20);
  line-height: 1;
  @include mq(sm) {
    font-size: rem(15);
  }
}

.calendar__month {
  display: block;
  font-size: rem(30);
  line-height: 1;
  @include mq(sm) {
    font-size: rem(22);
  }
}