@use "global" as *;

/* category */
.category {
  span {
    display: inline-block;
    font-size: rem(14);
    letter-spacing: 0.12em;
    color: $main-color;
    background: #F5F5F2;
    padding: rem(5) rem(15);
    margin-right: rem(13);
    margin-bottom: rem(10);
    @include mq(sm) {
      font-size: rem(13);
      padding: rem(4) rem(10);
      margin-right: rem(10);
    }
  }
}