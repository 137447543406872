@use "global"as *;

/* demand */
.demand {

}

.demand__text {
  font-size: rem(15);
  letter-spacing: 0.04em;
  line-height: 1.8;
  width: rem(694);
  max-width: 100%;
  margin: rem(30) auto 0;
}

.demand__items {
  display: flex;
  flex-wrap: wrap;
  @include mq(sm) {
    display: block;
    margin-top: rem(40);
  }
}

.demand__item {
  width: calc(33.33333333% - #{rem(40)} * 2 / 3);
  margin-top: rem(45);
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: 0 auto;
    & + & {
      margin-top: rem(40);
    }
  }
  &:not(:nth-child(3n + 1)) {
    margin-left: rem(40);
    @include mq(sm) {
      margin-left: auto;
    }
  }
}

.demand__item-img {
  
}

.demand__item-text {
  text-align: center;
  font-size: rem(15);
  line-height: 1.8;
  letter-spacing: 0.04em;
  margin-top: rem(12);
}