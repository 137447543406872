@use "global" as *;

/* top-voice */
.top-voice {
  padding-bottom: rem(60);
  position: relative;
  @include mq(sm) {
    padding-bottom: rem(40);
  }
  &::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: rem(317);
    background: url(../img/top-voice-bg_pc.png) no-repeat center / cover;
    z-index: -1;
    @include mq(sm) {
      height: rem(218);
      background: url(../img/top-voice-bg_sp.png) no-repeat center / cover;
    }
  }
}

.top-voice__inner {
  @include mq(sm) {
    padding: 0 rem(20);
  }
}

.top-voice__items {
  margin-top: rem(34);
  @include mq(sm) {
    margin-top: rem(24);
  }
  .card__title {
    @include mq(sm) {
      margin-top: rem(12);
    }
  }
  .card__img {
    padding-top: calc(190 / 320 * 100%);
  }
}

.top-voice__link {
  margin: rem(58) auto 0;
  @include mq(sm) {
    margin-top: rem(40);
  }
}