@use "global" as *;

/* list */
.list {
  border-top: rem(1) solid #ccc;
}

.list__item {
  display: flex;
  align-items: center;
  font-size: rem(15);
  line-height: 1.8;
  letter-spacing: 0.08em;
  border-bottom: rem(1) solid #ccc;
  padding: rem(23) 0;
  @include mq(sm) {
    display: block;
    padding: rem(15) 0;
  }
}

.list__item-title {
  width: 37%;
  font-weight: 700;
  @include mq(sm) {
    width: 100%;
  }
}

.list__item-text {
  width: 63%;
  @include mq(sm) {
    width: 100%;
    font-size: rem(14);
    margin-top: rem(3);
  }
}