@use "global"as *;

/* sidebar */
.sidebar {

}

.sidebar__blocks {

}

.sidebar__block {
  & + & {
    margin-top: rem(69);
  }
}

.sidebar__subtitle {
  letter-spacing: 0.08em;
}

.sidebar__items {
  border-top: rem(1) solid #707070;
  padding-top: rem(15);
  margin-top: rem(10);
}

.sidebar__item {
  & + & {
    margin-top: rem(25);
  }
}

.sidebar__item-inner {
  display: block;
  &:hover {
    opacity: 1;
    img {
      transform: scale(1.2);
    }
  }
}

.sidebar__item-img {
  width: 100%;
  padding-top: calc(107 / 226 * 100%);
  overflow: hidden;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .4s;
  }
}

.sidebar__item-title {
  font-size: rem(14);
  letter-spacing: 0.04em;
  margin-top: rem(10);
}

.sidebar__link {
  margin-top: rem(40);
}