@use "global" as *;

/* top-column */
.top-column {
  padding: rem(75) 0 rem(75);
  @include mq(sm) {
    padding: rem(48) 0 rem(48);
  }
}

.top-column__items {
  display: flex;
  width: rem(800);
  max-width: 100%;
  margin: rem(39) auto 0;
  @include mq(sm) {
    display: block;
    margin-top: rem(29);
  }
}

.top-column__item {
  width: calc(50% - #{rem(40)} * 1 / 2);
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: 0 auto;
    & + & {
      margin-top: rem(40);
    }
  }
  &:not(:nth-child(2n + 1)) {
    margin-left: rem(40);
    @include mq(sm) {
      margin-left: auto;
    }
  }
  .card__img {
    padding-top: calc(180 / 380 * 100%);
  }
}

.top-column__link {
  margin: rem(60) auto 0;
  @include mq(sm) {
    width: rem(235);
    margin-top: rem(41);
    padding: rem(12) rem(10);
  }
}