@use "global" as *;

/* qa */
.qa {

}

.qa__content {
  padding-bottom: rem(100);
  @include mq(sm) {
    padding-bottom: rem(60);
  }
}

.qa__tab {
  display: flex;
  margin-bottom: rem(-1);
}

.qa__tab-item {
  text-align: center;
  font-size: rem(20);
  font-weight: 700;
  letter-spacing: 0.08em;
  background: #F5F5F2;
  border-top: rem(6) solid #F5F5F2;
  border-bottom: rem(1) solid #707070;
  cursor: pointer;
  padding: rem(19) rem(15);
  width: calc(33.33333333% - #{rem(10)} * 2 / 3);
  @include mq(sm) {
    font-size: rem(14);
    padding: rem(10) rem(10);
  }
  &:not(:nth-child(3n + 1)) {
    margin-left: rem(10);
  }
  &.current {
    background: #fff;
    border-left: rem(1) solid #707070;
    border-right: rem(1) solid #707070;
    border-top: rem(6) solid $main-color;
    border-bottom: none;
	}
}

.qa__menu {
  border: rem(1) solid #707070;
  padding: rem(40) rem(50);
  @include mq(sm) {
    padding: rem(25) rem(20);
  }
}

.qa__menu-item {
  display: none;
  &.active {
    display: block;

		animation-name: displayAnime;
    animation-duration: .5s;
    animation-fill-mode: forwards;
  }
}

.qa__list {

}

.qa__list-item {
  letter-spacing: 0.08em;
  line-height: 2;
  padding-left: 1em;
  position: relative;
  @include mq(sm) {
    font-size: rem(14);
  }
  &::before {
    position: absolute;
    content: "・";
    left: 0;
    top: 0;
  }
}

.qa__block {
  margin-top: rem(55);
  @include mq(sm) {
    margin-top: rem(40);
  }
}

.qa__block-item {
  display: none;
  &.active {
    display: block;

    animation-name: displayAnime;
    animation-duration: .5s;
    animation-fill-mode: forwards;
  }
}

.qa__group {

}

.qa__group-item {
  font-size: rem(18);
  line-height: 1.72;
  @include mq(sm) {
    font-size: rem(14);
  }
  & + & {
    margin-top: rem(65);
    @include mq(sm) {
      margin-top: rem(40);
    }
  }
}

.qa__group-item-header {
  display: flex;
}

.qa__group-item-body {
  display: flex;
  margin-top: rem(12);
}

.qa__group-item-initials {
  color: $main-color;
  font-weight: 700;
  margin-right: rem(20);
  @include mq(sm) {
    margin-right: rem(15);
  }
}

.qa__group-item-question {
  font-weight: 700;
}

.qa__group-item-answer {

}

@keyframes displayAnime{
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}