@use "global" as *;

/* flow */
.flow {
  
}

.flow__items {
  margin-top: rem(45);
  @include mq(sm) {
    margin-top: rem(40);
  }
}

.flow__item {
  & + & {
    margin-top: rem(40);
    @include mq(sm) {
      margin-top: rem(20);
    }
  }
}

.flow__item-header {
  display: block;
  width: 100%;
  color: $font-color;
  text-align: left;
  background: #F5F5F2;
  position: relative;
  padding: rem(13) rem(80) rem(13) rem(44);
  @include mq(sm) {
    padding: rem(10) rem(40) rem(10) rem(20);
  }
  &.opened {
    &::before {
      transform: rotate(0deg);
    }
  }
  &::before, &::after {
    position: absolute;
    content: '';
    width: rem(30);
    height: rem(7);
    background: $main-color;
    top: 50%;
    right: rem(40);
    transform: translateY(-50%);
    @include mq(sm) {
      width: rem(20);
      height: rem(4);
      right: rem(15);
    }
  }
  &::before {
    top: 45%;
    transition: .2s;
    transform: rotate(90deg);
    @include mq(sm) {
      top: 47%;
    }
  }
  &:hover {
    opacity: 1;
  }
}

.flow__item-title {
  font-size: rem(20);
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(18);
  }
}

.flow__item-body {
  display: none;
}

.flow__item-inner {
  display: flex;
  justify-content: space-between;
  padding: rem(30) rem(40) rem(15);
  @include mq(sm) {
    display: block;
    padding: rem(15) 0;
  }
}

.flow__item-texts {
  width: 56%;
  margin-top: rem(-8);
  @include mq(sm) {
    width: 100%;
    margin-top: 0;
  }
}

.flow__item-subtitle {
  font-size: rem(18);
  letter-spacing: 0.08em;
  line-height: 1.72;
  @include mq(sm) {
    font-size: rem(16);
  }
}

.flow__item-text {
  font-size: rem(15);
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: rem(20);
  @include mq(sm) {
    font-size: rem(14);
    margin-top: rem(10);
  }
}

.flow__item-img {
  width: 40.5%;
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: rem(20) auto 0;
  }
}