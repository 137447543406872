@use "global" as *;

/* process */
.process {
  margin-top: rem(100);
}

.process__title {
  text-align: center;
  font-size: rem(22);
  font-weight: 700;
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(20);
  }
}

.process__subtitle {
  text-align: center;
  font-size: rem(18);
  letter-spacing: 0.08em;
  margin-top: rem(5);
  @include mq(sm) {
    font-size: rem(16);
  }
}

.process__items {
  width: rem(775);
  max-width: 100%;
  margin: rem(32) auto 0;
}

.process__item {
  & + & {
    margin-top: rem(20);
  }
}

.process__item-title {
  font-size: rem(20);
  font-weight: 700;
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(16);
  }
}

.process__item-text {
  font-size: rem(15);
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: rem(3);
  @include mq(sm) {
    font-size: rem(14);
  }
}

.process__box {
  background: #F2F2F2;
  border-radius: rem(15);
  width: rem(805);
  max-width: 100%;
  margin: rem(40) auto 0;
  padding: rem(25) rem(35) rem(40);
  @include mq(sm) {
    padding: rem(25) rem(20);
  }
}

.process__units {

}

.process__unit {
  & + & {
    margin-top: rem(20);
    @include mq(sm) {
      margin-top: rem(30);
    }
  }
}

.process__unit-title {
  font-size: rem(18);
  font-weight: 700;
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(16);
  }
}

.process__unit-text {
  font-size: rem(12);
  letter-spacing: 0.04em;
  line-height: 2.25;
  @include mq(sm) {
    margin-top: rem(10);
  }
}