@use "global" as *;

/* catalog */
.catalog {
  .consultation {
    padding: rem(70) rem(107) rem(70);
    @include mq(sm) {
      padding: rem(40) rem(20);
    }
  }
  .form__item {
    &:last-child {
      @include mq(sm) {
        margin-top: rem(50);
      }
    }
  }
  .form__item-label, .form__item-label-inner {
    > span {
      background: #5A3234;
    }
  }
  .form__item-input {
    width: 56%;
    @include mq(sm) {
      width: 100%;
    }
  }
  .form__item--post {
    .form__item-input {
      padding-left: rem(25);
      position: relative;
      &::before {
        position: absolute;
        content: "〒";
        font-size: rem(15);
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    input {
      width: rem(191);
    }
  }
  .form__check {
    display: block;
    position: relative;
    > span {
      display: block;
      margin-right: 0;
      &:not(:last-of-type) {
        margin-bottom: rem(18);
        @include mq(sm) {
          margin-bottom: rem(23);
        }
      }
    }
    input[type="checkbox"]+span {
      &::after {
        border-color: #000;
      }
    }
    input {
      position: absolute;
      right: 0;
      @include mq(sm) {
        height: rem(35);
      }
      &:first-of-type {
        width: calc(100% - #{rem(75)});
        bottom: rem(50);
        @include mq(sm) {
          bottom: rem(40);
        }
      }
      &:last-child {
        width: calc(100% - #{rem(90)});
        bottom: rem(7);
        @include mq(sm) {
          bottom: rem(-7);
        }
      }
    }
  }
  .form__privacy {
    text-align: left;
    margin: 0;
    margin-left: rem(-20);
  }
  .form__privacy-box+span {
    &::after {
      border-color: #000;
    }
  }
  .form__btn {
    margin-top: rem(40);
  }
}