@use "global" as *;

/* top-menu */
.top-menu {
  background: url(../img/top-menu-bg_pc.png) no-repeat center / cover;
  padding: rem(10) 0 rem(45);
  @include mq(sm) {
    padding: rem(30) 0 rem(28);
    background: url(../img/top-menu-bg_sp.png) no-repeat center / cover;
  }
}

.top-menu__inner {
  padding: 0;
  @include mq(sm) {
    padding: 0 rem(30);
  }
}

.top-menu__items {
  display: flex;
  flex-wrap: wrap;
  @include mq(sm) {
    margin-top: rem(-30);
  }
}

.top-menu__item {
  background: #fff;
  width: calc(33.33333333% - #{rem(44)} * 2 / 3);
  margin-top: rem(40);
  @include mq(lg) {
    width: calc(33.33333333% - #{rem(20)} * 2 / 3);
    margin-top: rem(20);
  }
  @include mq(sm) {
    width: calc(50% - #{rem(15)} * 1 / 2);
    margin-top: rem(30);
  }
  &:not(:nth-child(3n + 1)) {
    margin-left: rem(44);
    @include mq(lg) {
      margin-left: rem(20);
    }
    @include mq(sm) {
      margin-left: 0;
    }
  }
  &:not(:nth-child(2n + 1)) {
    @include mq(sm) {
      margin-left: rem(15);
    }
  }
  &:nth-child(3), &:nth-child(4) {
    @include mq(sm) {
      margin-top: rem(16);
    }
  }
}

.top-menu__item-inner {
  display: flex;
  @include mq(sm) {
    display: block;
  }
  &:hover {
    opacity: 1;
    .top-menu__item-body {
      background: #f7f7f7;
    }
    img {
      transform: scale(1.2);
    }
  }
}

.top-menu__item-img {
  overflow: hidden;
  width: 39%;
  @include mq(sm) {
    width: 100%;
  }
  img {
    height: 100%;
    object-fit: cover;
    transition: .4s;
  }
}

.top-menu__item-body {
  width: 61%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: rem(14);
  transition: background .3s;
  @include mq(sm) {
    width: 100%;
    padding: rem(10);
  }
}

.top-menu__item-title {
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(14);
  }
  span {
    display: block;
    font-size: rem(13);
    line-height: 1.4;
    color: #7E8080;
    @include mq(sm) {
      font-size: rem(10);
    }
  }
}