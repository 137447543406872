@use "global" as *;

/* schedule */
.schedule {
  display: flex;
  background: #fff;
  @include mq(sm) {
    display: block;
  }
}

.schedule__calendar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  padding: rem(22) rem(60) rem(30);
  @include mq(sm) {
    padding: rem(32) rem(10) rem(35);
    max-width: rem(400);
    width: 100%;
    margin: 0 auto;
  }
}

.schedule__body {
  overflow-y: auto;
  width: 50%;
  border: rem(1) solid #707070;
  max-height: rem(450);
  padding: rem(15) rem(18);
  @include mq(sm) {
    width: 100%;
    max-height: rem(300);
    padding: rem(14) rem(13) rem(40);
  }
}

.schedule__title {
  text-align: center;
  font-size: rem(20);
  color: #707070;
  @include mq(sm) {
    font-size: rem(12);
  }
}

.schedule__cards {
  margin-top: rem(24);
  @include mq(sm) {
    max-width: rem(400);
    margin: rem(16) auto 0;
  }
}

.schedule__card {
  border: rem(1) solid #ccc;
  & + & {
    margin-top: rem(20);
  }
}

.schedule__card-inner {
  display: flex;
  flex-direction: row-reverse;
  &:hover {
    opacity: 1;
    img {
      transform: scale(1.2);
    }
  }
}

.schedule__card-body {
  width: 69%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: rem(10) rem(10);
  @include mq(sm) {
    width: 62%;
    padding: rem(7) rem(10);
  }
}

.schedule__card-title {
  font-size: rem(12);
  font-weight: 700;
  letter-spacing: 0.08em;
  color: #445F39;
  @include mq(sm) {
    font-size: rem(10);
  }
}

.schedule__card-text {
  font-size: rem(12);
  letter-spacing: 0.08em;
  margin-top: rem(10);
  @include mq(sm) {
    font-size: rem(10);
    margin-top: 0;
  }
}

.schedule__card-time {
  font-size: rem(14);
  letter-spacing: 0.08em;
  margin-top: rem(10);
  @include mq(sm) {
    font-size: rem(11);
    margin-top: rem(3);
  }
}

.schedule__card-img-wrapper {
  width: 31%;
  @include mq(sm) {
    width: 38%;
  }
}

.schedule__card-img {
  width: 100%;
  height: 100%;
  padding-top: calc(108 / 151 * 100%);
  overflow: hidden;
  position: relative;
  @include mq(sm) {
    padding-top: unset;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .4s;
  }
}