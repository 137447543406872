@use "global" as *;

/* top-works */
.top-works {
  padding: rem(75) 0 rem(60);
  position: relative;
  @include mq(sm) {
    padding: rem(46) 0 rem(40);
  }
  &::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 100%;
    height: rem(325);
    background: #F5F5F5;
    z-index: -1;
    @include mq(sm) {
      height: rem(216);
    }
  }
}

.top-works__inner {
  @include mq(sm) {
    padding: 0 rem(20);
  }
}

.top-works__items {
  margin-top: rem(35);
  @include mq(sm) {
    margin-top: rem(25);
  }
}

.top-works__link {
  width: rem(275);
  margin: rem(55) auto 0;
  @include mq(sm) {
    width: rem(235);
    margin-top: rem(28);
  }
}