@use "global" as *;

/* follow */
.follow {

}

.follow__items {
  display: flex;
  justify-content: space-between;
  border-top: rem(1) solid #ccc;
  padding-top: rem(25);
  margin-top: rem(25);
  @include mq(sm) {
    display: block;
    padding-top: rem(20);
    margin-top: rem(20);
  }
}

.follow__item {
  font-size: rem(15);
  letter-spacing: 0.04em;
  line-height: 1.8;
  &:last-child {
    margin-left: rem(10);
    margin-right: rem(20);
    @include mq(sm) {
      margin: rem(20) 0 0;
    }
  }
}

.follow__item-title {
  font-weight: 700;
}

.follow__item-list {

}

.follow__item-list-item {

}