@use "global" as *;

/* news */
.new {

}

.new__cards {
  display: flex;
  margin-top: rem(45);
  @include mq(sm) {
    margin-top: rem(30);
  }
  .cards__item {
    width: calc(33.33333333% - #{rem(40)} * 2 / 3);
    &:not(:nth-child(3n + 1)) {
      margin-left: rem(40);
    }
    .card__title {
      margin-top: rem(12);
    }
  }
}
