@use "global" as *;

/* section-heading */
.section-heading {
  text-align: center;
  color: $main-color;
  font-weight: 700;
  font-size: rem(22);
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(20);
  }
}