@use "global" as *;

/* attempt */
.attempt {
  text-align: center;
}

.attempt__logo {
  width: rem(100);
  max-width: 100%;
  margin: rem(15) auto 0;
}

.attempt__subtitle {
  font-size: rem(20);
  margin-top: rem(5);
  @include mq(sm) {
    font-size: rem(18);
  }
  span {
    font-size: rem(17);
    @include mq(sm) {
      font-size: rem(16);
    }
  }
}

.attempt__text {
  font-size: rem(15);
  line-height: 1.8;
  letter-spacing: 0.04em;
  margin-top: rem(15);
  @include mq(sm) {
    font-size: rem(14);
  }
}

.attempt__link {
  margin: rem(50) auto 0;
}