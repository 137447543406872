@use "global" as *;

/* iyashiro */
.iyashiro {
  margin-top: rem(115);
  @include mq(sm) {
    margin-top: rem(80);
  }
}

.iyashiro__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: rem(80);
  @include mq(sm) {
    display: block;
    margin-top: rem(40);
  }
}

.iyashiro__body {
  width: 56.5%;
  @include mq(sm) {
    width: 100%;
  }
}

.iyashiro__subtitle {
  font-size: rem(20);
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(16);
  }
}

.iyashiro__text {
  font-size: rem(15);
  letter-spacing: 0.08em;
  line-height: 2.3;
  margin-top: rem(25);
  @include mq(sm) {
    font-size: rem(13);
    line-height: 1.7;
    margin-top: rem(20);
  }
}

.iyashiro__subtext {
  font-size: rem(13);
  line-height: 2.7;
  letter-spacing: 0.08em;
  @include mq(sm) {
    line-height: 1.7;
  }
}

.iyashiro__img {
  width: 33.3%;
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: rem(20) auto 0;
  }
}