@use "global" as *;

/* obsession-fv */
.obsession-fv {
  text-align: center;
  min-height: rem(273);
  padding: rem(80) 0;
  @include mq(sm) {
    padding: rem(60) 0;
    min-height: rem(200);
  }
}

.obsession-fv--genbahinshitsu {
  background: url(../img/obsession-fv-genbahinshitsu.jpg) no-repeat center / cover;
}
.obsession-fv--sekkei {
  background: url(../img/obsession-fv-sekkei.jpg) no-repeat center / cover;
}
.obsession-fv--seinou {
  background: url(../img/obsession-fv-seinou.jpg) no-repeat center / cover;
}
.obsession-fv--kenkou {
  background: url(../img/obsession-fv-kenkou.jpg) no-repeat center / cover;
}
.obsession-fv--shizensozai {
  background: url(../img/obsession-fv-shizensozai.jpg) no-repeat center / cover;
}

.obsession-fv__title {
  font-size: rem(24);
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(20);
  }
}

.obsession-fv__text {
  letter-spacing: 0.08em;
  margin-top: rem(30);
  @include mq(sm) {
    font-size: rem(14);
  }
}