@use "global" as *;

/* humidity */
.humidity {
  margin-top: rem(115);
  @include mq(sm) {
    margin-top: rem(80);
  }
}

.humidity__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: rem(1000);
  max-width: 100%;
  padding-left: rem(50);
  margin: rem(75) auto 0;
  @include mq(sm) {
    display: block;
    margin-top: rem(40);
    padding-left: 0;
  }
}

.humidity__img {
  width: 27.7%;
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: rem(30) auto 0;
  }
}

.humidity__text {
  width: 60.2%;
  font-size: rem(15);
  letter-spacing: 0.08em;
  line-height: 2.3;
  @include mq(sm) {
    width: 100%;
    font-size: rem(13);
    line-height: 1.7;
  }
}

.humidity__box {
  border: rem(1) solid #E3E6E6;
  padding: rem(60) rem(60) rem(55);
  margin-top: rem(110);
  @include mq(sm) {
    padding: rem(30) rem(20);
    margin-top: rem(80);
  }
}

.humidity__items {
  display: flex;
  align-items: flex-start;
  @include mq(sm) {
    display: block;
  }
}

.humidity__item {
  width: 50%;
  @include mq(sm) {
    width: 100%;
  }
  &:first-child {
    padding-right: rem(50);
    border-right: rem(1) solid #E3E6E6;
    @include mq(sm) {
      border-right: none;
      padding-right: 0;
    }
  }
  &:last-child {
    padding-left: rem(50);
    @include mq(sm) {
      border-top: rem(1) solid #E3E6E6;
      padding-left: 0;
      margin-top: rem(30);
      padding-top: rem(30);
    }
  }
}

.humidity__item-title {
  font-size: rem(20);
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(16);
  }
}

.humidity__item-text {
  font-size: rem(15);
  letter-spacing: 0.08em;
  line-height: 2;
  margin-top: rem(10);
  @include mq(sm) {
    font-size: rem(13);
    line-height: 1.7;
  }
  &:first-of-type {
    margin-top: rem(25);
    @include mq(sm) {
      margin-top: rem(20);
    }
  }
}