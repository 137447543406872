@use "global" as *;

/* link-b */
.link-b {
  display: block;
  text-align: center;
  width: rem(300);
  max-width: 100%;
  padding: rem(13) 0;
  position: relative;
  border: rem(1) solid $main-color;
  color: $main-color;
  transition: color .3s, background .3s;
  &::after {
    position: absolute;
    content: "";
    width: rem(9);
    height: rem(9);
    right: rem(23);
    top: 50%;
    transform: translateY(-50%);
    background: url(../img/arrow-icon06.png) no-repeat center / contain;
    transition: background .3s;
  }
  &:hover {
    opacity: 1;
    background: $main-color;
    color: #fff;
    &::after {
      background-image: url(../img/arrow-icon01.png);
    }
  }
}