@use "global" as *;

/* banner */
.banner {
  display: flex;
  flex-direction: row-reverse;
  border: rem(1) solid #ccc;
  @include mq(sm) {
    display: block;
  }
}

.banner__img {
  width: 44%;
  @include mq(lg) {
    width: 40%;
  }
  @include mq(sm) {
    width: 100%;
  }
  img {
    height: 100%;
    object-fit: cover;
    @include mq(sm) {
      height: rem(200);
    }
  }
}

.banner__body {
  width: 56%;
  @include mq(lg) {
    width: 60%;
  }
  @include mq(sm) {
    width: 100%;
  }
}

.banner__title {
  height: 55%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: rem(24);
  letter-spacing: 0.04em;
  line-height: 1.96;
  background: #F5F5F2;
  padding: rem(20);
  @include mq(lg) {
    height: 50%;
    font-size: rem(22);
    line-height: 1.7;
  }
  @include mq(sm) {
    display: block;
    height: auto;
    font-size: rem(18);
    padding: rem(15);
  }
}

.banner__footer {
  height: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: rem(20);
  @include mq(lg) {
    height: 50%;
  }
  @include mq(sm) {
    height: auto;
    display: block;
    padding: rem(20) rem(15);
  }
}

.banner__texts {
  
}

.banner__text {
  font-size: rem(15);
  letter-spacing: 0.08em;
}

.banner__number {
  display: inline-block;
  font-family: $second-font-family;
  font-size: rem(33);
  letter-spacing: 0.03em;
  font-weight: 300;
  line-height: 1.4;
  margin-top: rem(3);
  @include mq(sm) {
    font-size: rem(28);
  }
}

.banner__subtext {
  font-size: rem(14);
}

.banner__btn {
  text-align: center;
  display: block;
  width: rem(200);
  max-width: 100%;
  padding: rem(11) 0;
  font-size: rem(15);
  color: #fff;
  background: $main-color;
  margin-left: rem(45);
  position: relative;
  @include mq(lg) {
    margin-left: rem(30);
  }
  @include mq(sm) {
    margin-left: 0;
    margin-top: rem(15);
  }
  &::after {
    position: absolute;
    content: "";
    width: rem(7);
    height: rem(7);
    top: 50%;
    transform: translateY(-50%);
    right: rem(15);
    background: url(../img/arrow-icon01.png) no-repeat center / contain;
  }
}