@use "global" as *;

/* event-single */
.event-single {
  overflow-x: hidden;
}

.event-single__content {
  margin-top: rem(45);
  @include mq(sm) {
    margin-top: rem(40);
  }
}

.event-single__thumbnail {
  margin-left: rem(240);
  @include mq(sm) {
    margin-left: 0;
  }
}

.event-single__body {
  margin-top: rem(100);
  @include mq(sm) {
    margin-top: rem(60);
  }
}

.event-single__header {
  .event-single__info {
    margin-top: rem(35);
  }
}

.event-single__footer {
  .event-single__info {
    margin-top: rem(30);
  }
}

.event-single__info {
  .info__item-title {
    padding: rem(3) rem(12) rem(4);
  }
}

.event-single__subtitle {
  text-align: center;
  color: $main-color;
  font-weight: 700;
  letter-spacing: 0.08em;
  background: #F5F5F2;
  border: rem(1) solid $main-color;
  padding: rem(6) rem(10);
  @include mq(sm) {
    font-size: rem(15);
  }
}

.event-single__title {
  font-size: rem(24);
  letter-spacing: 0.08em;
  font-weight: 700;
  margin-top: rem(32);
  @include mq(sm) {
    font-size: rem(22);
  }
}


.event-single__post {
  margin-top: rem(40);
  h2, h3, h4, h5, h6 {
    font-weight: 500;
    letter-spacing: 0.08em;
    margin-bottom: rem(35);
  }
  h2 {
    font-size: rem(20);
    @include mq(sm) {
      font-size: rem(18);
    }
  }
  figure {
    margin-bottom: rem(35);
  }
}

.event-single__form {
  margin-top: rem(100);
  @include mq(sm) {
    margin-top: rem(60);
  }
}

.event-single__form-box {
  background: #fff;
}

.event-single__form-title {
  text-align: center;
}

.event-single__form-subtitle {
  text-align: center;
}

.event-single__anchor {
  margin: 0 auto rem(60);
  @include mq(sm) {
    margin-bottom: rem(40);
  }
}
.event-single__link {
  margin: rem(60) auto 0;
  @include mq(sm) {
    margin-top: rem(40);
  }
}