@use "global" as *;

/* footer */
.footer {
  padding: rem(28) 0 rem(38);
  @include mq(sm) {
    text-align: center;
    padding: rem(40) 0 rem(45);
  }
}

.footer__inner {
  border-left: rem(1) solid #D9D9D9;
  padding-left: rem(51);
  padding-right: rem(80);
  @include mq(lg) {
    padding: 0 rem(20);
    border-left: none;
  }
}

.footer__flex {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  @include mq(sm) {
    display: block;
  }
}

.footer__body {
  padding-top: rem(12);
  @include mq(sm) {
    padding-top: 0;
    margin-top: rem(40);
  }
}

.footer__logo {
  width: rem(161);
  max-width: 100%;
  @include mq(sm) {
    margin: 0 auto;
  }
}

.footer__text {
  font-size: rem(14);
  color: #666;
  line-height: 1.78;
  letter-spacing: 0.04em;
  margin-top: rem(20);
  @include mq(sm) {
    font-size: rem(13);
    line-height: 1.92;
  }
}

.footer__copyright {
  font-family: $second-font-family;
  font-weight: 300;
  font-size: rem(14);
  letter-spacing: 0.08em;
  margin-top: rem(5);
  @include mq(sm) {
    font-size: rem(12);
    margin-top: rem(25);
  }
}

.footer__nav {

}