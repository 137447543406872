@use "global" as *;

/* modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  opacity: 0;
  background: rgba(0, 0, 0, 0.78);
  z-index: 200;
  transition: opacity .6s;
  overflow-y: auto;
  padding-top: rem(100);
  @include mq(sm) {
    overflow-y: unset;
    padding-top: 0;
  }
  &.active {
    opacity: 1;
    pointer-events: auto;
  }

  .swiper-button-prev, .swiper-button-next {
    position: absolute;
    top: 47%;
    transform: translateY(-50%);
    @include mq(sm) {
      top: 55%;
    }
    &::after {
      position: absolute;
      content: "";
      top: 0;
      @include mq(sm) {
        width: rem(20);
        height: rem(20);
      }
    }
  }
  .swiper-button-prev {
    left: rem(-70);
    @include mq(sm) {
      left: rem(-30);
    }
    &::after {
      border-top: rem(41) solid transparent;
      border-right: rem(36) solid #fff;
      border-bottom: rem(41) solid transparent;
      @include mq(sm) {
        border-top: rem(20) solid transparent;
        border-right: rem(20) solid #fff;
        border-bottom: rem(20) solid transparent;
      }
    }
  }
  .swiper-button-next {
    right: rem(-70);
    @include mq(sm) {
      right: rem(-30);
    }
    &::after {
      border-top: rem(41) solid transparent;
      border-left: rem(36) solid #fff;
      border-bottom: rem(41) solid transparent;
      @include mq(sm) {
        border-top: rem(20) solid transparent;
        border-left: rem(20) solid #fff;
        border-bottom: rem(20) solid transparent;
      }
    }
  }
}

.modal__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.modal__content {
  position: relative;
  max-width: 80%;
  @include mq(sm) {
    max-width: 85%;
  }
}

.modal__inner {
  padding: rem(150) 0 rem(100);
}

.modal__close {
  display: inline-block;
  position: absolute;
  right: rem(35);
  top: rem(135);
  z-index: 10;
  @include mq(sm) {
    top: rem(120);
    right: rem(10);
  }
  &::before, &::after {
		position: absolute;
		content: "";
		height: rem(15);
		width: rem(70);
		background: #fff;
		left: 0;
		top: rem(10);
    @include mq(sm) {
      width: rem(8);
      height: rem(40);
    }
	}
	&::before {
		transform: rotate(45deg);
	}
	&::after {
		transform: rotate(-45deg);
	}
  &:hover {
    opacity: 1;
  }
}

.modal__slider-wrapper {
  overflow: hidden;
}

.modal__slider-item {
  padding-bottom: rem(20);
}

.modal__slider-item-img {
  width: 100%;
  padding-top: calc(529 / 942 * 100%);
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.modal__slider-item-text {
  position: relative;
  bottom: rem(-10);
  text-align: center;
  color: #fff;
  font-size: rem(20);
  font-weight: 700;
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(14);
  }
}