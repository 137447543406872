@use "global" as *;

/* post-content */
.post-content {
  h2, h3, h4, h5, h6 {
    font-weight: 700;
    letter-spacing: 0.12em;
    line-height: 1.3;
    margin: 0;
    margin-bottom: rem(25);
  }
  h2 {
    font-size: rem(24);
    @include mq(sm) {
      font-size: rem(22);
    }
  }
  h3 {
    font-size: rem(20);
    @include mq(sm) {
      font-size: rem(18);
    }
  }
  h4, h5, h6 {
    font-size: rem(18);
    @include mq(sm) {
      font-size: rem(16);
    }
  }
  p {
    font-size: rem(15);
    letter-spacing: 0.04em;
    line-height: 1.8;
    margin-bottom: rem(40);
  }
  figure {
    margin-bottom: rem(45);
    @include mq(sm) {
      max-width: rem(400);
      margin: 0 auto rem(40);
    }
  }
  a {
    display: inline-block;
    text-decoration: underline;
  }
  ul {
    margin-bottom: rem(40);
    li {
      line-height: 1.8;
      padding-left: 1em;
      position: relative;
      &::before {
        position: absolute;
        content: "・";
        left: 0;
        top: 0;
      }
    }
  }
}