@use "global" as *;

/* reservation */
.reservation {
  background: #fff;
  padding: rem(65) rem(50) rem(70);
  @include mq(sm) {
    padding: rem(40) rem(20);
  }
}

.reservation__text {
  text-align: center;
  letter-spacing: 0.12em;
  font-weight: 700;
  color: $main-color;
  margin-top: rem(15);
  @include mq(sm) {
    font-size: rem(15);
  }
}

.reservation__form {
  margin-top: rem(30);
  .form__item-label {
    width: 25%;
    @include mq(sm) {
      width: 100%;
    }
  }
  .form__item-input {
    width: 53%;
    @include mq(sm) {
      width: 100%;
    }
  }
}