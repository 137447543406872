@use "global" as *;

/* exhibition */
.exhibition {
  
}

.exhibition__content {

}

.exhibition__heading {
  color: $font-color;
}

.exhibition__text {
  text-align: center;
  font-size: rem(15);
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin: rem(30) 0 rem(75);
  @include mq(sm) {
    text-align: left;
    font-size: rem(14);
    margin-bottom: rem(60);
  }
}

.exhibition__items {
  margin-top: rem(40);
}

.exhibition__item {
  & + & {
    margin-top: rem(80);
  }
}

.exhibition__item-inner {
  display: flex;
  justify-content: space-between;
  @include mq(sm) {
    display: block;
  }
}

.exhibition__item-img-wrapper {
  width: 50%;
  @include mq(sm) {
    width: 100%;
  }
}

.exhibition__item-topimg {
  width: 100%;
  padding-top: calc(290 / 520 * 100%);
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.exhibition__item-imgs {
  display: flex;
  margin-top: rem(20);
  @include mq(sm) {
    margin-top: rem(15);
  }
}

.exhibition__item-img {
  width: calc(25% - #{rem(20)} * 3 / 4);
  cursor: pointer;
  position: relative;
  @include mq(sm) {
    width: calc(25% - #{rem(10)} * 3 / 4);
  }
  &:not(:nth-child(4n + 1)) {
    margin-left: rem(20);
    @include mq(sm) {
      margin-left: rem(10);
    }
  }
  &::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
  }
  &.current {
    &::after {
      display: none;
    }
  }
}

.exhibition__item-body {
  width: 46%;
  @include mq(sm) {
    width: 100%;
    margin-top: rem(25);
  }
}

.exhibition__item-title {
  font-size: rem(18);
  letter-spacing: 0.08em;
  @include mq(sm) {
    font-size: rem(16);
  }
}

.exhibition__item-list {
  border-top: rem(1) solid #ccc;
  padding-top: rem(28);
  margin-top: rem(20);
  @include mq(sm) {
    margin-top: rem(15);
    padding-top: rem(20);
  }
}

.exhibition__item-list-item {
  font-size: rem(15);
  letter-spacing: 0.08em;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mq(sm) {
    font-size: rem(14);
  }
  & + & {
    margin-top: rem(28);
    @include mq(sm) {
      margin-top: rem(20);
    }
  }
}

.exhibition__item-list-title {
  width: 20%;
  @include mq(sm) {
    width: 22%;
  }
}

.exhibition__item-list-text {
  width: 78%;
  @include mq(sm) {
    width: 76%;
  }
}

.exhibition__item-link {
  margin: rem(60) auto 0;
  @include mq(sm) {
    margin-top: rem(40);
  }
}

.exhibition__bottom {

}

.exhibition__flex {
  display: flex;
  justify-content: space-between;
  @include mq(sm) {
    display: block;
  }
}

.exhibition__flex-body {
  width: 53%;
  @include mq(sm) {
    width: 100%;
  }
}

.exhibition__flex-title {
  font-size: rem(18);
  letter-spacing: 0.08em;
  line-height: 1.45;
  @include mq(sm) {
    font-size: rem(16);
  }
}

.exhibition__flex-text {
  font-size: rem(15);
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: rem(25);
  @include mq(sm) {
    font-size: rem(14);
    margin-top: rem(20);
  }
}

.exhibition__flex-img {
  width: 42%;
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: rem(20) auto 0;
  }
}