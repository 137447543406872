@use "global" as *;

/* top-mv */
.top-mv {
  @include mq(sm) {
    margin-top: rem(60);
  }
}

.top-mv__slider {
  overflow: hidden;
  .swiper-container {
    .swiper-wrapper {
      .swiper-slide {
        .slide-img {
          @include mq(sm) {
            width: 100%;
            padding-top: calc(16 / 9 * 100%);
            position: relative;
          }
          img {
            height: 100vh;
            object-fit: cover;
            @include mq(sm) {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .swiper-slide-active .slide-img,
  .swiper-slide-duplicate-active .slide-img,
  .swiper-slide-prev .slide-img {
      animation: zoomUp 12s linear 0s;
      animation-fill-mode: both;
  }
}

@keyframes zoomUp {
  0% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
