@use "global" as *;

/* charm */
.charm {
  
}

.charm__items {
  display: flex;
  margin-top: rem(35);
  @include mq(sm) {
    display: block;
  }
}

.charm__item {
  width: calc(33.33333333% - #{rem(40)} * 2 / 3);
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: 0 auto;
    & + & {
      margin-top: rem(40);
    }
  }
  &:not(:nth-child(3n + 1)) {
    margin-left: rem(40);
    @include mq(sm) {
      margin-left: auto;
    }
  }
}

.charm__item-img {

}

.charm__item-title {
  text-align: center;
  font-weight: 700;
  line-height: 1.68;
  letter-spacing: 0.08em;
  margin-top: rem(15);
}

.charm__item-text {
  font-size: rem(15);
  letter-spacing: 0.04em;
  line-height: 1.8;
  margin-top: rem(10);
}