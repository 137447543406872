@use "global"as *;

/* column-archive */
.column-archive {
  
}

.column-archive__content {

}

.column-archive__category {

}


.column-archive__items {
  display: flex;
  flex-wrap: wrap;
  margin-top: rem(20);
  @include mq(sm) {
    display: block;
    margin-top: rem(40);
  }
}

.column-archive__item {
  width: calc(50% - #{rem(40)} * 1 / 2);
  margin-top: rem(52);
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: 0 auto;
    & + & {
      margin-top: rem(40);
    }
  }
  &:not(:nth-child(2n + 1)) {
    margin-left: rem(40);
    @include mq(sm) {
      margin-left: auto;
    }
  }
  .card__subtitle {
    margin-top: rem(10);
  }
  .card__heading {
    margin-top: rem(6);
    letter-spacing: 0.04em;
  }
  .card__img {
    padding-top: calc(230 / 500 * 100%);
  }
}