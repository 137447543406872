@use "global"as *;

/* concept-nav */
.concept-nav {
  
}

.concept-nav__inner {
  @include mq(sm) {
    padding: 0 rem(15);
  }
}

.concept-nav__items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.concept-nav__item {
  font-size: rem(14);
  letter-spacing: 0.12em;

  &+& {
    margin-left: rem(35);
    position: relative;

    &::before {
      position: absolute;
      content: "|";
      color: #9FA0A0;
      left: rem(-20);
      top: rem(5);
    }
  }

  a,
  span {
    display: inline-block;
    padding: rem(5) 0;
  }
}