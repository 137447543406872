@use "global"as *;

/* news-archive */
.news-archive {

}

.news-archive__content {
  
}

.news-archive__body {
  background: #fff;
  padding: rem(65) rem(80);
  @include mq(sm) {
    padding: rem(40) rem(20);
  }
}

.news-archive__items {
  border-top: rem(1) dashed #ccc;
}

.news-archive__item {
  border-bottom: rem(1) dashed #ccc;
  padding: rem(45) 0;
  @include mq(sm) {
    padding: rem(25) 0;
  }
}

.news-archive__item-inner {
  display: block;
}

.news-archive__item-date {
  letter-spacing: 0.12em;
  @include mq(sm) {
    font-size: rem(15);
  }
}

.news-archive__item-title {
  font-size: rem(24);
  font-weight: 700;
  letter-spacing: 0.12em;
  color: $main-color;
  margin-top: rem(8);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @include mq(sm) {
    font-size: rem(20);
  }
}

.news-archive__item-text {
  font-size: rem(14);
  line-height: 1.7;
  letter-spacing: 0.04em;
  margin-top: rem(15);
}