@use "global" as *;

/* talk */
.talk {
  
}

.talk__items {
  display: flex;
  margin-top: rem(35);
  @include mq(sm) {
    display: block;
  }
}

.talk__item {
  width: calc(25% - #{rem(27)} * 3 / 4);
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: 0 auto;
    & + & {
      margin-top: rem(40);
    }
  }
  &:not(:nth-child(4n + 1)) {
    margin-left: rem(27);
    @include mq(sm) {
      margin-left: auto;
    }
  }
}

.talk__item-inner {
  display: block;
  width: 100%;
  text-align: left;
  &:hover {
    opacity: 1;
    img {
      transform: scale(1.2);
    }
  }
}

.talk__item-img {
  width: 100%;
  padding-top: calc(170 / 240 * 100%);
  overflow: hidden;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .4s;
  }
}

.talk__item-name {
  font-size: rem(15);
  color: #000;
  letter-spacing: 0.08em;
  margin-top: rem(15);
}

.talk__popups {

}

.talk__popup {

}