@use "global" as *;

/* popup */
.popup {
  display: none;
  z-index: 100;
  &.is-open {
    display: block;
  }
}

.popup__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(#000, 0.8);
  left: 0;
  top: 0;
  z-index: 100;
  overflow: auto;
}

.popup__container {
  width: rem(736);
  max-width: 90%;
  padding: rem(100) 0;
  overflow: auto;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  @include mq(sm) {
    padding: rem(60) 0;
  }
}

.popup__content {
  background: #fff;
  padding: rem(40) rem(40);
  @include mq(sm) {
    padding: rem(30) rem(20);
  }
}

.popup__close {
  position: absolute;
  right: rem(20);
  top: rem(60);
  width: rem(30);
  height: rem(30);
  @include mq(sm) {
    top: rem(20);
  }
  &::before, &::after {
    position: absolute;
    content: "";
    background: #fff;
    width: rem(28);
    height: rem(5);
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}

.popup__header {
  display: flex;
  justify-content: space-between;
  @include mq(sm) {
    display: block;
  }
}

.popup__img {
  width: 38.5%;
  @include mq(sm) {
    width: 100%;
    max-width: rem(400);
    margin: 0 auto;
  }
}

.popup__body {
  width: 57%;
  margin-top: rem(-3);
  @include mq(sm) {
    width: 100%;
    margin-top: rem(15);
  }
}

.popup__position {
  font-size: rem(13);
  letter-spacing: 0.08em;
}

.popup__name {
  font-size: rem(20);
  letter-spacing: 0.08em;
  margin-top: rem(3);
}

.popup__subtitle {
  font-size: rem(14);
  font-weight: 700;
  color: $main-color;
  margin-top: rem(10);
}

.popup__subtext {
  font-size: rem(13);
  line-height: 1.92;
  letter-spacing: 0.04em;
  margin-top: rem(6);
}

.popup__text {
  font-size: rem(13);
  letter-spacing: 0.04em;
  line-height: 1.9;
  margin-top: rem(20);
  span {
    display: block;
    font-size: rem(14);
    font-weight: 700;
    color: $main-color;
    margin-bottom: rem(-10);
  }
}