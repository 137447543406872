@use "global" as *;

/* voice-single */
.voice-single {
  
}

.voice-single__inner {
  padding: 0;
}

.voice-single__thumbnail {
  width: 100%;
  padding-top: calc(311 / 1080 * 100%);
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.voice-single__header {
  border-top: rem(3) solid $main-color;
  background: #F5F5F2;
  margin-top: rem(100);
  padding: rem(20) 0 rem(25);
  @include mq(sm) {
    padding: rem(20) rem(20);
    margin-top: rem(60);
  }
}

.voice-single__list {
  display: flex;
  @include mq(sm) {
    display: block;
  }
}

.voice-single__list-item {
  width: 50%;
  display: flex;
  justify-content: center;
  @include mq(sm) {
    width: 100%;
    &:not(:first-child) {
      margin-top: rem(15);
    }
  }
}

.voice-single__list-item-title {
  font-weight: 700;
}

.voice-single__list-item-text {
  margin-left: rem(50);
  @include mq(sm) {
    margin-left: rem(40);
  }
}

.voice-single__items {
  margin-top: rem(100);
  @include mq(sm) {
    margin-top: rem(60);
  }
}

.voice-single__item {
  border: rem(1) solid #ccc;
  padding: rem(45) rem(70) rem(45);
  @include mq(sm) {
    padding: rem(20) rem(20);
  }
  & + & {
    margin-top: rem(50);
    @include mq(sm) {
      margin-top: rem(40);
    }
  }
}

.voice-single__item-title {
  font-size: rem(20);
  font-weight: 700;
  line-height: 1.7;
  color: $main-color;
  border-bottom: rem(1) dashed #ccc;
  padding-bottom: rem(15);
  margin-bottom: rem(20);
  @include mq(sm) {
    font-size: rem(15);
    font-weight: 500;
  }
  span {
    display: inline-block;
    margin-left: rem(5);
    margin-right: rem(10);
  }
}

.voice-single__item-text {
  font-size: rem(15);
  letter-spacing: 0.04em;
  line-height: 1.8;
}

.voice-single__item-flex {
  display: flex;
  justify-content: space-between;
  @include mq(sm) {
    display: block;
  }
  .voice-single__item-text {
    width: 66%;
    margin-top: rem(-7);
    @include mq(sm) {
      width: 100%;
      margin-top: rem(15);
    }
  }
}

.voice-single__item-img-wrapper {
  width: 31%;
  @include mq(sm) {
    width: rem(300);
    max-width: 100%;
    margin: 0 auto;
  }
}

.voice-single__item-img {
  width: 100%;
  padding-top: calc(277 / 277 * 100%);
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}