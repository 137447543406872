@use "global" as *;

/* pagetop */
.pagetop {
  display: none;
  position: fixed;
  width: rem(64);
  right: rem(20);
  bottom: rem(23);
  z-index: 50;
  @include mq(sm) {
    width: rem(32);
    right: rem(10);
    bottom: rem(33);
  }
}