@use "global" as *;

/* links */
.links {
  display: flex;
  justify-content: center;
  @include mq(sm) {
    display: block;
  }
  a {
    display: block;
    text-align: center;
    width: rem(300);
    max-width: 100%;
    border: rem(1) solid $main-color;
    color: $main-color;
    padding: rem(13) 0;
    position: relative;
    transition: color .3s, background .3s;
    @include mq(sm) {
      margin: 0 auto;
    }
    &::after {
      position: absolute;
      content: "";
      width: rem(9);
      height: rem(9);
      right: rem(23);
      top: 50%;
      transform: translateY(-50%);
      background: url(../img/arrow-icon06.png) no-repeat center / contain;
      transition: background .3s;
    }
    &:hover {
      opacity: 1;
      background: $main-color;
      color: #fff;
      &::after {
        background-image: url(../img/arrow-icon01.png);
      }
    }
  }
}

.links__prev, .links__next {
  margin: 0 rem(12);
  @include mq(sm) {
    margin: rem(12) 0;
  }
  a {
    &:hover {
      opacity: 1;
      background: $main-color;
      color: #fff;
    }
  }
}

.links__prev {
  a {
    &::after {
      left: rem(23);
      background: url(../img/arrow-icon05.png) no-repeat center / contain;
    }
    &:hover {
      &::after {
        background-image: url(../img/arrow-icon07.png);
      }
    }
  }
}

.links__next {
  a {
    &::after {
      right: rem(23);
      background: url(../img/arrow-icon06.png) no-repeat center / contain;
    }
    &:hover {
      &::after {
        background-image: url(../img/arrow-icon01.png);
      }
    }
  }
}