@use "global" as *;

/* gnav */
.gnav {

}

.gnav__items {
  display: flex;
}

.gnav__item {
  font-size: rem(14);
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0.2em;
  writing-mode: vertical-rl;
  a {
    display: inline-block;
    padding: 0 rem(5);

    position: relative;
    &::after {
      position: absolute;
      right: 0;
      content: '';
      width: rem(1);
      height: 100%;
      background: #000000;
      top: 0;
      transform: scale(1, 0);
      transform-origin: left top;
      transition: all 300ms cubic-bezier(1, 0, 0, 1);
      transition-timing-function: cubic-bezier(1, 0, 0, 1);
    }
    &:hover {
      opacity: 1;
      &::after {
        transform: scale(1, 1);
      }
    }
  }
}