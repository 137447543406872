@use "global" as *;

/* fade */
.js-fade {
  opacity: 0;
  visibility: hidden;
  transform: translateY(30px);
  transition: opacity 0.8s, transform .8s;
  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
  }
}