@use "global" as *;

/* top-info */
.top-info {
  background: #F5F5F5;
  padding: rem(37) 0 rem(60);
  @include mq(sm) {
    padding: rem(36) 0 rem(50);
  }
  .section-entitle {
    white-space: nowrap;
  }
}

.top-info__schedule {
  margin-top: rem(40);
  .calendar {
    border: rem(1) solid #707070;
    @include mq(sm) {
      border: none;
    }
  }
}