@use "global" as *;

/* event-archive */
.event-archive {

}

.event-archive__content {

}

.event-archive__body {
  
}

.event-archive__slider-wrapper {

}

.event-archive__calendar {
  margin-top: rem(100);
  @include mq(sm) {
    overflow-x: auto;
    white-space: nowrap;
    margin-top: rem(60);
    padding-bottom: rem(20);
    .calendar {
      width: rem(700);
    }
  }
}

.event-archive__items {
  margin-top: rem(100);
  @include mq(sm) {
    margin-top: rem(40);
  }
}

.event-archive__item {
  border: rem(1) solid #ccc;
  & + & {
    margin-top: rem(50);
    @include mq(sm) {
      margin-top: rem(40);
    }
  }
}

.event-archive__item-inner {
  display: flex;
  flex-direction: row-reverse;
  @include mq(sm) {
    display: block;
  }
  &:hover {
    opacity: 1;
    img {
      transform: scale(1.2);
    }
  }
}

.event-archive__item-img-wrapper {
  width: 37%;
  @include mq(sm) {
    width: 100%;
    height: rem(200);
  }
}

.event-archive__item-img {
  width: 100%;
  height: 100%;
  padding-top: calc(233 / 326 * 100%);
  overflow: hidden;
  position: relative;
  @include mq(sm) {
    padding-top: unset;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform .4s;
  }
}

.event-archive__item-body {
  width: 63%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: rem(20) rem(50);
  @include mq(sm) {
    width: 100%;
    padding: rem(20) rem(15);
  }
}

.event-archive__item-title {
  font-weight: 700;
  letter-spacing: 0.08em;
  color: $main-color;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.event-archive__item-info {
  margin-top: rem(23);
  .info__item {
    &:not(:first-child) {
      margin-top: rem(10);
    }
  }
  .info__item-title {
    padding: rem(2) rem(12) rem(3);
  }
}

.event-archive__item-text {
  font-size: rem(14);
  letter-spacing: 0.04em;
  margin-top: rem(15);
}

.event-archive__banner {
  background: #F5F5F2;
  padding: rem(70) 0;
  margin-top: rem(100);
  @include mq(sm) {
    padding: rem(60) 0;
    margin-top: rem(80);
  }
}