@use "global" as *;

/* top-reservation */
.top-reservation {
  margin-top: rem(80);
  @include mq(sm) {
    max-width: rem(400);
    margin: rem(30) auto 0;
  }
}

.top-reservation__inner {
  display: flex;
  @include mq(sm) {
    display: block;
  }
}

.top-reservation__body {
  width: 52%;
  background: #F5F5F5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include mq(sm) {
    width: 100%;
    padding: rem(43) rem(20) rem(50);
  }
}

.top-reservation__title {
  text-align: center;
  font-size: rem(32);
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 1.37;
  @include mq(sm) {
    font-size: rem(24);
    line-height: 1.58;
  }
}

.top-reservation__link {
  margin: rem(27) auto 0;
  @include mq(sm) {
    margin-top: rem(34);
  }
}

.top-reservation__video-wrapper {
  width: 48%;
  @include mq(sm) {
    width: 100%;
  }
}

.top-reservation__video {
  width: 100%;
  padding-top: 280 / 500 * 100%;
  position: relative;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}