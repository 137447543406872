@use "global" as *;

/* page-top */
.page-top {
  padding: rem(90) 0;
  @include mq(sm) {
    padding: rem(15) 0 rem(25);
    margin-top: rem(60);
  }
}

.page-top--top {
  .page-top__img {
    width: rem(700);
    @include mq(sm) {
      width: rem(330);
    }
  }
}

.page-top--concept {
  .page-top__img {
    width: rem(500);
    @include mq(sm) {
      width: rem(330);
    }
  }
}

.page-top__inner {
  @include mq(sm) {
    padding: 0 rem(15);
  }
}

.page-top__img {
  width: rem(900);
  max-width: 100%;
  margin: 0 auto;
  @include mq(sm) {
    width: rem(330);
  }
}